import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Upload,
  DatePicker,
  Select,
  Space,
  Table,
  Divider,
  Popover,
} from 'antd';
import CloseIcon from '../../../common/CloseIcon';
import {
  LoadingOutlined,
  MessageFilled,
  MessageOutlined,
  PaperClipOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import axios from 'axios';
import { baseURL, getToken } from '../../../../helpers/api';
import Button from '../../../../components/common/Button';
import { openNotification } from '../../../../helpers/notification';
import { formatDate } from '../../../../helpers/utils';
import './../index.css';

export default function InvoicePaymentApprovalForm({
  showApprovalForm,
  toggleApprovalForm,
  invoiceData,
}) {

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState([
    { value: 'REMITA', label: 'REMITA' },
    { value: 'BANK', label: 'BANK' },
  ]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethod[0].value);

  // Handle form submission
  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      const formattedDatePaid = values.datePaid.toISOString();

      formData.append('bankName', values.bankName);
      formData.append('bankAccount', values.bankAccountName);
      formData.append('paymentReference', values.tellerNo);
      formData.append('amountPaid', values.amount);
      formData.append('paymentDescription', values.comment);
      formData.append('invoiceId', invoiceData?.invoiceId);
      formData.append('datePaid', formattedDatePaid);
      formData.append('paymentMethod', values.paymentMethod);

      if (fileList.length > 0) {
        const file = fileList[0].originFileObj;
        formData.append('receipt', file);
        console.log('formdata', formData.values('receipt'));
      } else {
        console.log('No file selected.');
      }

      const response = await axios.post(`${baseURL}/Payment/ApprovePayment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `bearer ${getToken()}`,
        },
      });

      if (response.data?.result?.status?.code === '00') {
        setLoading(false);

        setTimeout(() => {
          toggleApprovalForm(false);
        }, 1000);
        return openNotification({
          type: 'success',
          title: 'Success',
          message: 'Confirmed successfully',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      return openNotification({
        //duration: 2000,
        type: 'error',
        title: 'Failed',
        message: 'Failed to confirm',
      });
    }
  };

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const renderPaymentTable = () => {
    const columns = [
      {
        title: 'Date Paid',
        key: 'paymentDate',
        dataIndex: 'paymentDate',
        render: (paymentDate) => (
          <Space>
            {new Date(paymentDate).toLocaleDateString('en-NG', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </Space>
        ),
      },

      {
        title: 'Expected',
        key: 'amount',
        dataIndex: 'amount',
        render: (amount) => (
          <span>
            {Intl.NumberFormat('en-NG', {
              style: 'currency',
              currency: 'NGN',
            }).format(amount)}
          </span>
        ),
      },
      {
        title: 'Paid',
        key: 'amountPaid',
        dataIndex: 'amountPaid',
        render: (amountPaid, data) => (
          <Space>
            <Space>
              {Intl.NumberFormat('en-NG', {
                style: 'currency',
                currency: 'NGN',
              }).format(amountPaid)}
            </Space>
            {data?.paymentReceipt && (
              <Space style={{ cursor: 'pointer', color: 'blue' }}>
                <a href={data.paymentReceipt} target="_blank">
                  <PaperClipOutlined />
                </a>
              </Space>
            )}
          </Space>
        ),
      },
      {
        title: 'Method',
        key: 'paymentMethod',
        dataIndex: 'paymentMethod',
        render: (paymentMethod) => <span>{paymentMethod}</span>,
      },
      {
        title: 'Date Approved',
        key: 'approvalDate',
        dataIndex: 'approvalDate',
        render: (approvalDate) => (
          <div style={{ width: '100px' }}>
            {approvalDate ? formatDate(new Date(approvalDate), 'LLL do, yyyy') : '00:00'}
          </div>
        ),
      },
      {
        title: 'Approver',
        key: 'approver',
        dataIndex: 'approver',
        render: (approver, data) => {
          return (
            <Space>
              <Space>{approver}</Space>
              <Space style={{ cursor: 'pointer', color: '#0CB230' }}>
                {data?.approverComment && (
                  <Popover content={data?.approverComment} trigger="click">
                    <MessageFilled />
                  </Popover>
                )}
              </Space>
            </Space>
          );
        },
      },
    ];

    return (
      <div>
        <Table
          columns={columns}
          dataSource={invoiceData?.payments}
          size="large"
          pagination={false}
        />
      </div>
    );
  };

  return (
    <Modal
      open={showApprovalForm}
      title={
        <div style={{ display: 'flex' }}>
          <h3 style={{ flexGrow: 1 }}>Confirm Payment</h3>
          <CloseIcon className="close-icon" onClick={() => toggleApprovalForm(false)} />
        </div>
      }
      footer={null}
      centered={true}
      className="standard-report-modal"
    >
      <div>
        <div style={{ display: 'flex' }}>
          <div className="reporting-form__subheading" style={{ padding: '0.8rem', flexGrow: 1 }}>
            <strong>{invoiceData?.facilityName}</strong> | <span>{invoiceData?.invoiceTitle}</span>
          </div>
          <div>
            Expected Amount:{' '}
            <span style={{ color: '#7CBDFF', fontWeight: 'bold' }}>
              {Intl.NumberFormat('en-NG', {
                currency: 'NGN',
                style: 'currency',
              }).format(invoiceData?.outstandingAmount)}
            </span>
          </div>
        </div>
        {/* <Divider /> */}
        {invoiceData.invoiceStatus !== 'CLOSED' && (
          <div style={{ marginBottom: '5px' }}>
            <Form
              initialValues={{
                bankName: '',
                bankAccountName: '',
                tellerNo: '',
                amount: 0,
                comment: '',
                datePaid: '',
                paymentMethod: paymentMethod[0].value,
              }}
              layout="vertical"
              onFinish={handleFormSubmit} // Call onFinish for form submission
            >
              <Row
                gutter={5}
                style={{
                  border: '1px solid lightgray',
                  borderRadius: '10px',
                  backgroundColor: '#F6F6F6',
                  padding: '1rem',
                }}
              >
                <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}></Col>
                <Col lg={{ span: 4 }} md={{ span: 4 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Form.Item
                    label="Payment Method"
                    name="paymentMethod"
                    rules={[{ required: true, message: 'Please enter bank payment method!' }]}
                  >
                    <Select
                      defaultValue={paymentMethod[0].value}
                      onChange={(e) => {
                        setSelectedPaymentMethod(e);
                      }}
                      options={paymentMethod}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} md={{ span: 4 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                  <Form.Item
                    label="Bank Name"
                    name="bankName"
                    rules={[
                      {
                        required: selectedPaymentMethod === paymentMethod[1].value ? true : false,
                        message: 'Please enter bank name!',
                      },
                    ]}
                  >
                    <Input
                      disabled={selectedPaymentMethod === paymentMethod[1].value ? false : true}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} md={{ span: 4 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                  <Form.Item
                    label="Bank Account Name"
                    name="bankAccountName"
                    rules={[
                      {
                        required: selectedPaymentMethod === paymentMethod[1].value ? true : false,
                        message: 'Please enter bank account name!',
                      },
                    ]}
                  >
                    <Input
                      disabled={selectedPaymentMethod === paymentMethod[1].value ? false : true}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} md={{ span: 4 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                  <Form.Item
                    label="Paid Amount"
                    name="amount"
                    rules={[{ required: true, message: 'Please enter paid amount!' }]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} md={{ span: 4 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                  <Form.Item
                    label="Date Paid"
                    name="datePaid"
                    initialValue={invoiceData?.outstandingAmount}
                    rules={[{ required: true, message: 'Please enter date!' }]}
                  >
                    <DatePicker />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} md={{ span: 4 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Form.Item label="Reference No." name="tellerNo">
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} md={{ span: 4 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Form.Item valuePropName="fileList">
                    <Upload
                      listType="picture-card"
                      fileList={fileList}
                      onChange={handleFileChange}
                      beforeUpload={() => false} // Prevent default upload behavior
                    >
                      {fileList.length < 1 && (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Upload Receipt</div>
                        </div>
                      )}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col lg={{ span: 20 }} md={{ span: 20 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Form.Item label="Comment" name="comment" style={{ width: '100%' }}>
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Button type="primary" appearance="default" htmlType="submit" disabled={loading}>
                    Confirm {loading && <Spin indicator={<LoadingOutlined spin />} size="small" />}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        )}
        <br />
        <div>
          <div
            style={{
              height: '100%',
            }}
          >
            {renderPaymentTable()}
          </div>
        </div>
      </div>
    </Modal>
  );
}
