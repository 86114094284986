import React, { useState } from 'react';
import { Modal, Row, Col, message, Divider } from 'antd';
import Button from '../../../components/common/Button';
import { LockFilled } from '@ant-design/icons';
import { scheduleFacility } from '../../../helpers/api';
import CalendarOpen from '../../../assets/img/calendar-18.svg';
import './Schedule.css';
import ModalHeader from './ModalHeader';
const currentYear = new Date().getFullYear();

const ScheduleFacilityModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [disableLock, setDisableLock] = useState(false);

  const lockFacilitiesToQuarter = (quarter) => {
    setLoading(true);
    handleLocking(quarter);
  };
  const handleLocking = (id) => {
    const payload = {
      quarter: id,
      year: currentYear,
      facilityIds: props.singleFacility,
    };
    scheduleFacility(payload)
      .then((res) => {
        setDisableLock(true);
        console.log(res);
        message.success(res.result.status.desc);
        props.close();
        setLoading(false);
        props.reloadFacility(true);
        props.reloadMetrics(true);
      })
      .catch((err) => {
        message.error(err);
        setLoading(false);
      });
  };
  return (
    <Modal
      className="schedule__modal"
      visible={props.open}
      onCancel={props.close}
      footer={false}
      title={<ModalHeader closeModal={props.close} />}
    >
      <Row justify="space-between" className="mt-15">
        <Col lg={5}>
          <div className="schedule__card">
            <div className="schedule__heading">
              <Row>
                <Col lg={24}>
                  <p className="text-blue small-text mb-15 bold">
                    {props.quarterMetrics.firstQuarter === 0 ? (
                      'No Facilities Locked'
                    ) : props.quarterMetrics.firstQuarter === 1 ? (
                      <>{props.quarterMetrics.firstQuarter} Facility Locked</>
                    ) : (
                      <>{props.quarterMetrics.firstQuarter} Facilities Locked</>
                    )}
                  </p>
                </Col>
                <Col lg={5} className="">
                  <img src={CalendarOpen} className="schedule__icon" />
                </Col>
                <Col lg={16} className="">
                  <h3>First Quarter</h3>
                  <p>Jan - Mar</p>
                </Col>
                <Col lg={24}>
                  <Button
                    className="lock__btn"
                    onClick={(quarter) => lockFacilitiesToQuarter(1)}
                    disabled={loading}
                  >
                    <LockFilled style={{ marginRight: '3px' }} />
                    Select Facility
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <Col lg={1} className="divider__container">
          <Divider type="vertical" />
        </Col>

        <Col lg={5}>
          <div className="schedule__card">
            <div className="schedule__heading">
              <Row>
                <Col lg={24}>
                  <p className="small-text text-blue mb-15 bold">
                    {props.quarterMetrics.secondQuarter === 0 ? (
                      'No Facilities Locked'
                    ) : props.quarterMetrics.secondQuarter === 1 ? (
                      <>{props.quarterMetrics.secondQuarter} Facility Locked</>
                    ) : (
                      <>{props.quarterMetrics.secondQuarter} Facilities Locked</>
                    )}
                  </p>
                </Col>
                <Col lg={5}>
                  <img src={CalendarOpen} className="schedule__icon" />
                </Col>
                <Col lg={16}>
                  <h3>Second Quarter</h3>
                  <p>Apr - Jun</p>
                </Col>

                <Col lg={24}>
                  <Button
                    className="lock__btn"
                    onClick={(quarter) => lockFacilitiesToQuarter(2)}
                    disabled={loading}
                  >
                    <LockFilled style={{ marginRight: '3px' }} />
                    Select Facility
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <Col lg={1} className="divider__container">
          <Divider type="vertical" />
        </Col>

        <Col lg={5}>
          <div className="schedule__card">
            <div className="schedule__heading">
              <Row>
                <Col lg={24}>
                  <p className="small-text text-blue mb-15 bold">
                    {props.quarterMetrics.thirdQuarter === 0 ? (
                      'No Facilities Locked'
                    ) : props.quarterMetrics.thirdQuarter === 1 ? (
                      <>{props.quarterMetrics.thirdQuarter} Facility Locked</>
                    ) : (
                      <>{props.quarterMetrics.thirdQuarter} Facilities Locked</>
                    )}
                  </p>
                </Col>
                <Col lg={5}>
                  <img src={CalendarOpen} className="schedule__icon" />
                </Col>
                <Col lg={16}>
                  <h3>Third Quarter</h3>
                  <p>Jul - Sep</p>
                </Col>
                <Col lg={24}>
                  <Button
                    className="lock__btn"
                    onClick={(quarter) => lockFacilitiesToQuarter(3)}
                    disabled={loading}
                  >
                    <LockFilled style={{ marginRight: '3px' }} /> Select Facility
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <Col lg={1} className="divider__container">
          <Divider type="vertical" />
        </Col>

        <Col lg={5}>
          <div className="schedule__card">
            <div className="schedule__heading">
              <Row>
                <Col lg={24}>
                  <p className="small-text text-blue mb-15 bold">
                    {props.quarterMetrics.fourthQuarter === 0 ? (
                      'No Facilities Locked'
                    ) : props.quarterMetrics.fourthQuarter === 1 ? (
                      <>{props.quarterMetrics.fourthQuarter} Facility Locked</>
                    ) : (
                      <>{props.quarterMetrics.fourthQuarter} Facilities Locked</>
                    )}
                  </p>
                </Col>
                <Col lg={5}>
                  <img src={CalendarOpen} className="schedule__icon" />
                </Col>
                <Col lg={16}>
                  <h3>Fourth Quarter</h3>
                  <p>Oct - Dec</p>
                </Col>
                <Col lg={24}>
                  <Button
                    className="lock__btn"
                    onClick={(quarter) => lockFacilitiesToQuarter(4)}
                    disabled={loading}
                  >
                    <LockFilled style={{ marginRight: '3px' }} /> Select Facility
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default ScheduleFacilityModal;
