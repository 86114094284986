import React from 'react';
import BreadCrumb from '../../common/BreadCrumb';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import ChangePasswordForm from './ChangePasswordForm';
import ChangeProfileForm from './ChangeProfileForm';

const ProfileContent = ({ className }) => {
  return (
    <div className={className}>
      <Row>
        <Col
          lg={{
            span: 20,
          }}
          offset={1}
        >
          <BreadCrumb
            previousPath="/settings"
            previousView="Settings"
            currentView="Profile Settings"
          />
        </Col>
      </Row>
      <Row>
        <Col
          lg={{
            span: 20,
          }}
          offset={1}
        >
          <div className="profile-heading">
            <h3>Profile Settings</h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          lg={{
            span: 18,
          }}
          offset={1}
        >
          <section className="profile profile-section">
            <Row>
              <Col
                sm={{
                  span: 24,
                }}
                md={{
                  span: 24,
                }}
                lg={{
                  span: 6,
                }}
              >
                <p className="section__heading">Edit Profile</p>
                <h3>Change Profile Details</h3>
              </Col>
              <Col
                sm={{
                  span: 24,
                }}
                md={{
                  span: 24,
                }}
                lg={{
                  span: 16,
                }}
              >
                <ChangeProfileForm />
              </Col>
            </Row>
          </section>
          <section className="profile password-section">
            <Row>
              <Col
                sm={{
                  span: 24,
                }}
                md={{
                  span: 24,
                }}
                lg={{
                  span: 6,
                }}
              >
                <p className="section__heading">Edit Profile</p>
                <h3>Change Password</h3>
              </Col>
              <Col
                sm={{
                  span: 24,
                }}
                md={{
                  span: 24,
                }}
                lg={{
                  span: 16,
                }}
              >
                <ChangePasswordForm />
              </Col>
            </Row>
          </section>
        </Col>
      </Row>
    </div>
  );
};

export default styled(ProfileContent)`
  .profile-heading {
    margin-top: 35px;
    h3 {
      font-family: var(--font-family-bold);
      font-size: 23px;
    }
  }
  .profile {
    background: rgba(10, 10, 120, 0.05);
    border-top: solid 1px rgba(0, 0, 0, 0.1);
    border-bottom: none;
    margin-bottom: 20px;
    padding: 40px 0px;
    font-family: var(--font-family);
    background: #fff;
    @media screen and (max-width: 768px) {
      padding: 40px 20px;
    }
    p.section__heading {
      color: #828ea9;
      font-size: 18px;
    }
  }
`;
