import React from 'react';
import { Modal } from 'antd';
import Form from './EditSectorForm';

const EditSectorModal = ({ open, close, reloadSectors, sectorName, sectorId }) => {
  return (
    <Modal visible={open} onCancel={close} title="Edit Sector" footer={false} className="edit__sector__modal">
      <Form reloadSector={reloadSectors} closeModal={close} id={sectorId} name={sectorName} />
    </Modal>
  );
};

export default EditSectorModal;
