import React, { useState } from 'react';
import { Alert, Popconfirm, Tree } from 'antd';
import { CarryOutOutlined, MoreOutlined } from '@ant-design/icons';
import compareDesc from 'date-fns/compareDesc';
import moment from 'moment';
import { properCase, formatDate, datelize } from '../../helpers/utils';
import Table from '../../components/common/Table';
import Menu from '../../components/common/Menu';
import Dropdown from '../../components/common/Dropdown';
// import Popover from 'components/common/Popover';
import { actionInProgress, openNotification } from '../../helpers/notification';
import './index.css';
import '../../scrollfix.css';
import { LockFilled } from '@ant-design/icons';
import { toggleQaqcReportVisibilityApi } from '../../helpers/api';
import LoadingContent from '../common/LoadingContent';

let overflowMenu, moreOutlinedIcon;

const popMe = (which, data) => {
  let content;
  switch (which) {
    case 'address':
      content = (
        <div>
          <p>{data ? properCase(data) : 'Not Available'}</p>
        </div>
      );
      break;
    case 'samplePoints':
      let treeData =
        data && data.length > 0
          ? data.map((dt, index) => ({
              title: dt.samplePointName,
              key: `${dt.samplePointId} ${index / 100}-${index / 10}-${index % 10}`,
              icon: <CarryOutOutlined />,
              children: [
                {
                  title: `Sample Point Type: ${
                    dt.samplePointType ? properCase(dt.samplePointType) : ''
                  }`,
                  key: `${index / 1000}-${index / 100}-${index / 10}-1`,
                  icon: <CarryOutOutlined />,
                },
                {
                  title: 'Coordinates',
                  key: `${index / 1000}-${index / 100}-${index / 10}-2`,
                  icon: <CarryOutOutlined />,
                  children: [
                    {
                      title: `lat: ${dt.coordinates ? dt.coordinates.latitude : ''}`,
                      key: index + '-0-1',
                    },
                    {
                      title: `lng: ${dt.coordinates ? dt.coordinates.longitude : ''}`,
                      key: index + '-0-2',
                    },
                  ],
                },
                {
                  title: `Entry Date: ${formatDate(new Date(dt.entryDate), 'LLL do, yyyy p')}`,
                  key: `${index / 1000}-${index / 100}-${index / 10}-3`,
                  icon: <CarryOutOutlined />,
                },
              ],
            }))
          : [];
      content = <Tree showLine={true} showIcon={false} treeData={treeData} />;
      break;
    case 'personnels':
      let persData =
        data && data.length > 0
          ? data.map((dt, index) => ({
              title: `${dt.fullName} (${properCase(dt.designation)})`,
              key: `${dt.fullName}-${index / 100}-${index / 10}-${index % 10}`,
              icon: <CarryOutOutlined />,
              children: [
                {
                  title: `Email: ${dt.email ? dt.email : ''}`,
                  key: `email-${index / 1000}-${index / 100}-${index / 10}-2`,
                  icon: <CarryOutOutlined />,
                },
                {
                  title: `Phone: ${dt.phone ? dt.phone : 'N|A'}`,
                  key: `phone-${index / 1000}-${index / 100}-${index / 10}-2`,
                  icon: <CarryOutOutlined />,
                },
              ],
            }))
          : [];
      content = <Tree showLine={true} showIcon={false} treeData={persData} />;
      break;
    default:
      break;
  }
  return content;
};

const handlePeriodFilter = (entryDate, periodX) => {
  // console.log('entry data', entryDate);
  // console.log('period x', periodX);

  // console.log(
  //   'compare is same or after',
  //   moment(entryDate).isSameOrAfter(moment(periodX[0], 'day'))
  // );

  // console.log('is same or before', moment(entryDate).isSameOrBefore(moment(periodX[1], 'day')));

  return (
    moment(entryDate).isSameOrAfter(moment(periodX[0], 'day')) &&
    moment(entryDate).isSameOrBefore(moment(periodX[1], 'day'))
  );
};

let facilityX, consultantX, quarterX, reportTypeX, statusX, sectorX, stateX, eiaStatusX, periodX;

const ReportTable = ({
  userCategory,
  userPerm,
  data,
  loading,
  setReport,
  setModal,
  facilityFilter,
  consultantFilter,
  statusFilter,
  reportTypeFilter,
  quarterFilter,
  stateFilter,
  sectorFilter,
  eiaFilter,
  periodFilter,
}) => {
  const [row, setRow] = useState('');
  consultantX = consultantFilter;
  statusX = statusFilter;
  reportTypeX = reportTypeFilter;
  quarterX = quarterFilter;
  sectorX = sectorFilter;
  stateX = stateFilter;
  facilityX = facilityFilter;
  eiaStatusX = eiaFilter;
  periodX = periodFilter;

  const userCat = userCategory.trim().toLowerCase();
  const isConsultant = userCat === 'consultant';
  // const canViewReport = userCat === 'edm' || userCat === 'regulator' || userCat === 'consultant';
  // const canViewComparativeReport = userCat === 'regulator' || userCat === 'edm';
  const canViewReport = userPerm.includes('view_report');
  const canViewComparativeReport = userPerm.includes('view_comparative_report');
  const canLockUnlockReport = userPerm.includes('lock_report');

  const [loadingAction, setLoadingAction] = useState();

  const handleLockUnlockReport = () => {
    setLoadingAction(true);
    const payload = [row.reportId];
    toggleQaqcReportVisibilityApi(payload)
      .then((response) => {
        setLoadingAction(false);
        return openNotification({
          type: 'success',
          title: 'Success',
          //message: `${errorMessage}`,
        });
      })
      .catch((error) => {
        setLoadingAction(false);
        const errorMessage = error.data?.result?.status?.desc;
        return openNotification({
          type: 'error',
          title: 'Something went wrong',
          message: `${errorMessage}`,
        });
      });
  };

  const columns = [
    {
      title: 'Report Date',
      dataIndex: 'entryDate',
      key: 'entryDate',
      //width: '0.8%',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => compareDesc(new Date(a.entryDate), new Date(b.entryDate)),
      render: (entryDate) => {
        // return <span>{entryDate ? formatDate(new Date(entryDate), 'LLL do') : '00:00'}</span>;
        const date = new Date(entryDate).toLocaleDateString('en-NG', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });

        return <span>{date}</span>;
      },
      ellipsis: true,
    },
    {
      title: 'Facility',
      dataIndex: 'facility',
      key: 'facility',
      ellipsis: true,
      render: (facility, record) => {
        const spChecked = record.checkedSamplePointCount;
        return (
          <div>
            <div>{facility}</div>
            <div style={{ fontSize: '0.8em', color: 'gray' }}>{`${spChecked} ${
              spChecked > 1 ? 'Sample Points Checked' : 'Sample Point Checked'
            }`}</div>
          </div>
        );
      },
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a.facility > b.facility ? 1 : -1),
      onFilter: (value, record) => (facilityX ? record.facility === facilityX : record),
      filterDropdownVisible: true,
      filteredValue: [facilityX],
    },
    {
      title: 'Report Period',
      //width: '0.8%',
      dataIndex: 'facility',
      sorter: (a, b) => (a.reportTitle > b.reportTitle ? 1 : -1),
      onFilter: (value, record) => (quarterX ? record.reportQuarter === quarterX : record),
      filterDropdownVisible: true,
      filteredValue: [quarterX],
      ellipsis: true,
      render: (_, records) => {
        if (records.type && records.type.toLowerCase() === 'adhoc') {
          let entryDate = records.entryDate;
          let dueDate = records.dueDate;
          return (
            <span>
              {entryDate && dueDate
                ? `${formatDate(new Date(entryDate), 'LLL do')} - ${formatDate(
                    new Date(dueDate),
                    'LLL do'
                  )}`
                : '00:00'}
            </span>
          );
        }
        return (
          <span>
            {records.reportQuarter && records.reportYear
              ? `Q${records.reportQuarter}, ${records.reportYear}`
              : 'Not Available'}
          </span>
          // <span>{rqtr ? `${numeralize(rqtr)} Quarter, ${rqtr.split(',')[1]}` : 'Not Available'}</span>
        );
      },
      onFilter: (entryDate, record) =>
        periodX && periodX[0] ? handlePeriodFilter(record.entryDate, periodX) : record,
      filterDropdownVisible: true,
      filteredValue: [periodX],
      key: 'entryDate',
    },
    // {
    //   title: 'Sample Points Checked',
    //   dataIndex: 'checkedSamplePointCount',
    //   key: 'checkedSamplePointCount',
    //   //width: '1%',
    //   render: (samplePt, record) => (
    //     // <Popover
    //     //   placement="topLeft"
    //     //   title={'Sample Points'}
    //     //   content={() => popMe('samplePoints', record.checkedSamplePointCount)}
    //     //   trigger="hover"
    //     //   className="facility-table__popover sample-point__popover"
    //     // >
    //     <span>{`${samplePt} ${samplePt > 1 ? 'Sample Points' : 'Sample Point'}`}</span>
    //     // </Popover>
    //   ),
    //   ellipsis: true,
    // },

    // {
    //   title: 'Contact Person',
    //   dataIndex: 'facilityPersonels',
    //   key: 'facilityPersonels',
    //   width: '1.2%',
    //   render: (facilityPersonels) => (
    //     <Popover
    //       placement="topLeft"
    //       title={'Personnels'}
    //       content={() => popMe('personnels', facilityPersonels)}
    //       trigger="hover"
    //       className="facility-table__popover sample-point__popover"
    //     >
    //       <span>
    //         {facilityPersonels && facilityPersonels.length > 0 ? (
    //           facilityPersonels.length > 1 ? (
    //             <>
    //               <p className="facility-personnel__others">
    //                 {`${properCase(facilityPersonels[0].fullName)}`}
    //                 {` & ${facilityPersonels.length - 1} ${
    //                   facilityPersonels.length > 2 ? 'others' : 'other'
    //                 }`}
    //               </p>
    //             </>
    //           ) : (
    //             `${properCase(facilityPersonels[0].fullName)}`
    //           )
    //         ) : (
    //           <p className="facility-personnel__notaval">Not Available</p>
    //         )}
    //       </span>
    //     </Popover>
    //   ),
    //   sorter: (a, b) => (a.facilityPersonels > b.facilityPersonels ? 1 : -1),
    //   ellipsis: true,
    // },
    {
      title: 'Report Type',
      dataIndex: 'reportType',
      key: 'reportType',
      //width: '0.7%',
      // fixed: 'right',
      onFilter: (value, record) => (reportTypeX ? record.reportType === reportTypeX : record),
      filterDropdownVisible: true,
      defaultFilteredValue: ['NORMAL'],
      filteredValue: [reportTypeX],
      render: (rType) => (
        <span
          className={
            String(rType)
              .trim()
              .toLowerCase() === 'qaqc'
              ? 'qaqc'
              : 'not-qaqc'
          }
        >
          {rType ? (rType === 'QAQC' ? 'QAQC' : properCase(String(rType).trim())) : 'NA'}
        </span>
      ),
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (String(a.rType).toLowerCase() > String(b.rType).toLowerCase() ? 1 : -1),
      ellipsis: true,
    },
    {
      title: 'Consultant',
      dataIndex: 'facilityOperators',
      key: '3',
      onFilter: (value, record) =>
        consultantX
          ? consultantX === 'Not Available'
            ? !record.facilityOperators
            : record.facilityOperators &&
              record.facilityOperators.length > 0 &&
              properCase(
                `${record.facilityOperators[0].firstName} ${record.facilityOperators[0].lastName}`
              ) === consultantX
          : record,
      filterDropdownVisible: true,
      // defaultFilteredValue: [{ text: 'ME', value: '' }],
      filteredValue: [consultantX],

      //width: '1%',
      render: (facilityOperators) => (
        <span>
          {facilityOperators && facilityOperators.length > 0 ? (
            facilityOperators.length > 1 ? (
              // <span className="facility-personnel__others">
              <span>
                {properCase(
                  `${facilityOperators[0].firstName} ${facilityOperators[0].lastName} & ${
                    facilityOperators.length > 2
                      ? `${facilityOperators.length - 1} others`
                      : '1 other'
                  }`
                )}
              </span>
            ) : (
              `${properCase(facilityOperators[0].firstName)} ${properCase(
                facilityOperators[0].lastName
              )}`
            )
          ) : (
            <p className="facility-personnel__notaval">Not Available</p>
          )}
        </span>
      ),
      ellipsis: true,
    },
    {
      title: 'Report Status',
      dataIndex: 'reportStatus',
      key: 'reportStatus',
      ellipsis: true,
      onFilter: (value, record) =>
        statusX && typeof record.reportStatus === 'string'
          ? record.reportStatus.toLowerCase().includes(statusX.toLowerCase().trim())
          : record,

      filterDropdownVisible: true,
      filteredValue: [statusX],

      render: (status, record) => {
        if (status === 'DRAFT')
          return (
            <Alert
              message={
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div style={{ flexGrow: 1 }}>{properCase(status)}</div>
                  <div>
                    {!record.isVisible && record.reportType === 'QAQC' && (
                      <LockFilled style={{ marginRight: '5px', color: '#2e5bff' }} />
                    )}
                  </div>
                </div>
              }
              type="warning"
              style={{ fontSize: '0.8em', textAlign: 'center', padding: '0.3rem', width: '70px' }}
            />
          );
        if (status === 'PUBLISHED')
          return (
            <Alert
              message={
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div style={{ flexGrow: 1 }}>{properCase(status)}</div>
                  <div>
                    {!record.isVisible && record.reportType === 'QAQC' && (
                      <LockFilled style={{ marginRight: '5px', color: '#2e5bff' }} />
                    )}
                  </div>
                </div>
              }
              type="success"
              style={{ fontSize: '0.8em', textAlign: 'center', padding: '0.3rem', width: '70px' }}
            />
          );

        // return (
        //   <span
        //     className={
        //       String(status)
        //         .trim()
        //         .toLowerCase() === '1' || String(status).toLowerCase() === 'draft'
        //         ? 'not-active'
        //         : 'active'
        //     }
        //   >
        //     {String(status) === '1' || String(status).toLowerCase() === 'draft'
        //       ? 'Draft'
        //       : properCase(status)}
        //   </span>
        // );
      },
      sorter: (a, b) => (a.sttus > b.sttus ? 1 : -1),
    },
    // {
    //   // title: 'Sector',
    //   // dataIndex: 'sectorName',
    //   key: 'reportSector',
    //   //width: '0%',
    //   // fixed: 'right',
    //   onFilter: (value, record) =>
    //     sectorX && typeof record.sectorName === 'string'
    //       ? record.sectorName.toLowerCase().includes(sectorX.toLowerCase().trim())
    //       : record,
    //   filterDropdownVisible: true,
    //   filteredValue: [sectorX],
    //   // render: (sector) => <span>{String(sector)}</span>,
    //   ellipsis: true,
    // },
    // {
    //   key: 'eia',
    //   width: '0%',
    //   onFilter: (value, record) =>
    //     eiaStatusX && typeof record.facilityEia === 'string'
    //       ? record.facilityEia.toLowerCase().trim() === eiaStatusX.toLowerCase().trim()
    //       : record,
    //   filterDropdownVisible: true,
    //   filteredValue: [eiaStatusX],
    //   ellipsis: true,
    // },
    // {
    //   key: 'stateName',
    //   width: '0%',
    //   onFilter: (value, record) =>
    //     stateX && typeof record.stateName === 'string'
    //       ? record.stateName.toLowerCase().includes(stateX.toLowerCase().trim())
    //       : record,
    //   filterDropdownVisible: true,
    //   filteredValue: [stateX],
    //   ellipsis: true,
    // },
    {
      title: '',
      key: '111',
      fixed: 'right',
      width: '0.3%',
      render: (_, record) => {
        if (!record.isVisible && record.reportType === 'QAQC' && isConsultant) {
          return <LockFilled style={{ marginRight: '5px', color: '#2e5bff' }} />;
        } else {
          return (
            <Dropdown overlay={overflowMenu} trigger={['click']}>
              <a
                className="ant-dropdown-link"
                href="/#"
                onClick={(e) => e.preventDefault() && actionInProgress()}
              >
                {moreOutlinedIcon(record)}
              </a>
            </Dropdown>
          );
        }
      },
    },
  ];

  moreOutlinedIcon = (record) => (
    <MoreOutlined
      className="icon-more-outline"
      onClick={() => setRow(record)}
      // onMouseEnter={() => setRow(record)}
    />
  );

  overflowMenu = (
    <Menu className="facility-overflow__menu" style={{ width: '150px' }}>
      {canViewReport && (
        <Menu.Item
          onClick={() => {
            setReport({ type: 'viewing', data: row });
            setModal('viewing');
          }}
        >
          <span>View Report</span>
        </Menu.Item>
      )}

      {canViewComparativeReport && !isConsultant && (
        <Menu.Item
          onClick={() => {
            setReport({ type: 'comparing', data: row });
            setModal('compare');
          }}
        >
          <span>Compare Result</span>
        </Menu.Item>
      )}

      {canLockUnlockReport && (
        <Popconfirm
          title={
            <>
              {/* <h3>Submitting is final </h3> */}
              <p>
                {row.isVisible
                  ? 'Are you sure you want to lock report? '
                  : 'Are you sure you want to unlock the report?'}
              </p>
            </>
          }
          onConfirm={handleLockUnlockReport}
          okText="Yes"
          cancelText="No"
        >
          <Menu.Item
            onClick={() => {
              // setReport({ type: 'comparing', data: row });
              // setModal('compare');
            }}
          >
            <span>{row.isVisible ? 'Lock Report' : 'Unlock Report'}</span>
          </Menu.Item>
        </Popconfirm>
      )}
    </Menu>
  );

  const keyedData = data;
  keyedData.forEach((dt) => (dt['key'] = dt.facilityId));

  // if (keyedData && keyedData.length > 0) {
  //   filterByFacility = filter(keyedData, 'facility');
  // }

  let xColumns = [...columns];
  if (isConsultant) xColumns = columns.filter((cl) => cl.title !== 'Consultant');

  return (
    <div>
      {loadingAction && (
        <div
          style={{
            position: 'absolute',
            overflow: 'hidden',
            width: '100%',
            height: '90vh',
            zIndex: 1,
            display: 'flex',
            background:"#FCFAFA",
            opacity: 0.8,
          }}
        >
          <LoadingContent pageLoading />
        </div>
      )}

      <Table
        columns={xColumns}
        dataSource={keyedData}
        // scroll={{ x: 800, y: 1200 }}
        // style={style}
        mainTable={true}
        rowClassName={'facility-table__rows'}
        loading={(!data && !data.length) || loading}
      />
    </div>
  );
};

export default ReportTable;
