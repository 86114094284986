import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Row, Col, Radio, Popover, message } from 'antd';
import { UserContext } from '../../../store/UserContext';
import { loadState } from '../../../store/localStorage';
import PopoverTitle from './PopoverTitle';
import LoadingContent from '../../../components/common/LoadingContent';
// import { openNotification } from 'helpers/notification';
import { getRolesByUserCategory } from '../../../helpers/api';
import { properCase } from '../../../helpers/utils';
import { rolesPerCategory } from '../../../helpers/appstate';
import { FaInfoCircle } from 'react-icons/fa';
import { InfoCircleFilled } from '@ant-design/icons';

const UserRoles = ({ className, invite }) => {
  const [AccountId, setAccountId] = useState('');
  const [Roles, setRoles] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [DefaultRole, setDefaultRole] = useState('');
  const [RolePrivileges, setRolePrivileges] = useState([]);

  const { updateUserRole, Category } = useContext(UserContext);

  useEffect(() => {
    getRoles();
  }, [Category]);

  useEffect(() => {
    if (DefaultRole) setDefaultRole('');
  }, [Category]);

  useEffect(() => {
    filterRoles();
  });
  useEffect(() => {
    getAccountId();
  });

  // GET LOGGED IN USER ACCOUNT ID
  const getAccountId = () => {
    const accountId = loadState().auth.result.data.accountId;
    setAccountId(accountId);
  };

  // FETCH CREATED ROLES
  const getRoles = () => {
    setLoading(true);
    console.log(Category, 'role cat');
    getRolesByUserCategory(Category)
      .then((res) => {
        console.log(res.result.data);
        if (res.result.status.code === '00') {
          setRoles(res.result.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, 'roles', Roles);
        setLoading(false);
        message.error(error && error.message ? error.message : 'Error getting roles');
      });
  };

  // GETS LIST OF PRIVILEGES FOR EACH ROLE
  const getRolePermissions = (ev) => {
    ev.stopPropagation();
    const roleId = ev.currentTarget.dataset.roleid;

    let permissions = Roles.filter((role) => role.roleId === roleId);
    if (permissions) {
      setRolePrivileges(permissions[0].privileges);
    }
  };

  // ADDS EVENT LISTENER TO ACTION BUTTON THAT DISPLAY POPUP
  const filterRoles = () => {
    const actionBtn = document.querySelectorAll('.role-action');
    for (let i = 0; i < actionBtn.length; i++) {
      actionBtn[i].addEventListener('mouseover', getRolePermissions);
    }
  };

  // LISTENS FOR SELECTED ROLE
  const onChange = (e) => {
    setDefaultRole(e.target.value);
    updateUserRole(e.target.value, e.target.name);
  };

  // CONTENT FOR ROLES POPUP
  const getContent = () => {
    if (RolePrivileges.length > 0) {
      return RolePrivileges.map((permission) => {
        return (
          <div className="content-container" key={permission.privilegeId}>
            <div>
              <div className="box box-hidden">
                <span className="dark bold">{permission.subDivision.toLowerCase()}</span>
                <span className="light">{permission.description}</span>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="content-container">
          <b>No Permissions for this role</b>
        </div>
      );
    }
  };

  const disableRadioCategory = (role, cat) => {
    // return true to disable radio option
    // if (!cat || !rolesPerCategory[cat]) return true;
    // if (rolesPerCategory[cat].includes(role.roleName)) {
    //   return false;
    // }
    // return true;
  };

  // DISPLAY ALL ROLES
  const displayRoles = () => {
    if (Roles !== null) {
      if (Roles.length > 0) {
        return Roles.map((role) => {
          const roleDesc = properCase(role.roleDescription);
          
          return (
            <div className="role-list mb-20" key={role.roleId}>
              <Row>
                <Col span={8}>
                  <Radio.Group
                    onChange={onChange}
                    name={role.roleName}
                    id={role.roleId}
                    value={DefaultRole}
                  >
                    <Radio value={role.roleId} className="role-name bold small-text">
                      {role.roleName}
                    </Radio>
                  </Radio.Group>
                </Col>
                <Col span={12}>
                  <p className="role-description">{roleDesc}</p>
                </Col>
                <Col span={2} className="text-right">
                  <Popover
                    content={getContent()}
                    title={
                      <PopoverTitle
                        roleName={role.roleName}
                        key={role.roleId}
                        description={role.roleDescription}
                      />
                    }
                  >
                   

                    <InfoCircleFilled
                      className="role-action"
                      data-roleid={role.roleId}
                      style={{
                        color: '#0c5bab',
                        background: '#fff',
                        fontSize: '1.3rem'
                      }}
                    />
                  </Popover>
                </Col>
              </Row>
            </div>
          );
        });
      } else {
        return (
          <h3>
            No Role Created Yet! <br /> Go to settings to create new role
          </h3>
        );
      }
    } else {
      return <LoadingContent modal={true} />;
    }
  };

  return (
    <div className={className}>
      {Loading ? (
        <div className="loader">
          <LoadingContent modal={true} />
        </div>
      ) : (
        displayRoles()
      )}
    </div>
  );
};

export default styled(UserRoles)`
  margin-top: 2rem;
  font-family: var(--font-family);
  .ant-radio-checked .ant-radio-inner {
    background: #0052cc;
    border-color: #0052cc;
    &::after {
      background: #fff;
    }
  }
  .mb-3 {
    margin-bottom: 3rem;
  }
  .userRoles {
    padding: 1rem 0;
  }
  .role-description {
    color: #9b9b9b;
    font-size: 14px;
    text-transform: capitalize;
  }
  .description-content {
    height: 44px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .description-content .expand {
    height: auto;
  }
  .show-btn {
    z-index: 2;
  }
  .show-btn > .anticon {
    z-index: 1;
  }
  .show-btn > .anticon svg {
    z-index: 1;
  }
  .pull-right {
    float: right;
  }
  .description-tag {
    padding-right: 5px;
  }
  .text-right {
    text-align: right;
  }
`;
