import React, { useState, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';
import googleMapStyles from './gStyles';
import './index.css';
import GoogleMap from './GoogleMap';

const App = (props) => {
  const initialState = {
    allMarkers: [],
    markers: [],
    currentMarker: null,
    map: null,
    longitude: props.longitude,
    latitude: props.latitude,
    title: props.title,
    zoom: props.zoom ? props.zoom : 6, // 2
    xszoom: 4.5, // 1.5
    isDialogOpen: 'false', //has to be string since it's passed as a prop in html
  };

  const [state, setState] = useState(initialState);
  let mapRef = useRef(null);

  // METHODS TO INITIALIZE MAP & MARKERS
  // getGoogleMaps, initMap, setMarkersInitially, addEventListenersToMarker

  const updateState = (newState, which) => {
    const currState = { ...state };
    if (typeof which === 'string') currState[which] = newState;
    else which.forEach((wh) => (currState[wh] = newState[wh]));
    setState(currState);
    return true;
  };

  const getGoogleMaps = () => {
    if (!window.googleMapsPromise) {
      window.googleMapsPromise = new Promise((resolve) => {
        window.resolveGoogleMapsPromise = () => {
          resolve(window.google);
        };

        let script = document.createElement('script');
        let API = 'AIzaSyB85KWUMOtGfz0F0qazy_13P0oRT3JRbVYk';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&callback=resolveGoogleMapsPromise`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      });
    }

    return window.googleMapsPromise;
  };

  // initializes the map and injects it to a DOM elemet
  const initMap = (latN, longN) => {
    let googleMapDomNode = ReactDom.findDOMNode(mapRef.current);
    if (googleMapDomNode) {
      let center;
      let zoom;
      const lat = parseFloat(latN) || parseFloat(state.latitude);
      const lng = parseFloat(longN) || parseFloat(state.longitude);

      if (window.matchMedia('(min-width: 600px)').matches) {
        // re-arranges map center for smaller screens (phones, tablets etc.)
        center = { lat, lng };
        zoom = state.zoom;
      } else {
        center = { lat, lng };
        zoom = state.xszoom;
      }

      let map = new window.google.maps.Map(googleMapDomNode, {
        center: center,
        zoom: zoom,
        styles: googleMapStyles,
        disableDefaultUI: true,
      });

      //   updateState(map, 'map');
      return map;
    }
  };

  // sets the initiial markers on the map
  const setMarkersInitially = (map) => {
    let markers = [];
    const { longitude, latitude, title } = props;
    const lng = Number(longitude);
    const lat = Number(latitude);
    const ttle = typeof title === 'string' ? title : 'Location';
    const content = typeof title === 'string' ? title : 'Location';

    let customIcon = {
      // adjusted from https://raw.githubusercontent.com/scottdejonge/map-icons/master/src/icons/postal-code.svg

      path:
        'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
      fillColor: '#f59237',
      fillOpacity: 1,
      scale: 0.7,
      strokeColor: '#963535',
      strokeWeight: 1,
      size: new window.google.maps.Size(20, 32),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(0, 32),
    };

    if (lng && lat) {
      let marker = new window.google.maps.Marker({
        position: {
          lat: lat,
          lng: lng,
        },
        map: map,
        icon: customIcon,
        animation: window.google.maps.Animation.DROP,
        title: ttle,
        zIndex: 10,
      });

      let infoWindow = new window.google.maps.InfoWindow({
        content: content ? content : '',
      });

      addEventListenersToMarker(map, marker, infoWindow);

      markers.push(marker);
    }

    updateState({ markers, allMarkers: markers, map }, ['markers', 'allMarkers', 'map']);
  };

  // sets the mouseover, mouseout and click events for all markers
  const addEventListenersToMarker = (map, marker, infoWindow) => {
    marker.addListener('mouseover', () => {
      infoWindow.open(map, marker);
    });

    marker.addListener('mouseout', () => {
      infoWindow.close();
    });

    marker.addListener('click', () => {
      setCurrentMarker(marker);
      openDialog();
    });
  };

  /***************************************************************************/
  /************** METHODS FOR OTHER COMPONENTS' USE **************************/
  /***************************************************************************/

  // to render InfoBox on the view
  const openDialog = () => {
    updateState('true', 'isDialogOpen');
  };

  const setCurrentMarker = (marker) => {
    updateState(marker, 'currentMarker');
  };

  /***************************************************************************/
  /*************************** LIFECYCLE HOOKS *******************************/
  /***************************************************************************/

  useEffect(() => {
    if (!props.latitude || !props.longitude) {
      updateState(initialState, ['map', 'allMarkers']);
      return () => null;
    } else {
      getGoogleMaps().then(() => {
        const mapp = initMap(props.latitude, props.longitude);
        if (mapp) setMarkersInitially(mapp);
      });

      // fixes accessibility issues
      // (1) GoogleMaps generates an <iframe> without a title
      // (2) GoogleMaps generates a <div> that needs to be taken out of tabIndex
      window.addEventListener('load', function() {
        document.querySelector('iframe').title = 'Google Maps';
        window.setTimeout(function() {
          document.querySelector('.gm-style').children[0].setAttribute('tabindex', '-1');
        }, 1000);
      });
    }
    return () => null;
  }, [props]);

  return (
    <div className="app" role="main" style={{ width: '100%' }}>
      <GoogleMap ref={mapRef} />
    </div>
  );
};

export default App;
