import { Button, Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { getInvoiceById } from '../../../helpers/api';

export default function PaymentScreen(props) {
  const qryParam = new URLSearchParams(props.location.search);
  const ref = qryParam.get('ref');
  console.log(ref);

  const [data, setData] = useState();
  console.log(data);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const invoice = await getInvoiceById(ref);
    if (invoice.result.data === null) {
      console.log('no invoice');
      setData(invoice.result.data);
    } else {
      setData(invoice.result.data);
      //console.log('invoice');
    }
    //console.log(invoice);
  };

  const [form] = Form.useForm();

  const formRef = useRef();

  let initialValues = {
    bankName: '',
    accountNo: '',
    paymentDesc: '',
    tellerRef: '',
    paidAmount: 0,
  };

  const renderContent = () => {
    if (!data) {
      return <div>Page Not Found</div>;
    }

    if (data) {
      return content();
    }
  };

  const content = () => {
    return (
      <div
        style={{
          padding: '1em',
          //display: 'flex',
          //width: '100%',
          // justifyContent: 'center',
          // alignItems: 'center',
          // height: '100vh',
          //border: '1px solid gray',
          marginTop: '5rem',
          marginLeft: '10rem',
          marginRight: '10rem',
        }}
      >
        <div>
          <h2>
            To complete your payment, please enter the details of payment that was made and submit
          </h2>
          <br />
        </div>
        <div
          style={
            {
              //display: 'flex',
              //width:"400px"
              // justifyContent: 'center',
              // alignItems: 'center',
              // height: '100vh',
              // border: '1px solid gray',
            }
          }
        >
          <Row gutter={20}>
            <Col span={10}>
              <div style={{ border: '0px solid gray' }}>
                <Form
                  layout="vertical"
                  form={form}
                  initialValues={initialValues}
                  onFinish={() => {}}
                  ref={formRef}
                  onFinishFailed={() => {}}
                >
                  <Col>
                    <Form.Item
                      label="Bank Name"
                      name="bankName"
                      rules={[{ required: true, message: 'Bank Name name is required!' }]}
                      style={{ marginBottom: 0, display: 'block' }}
                    >
                      <Input
                        placeholder="Enter Bank Name"
                        className="form-controlx"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>

                  <br />

                  <Col>
                    <Form.Item
                      label="Account No."
                      name="accountNo"
                      rules={[{ required: true, message: 'Account No. is required!' }]}
                      style={{ marginBottom: 0, display: 'block' }}
                    >
                      <Input
                        placeholder="Enter Account No"
                        className="form-controlx"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>

                  <br />

                  <Col>
                    <Form.Item
                      label="Teller Reference No."
                      name="paymentDesc"
                      rules={[{ required: true, message: 'Teller No. is required!' }]}
                      style={{ marginBottom: 0, display: 'block' }}
                    >
                      <Input
                        placeholder="Enter Payment Description"
                        className="form-controlx"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>

                  <br />

                  <Col>
                    <Form.Item
                      label="Payment Description"
                      name="tellerRef"
                      //rules={[{ required: true, message: 'Account No. is required!' }]}
                      style={{ marginBottom: 0, display: 'block' }}
                    >
                      <Input
                        placeholder="Enter Account No"
                        className="form-controlx"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>

                  <br />

                  <Col>
                    <Form.Item
                      label="Payment Description"
                      name="paidAmount"
                      rules={[{ required: true, message: 'Paid Amount is required!' }]}
                      style={{ marginBottom: 0, display: 'block' }}
                    >
                      <Input
                        type="number"
                        placeholder="Enter Paid Amount"
                        className="form-controlx"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>

                  <br />
                  <Col>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </div>
            </Col>
            <Col span={10}>
              {' '}
              <div
                style={{
                  border: '0px solid gray',
                  boxShadow: '5px 5px 10px #888888',
                  padding: '2rem',
                }}
              >
                <Col>
                  <div>
                    <span>Invoice For:</span>
                    <br /> <h3>{data.reportTitle}</h3>
                  </div>
                </Col>
                <br />
                <Col>
                  <div>
                    {' '}
                    <span>Facility Name:</span>
                    <br /> <h3>{data.facilityName}</h3>
                  </div>
                </Col>
                <br />
                <Col>
                  <div>
                    <span>Amount Due:</span>
                    <br />{' '}
                    <h3>
                      {new Intl.NumberFormat('en-NG', {
                        style: 'currency',
                        currency: "NGN",
                      }).format(data.amount)}
                    </h3>
                  </div>
                </Col>
                <br />
                <Col>
                  <div>
                    <span>Invoice Status:</span>
                    <br /> <h3>{data.invoiceStatus}</h3>
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return renderContent();
}
