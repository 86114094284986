import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserContext } from '../../../store/UserContext';
import Check from '../../../assets/img/checkmark.svg';

import { Modal } from 'antd';
import ButtonComponent from '../../common/Button';

const ConfirmUserInvite = ({ className, openConfirmationModal, closeConfirmationModal }) => {
  const { users, RoleName } = useContext(UserContext);

  const displayInvitedUsers = (usersArr) => {
    return usersArr.map((user) => {
      return <span key={usersArr.length}>{`${user}, `}</span>;
    });
  };
  return (
    <Modal
      className={className}
      visible={openConfirmationModal}
      onCancel={closeConfirmationModal}
      footer={null}
    >
      <div className="ConfirmationContent">
        <img src={Check} alt="Check mark icon" />
        <h3>User has been created successfully!</h3>
        <p>
          Invite has been sent to {displayInvitedUsers(users)} to create an {RoleName} account.
        </p>
        <ButtonComponent appearance="blue" size="small" onClick={closeConfirmationModal}>
          Ok
        </ButtonComponent>
      </div>
    </Modal>
  );
};

export default styled(ConfirmUserInvite)`
  width: 650px !important;
  height: 500px !important;
  .ant-modal-content {
    height: inherit;
    width: inherit;
    position: sticky;
    padding: 27px 40px !important;
  }
  .ConfirmationContent {
    text-align: center;
    padding-top: 2rem;
    // margin: auto;
  }

  img {
    width: 150px;
  }
  h3 {
    font-family: var(--font-family);
    font-size: 18px;
    color: #2e384d;
    margin-top: 33px !important;
    margin-bottom: 1rem !important;
  }
  p {
    color: #828ea9;
    font-size: 14px;
    font-family: var(--font-family);
    text-align: center;
    margin-bottom: 1rem !important;
  }
  button {
    padding: 0 2rem;
  }
`;
