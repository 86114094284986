import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { gray, blue, white, green } from '../../design-system/colors';
import { LoadingOutlined } from '@ant-design/icons';

const generateSize = (size) => {
  return size === 'small'
    ? `height: 32px; padding: 4px 15px;`
    : size === 'large'
    ? `height: 50px; padding: 0 30px;`
    : `height: 41px; padding: 0 16px;`;
};

const generateBgColor = (appearance, disabled, loading) => {
  if (!disabled) {
    if (appearance === 'default') {
      return `background-color: var(--button-primary); background: var(--button-primary); color: var(--white);`;
    }
    if (appearance === 'danger') {
      return `background-color: var(--danger); background: var(--danger); color: var(--white);`;
    }
    if (appearance === 'blue') {
      return `background-color: var(--button-primary); background: var(--button-primary); color: var(--white);`;
    }
    // if (appearance === 'blue') {
    //   return `background-color: ${blue.primary}; color: ${white};`;
    // }
    if (appearance === 'gray') {
      return `background-color: ${gray.misc1}; color: ${white};`;
    }
    if (appearance === 'light') {
      return `background-color: ${gray.light}; color: #3b3737;`;
    }
    if (appearance === 'green') {
      return `background-color: ${green.button}; color: ${white};`;
    }
    if (appearance === 'white') {
      return `background-color: ${white}; color: ${blue.button};`;
    }
  } else if (disabled && !loading) {
    return `background-color: var(--disable-bg) !important; color: var(--disable-color); !important; text-shadow: none; cursor: not-allowed;`;
  } else if (loading) {
    if (appearance === 'default') {
      return `background-color: var(--button-primary); background: var(--button-primary); color: var(--white); opacity: 0.8; cursor: not-allowed;`;
    }
    if (appearance === 'danger') {
      return `background-color: var(--danger); background: var(--danger); color: var(--white); opacity: 0.8; cursor: not-allowed;`;
    }
    if (appearance === 'blue') {
      return `background-color: var(--button-primary); background: var(--button-primary); color: var(--white); opacity: 0.8; cursor: not-allowed;`;
    }
    return `background-color: var(--white); background: var(--white); color: var(--button-primary); opacity: 0.8; cursor: not-allowed;`;
  }
  return `background-color: var(--white); background: var(--white); color: var(--button-primary);`;
};

const generateBorder = (type, disabled, loading) => {
  if (type === 'secondary' && (!disabled || loading)) {
    return `border: 1px solid var(--button-primary) !important;`;
  }
  if (type === 'primary' && (!disabled || loading)) {
    return `border: none !important;`;
  }
  if (disabled) return `border: 1px solid var(--disable-color) !important;`;
  // if (disabled) return `border: var(--disable-border);`;
};

const generateBoxShadow = (type, disabled) => {
  if (type !== 'link' && !disabled) {
    // return `box-shadow: rgba(0, 0, 0, 0.1) -2px 7px 20px -4px;`;
    return `box-shadow: none;`;
  }
  if (disabled) return `box-shadow: rgba(0, 0, 0, 0.1) -2px 7px 20px -4px;`;

  return `box-shadow: none;`;
};

const StyledButton = styled.button`
  ${(props) => generateSize(props.size)};
  ${(props) => generateBgColor(props.appearance, props.disabled, props.loading)};
  ${(props) => generateBorder(props.type, props.disabled, props.loading)};
  ${(props) => generateBoxShadow(props.type, props.disabled, props.loading)};
  outline: none;
  border-radius: 2px;
  font-size: 14px;
  font-family: var(--font-family);
  // margin-bottom: ${(props) => props.marginBottom || 12}px;
  margin-bottom: 0px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  };
    ${(props) =>
      props.nomargin ? `margin-left: 0px !important; margin-right: 0px !important;` : ''};

  svg {
    margin-right: 8px !important;
  }
`;

const ButtonComponent = ({
  size,
  type,
  fluid,
  disabled,
  text,
  appearance,
  marginBottom,
  children,
  loading,
  ...rest
}) => (
  <StyledButton
    size={size}
    fluid={fluid}
    type={type}
    loading={loading}
    disabled={disabled}
    appearance={appearance}
    marginBottom={marginBottom}
    {...rest}
  >
    {loading && <LoadingOutlined />}
    {children}
  </StyledButton>
);

ButtonComponent.defaultProps = {
  size: 'small',
  type: 'primary',
  fluid: true,
  text: 'Button',
  appearance: '',
  marginBottom: '',
  disabled: false,
};

ButtonComponent.propTypes = {
  size: PropTypes.string,
  fluid: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.string,
  marginBottom: PropTypes.string,
  appearance: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ButtonComponent;
