import React, { useState, createContext } from 'react';
import { getUser } from '../helpers/api';
import { loadState } from './localStorage';
export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [users, setUsers] = useState([]);
  const [Category, setCategory] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [RoleName, setRoleName] = useState('');
  const [inviteUsers, setInvite] = useState(false);
  const [State, setState] = useState([]);
  const [Zones, setZones] = useState([]);
  const [LGA, setLGA] = useState([]);
  const [SelectedFacility, setSelectedFacility] = useState([]);
  const [userData, setUserData] = useState(null);

  const addUsers = (newUsers) => {
    setUsers([...newUsers]);
  };
  const updateUserRole = (role, roleName) => {
    setSelectedRole(role);
    setRoleName(roleName);
  };
  const allowInvitation = (invite) => {
    setInvite(invite);
  };
  const updateCategory = (value) => {
    setCategory(value);
  };
  const updateState = (value) => {
    setState([...value]);
  };
  const updateZone = (value) => {
    setZones([...value]);
  };
  const updateLGA = (value) => {
    setLGA([...value]);
  };
  const updateFacility = (value) => {
    setSelectedFacility([...value]);
  };

  const getUserData = () => {
    const id = loadState().auth.result.data.userId;
    getUser(id)
      .then((res) => {
        setUserData(res.result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <UserContext.Provider
      value={{
        users,
        selectedRole,
        addUsers,
        updateUserRole,
        inviteUsers,
        allowInvitation,
        updateCategory,
        Category,
        State,
        Zones,
        LGA,
        SelectedFacility,
        updateState,
        updateZone,
        updateLGA,
        updateFacility,
        RoleName,
        getUserData,
        userData,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
