import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  ArrowLeftOutlined,
  EditOutlined,
  CloseCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Row, Col, message, Popconfirm, Breadcrumb } from 'antd';
import { getZones, getStates, createState, deleteState } from '../../../../../helpers/api';
import { properCase } from '../../../../../helpers/utils';
import { openNotification } from '../../../../../helpers/notification';
import Table from '../../../../../components/common/Table';
import Input from '../../../../../components/common/AntInput';
import SearchInput from '../../../../../components/common/SearchInput';
import Select from '../../../../../components/common/Select';
import Button from '../../../../../components/common/Button';
import LoadingContent from '../../../../../components/common/LoadingContent';
import PageHeader from '../../../../../components/common/PageHeader';
import { Link } from 'react-router-dom';
import EditState from './EditState';
// import DeleteStateModal from './DeleteState';
import Layout from '../../../../../components/common/Layout';
import { convertDMS } from '../../../../../helpers/utils';
import './state.css';
import PageContent from '../../../../../components/common/PageContent';
import AddStateModal from './AddState';

const { Option } = Select;
const { Search } = Input;

const StateLookup = ({ className }) => {
  const [zonesList, setzonesList] = useState([]);
  const [stateList, setstateList] = useState(null);
  const [loading, setLoading] = useState(false);

  const [selectedZoneId, setselectedZoneId] = useState('');
  const [selectedStateId, setSelectedStateId] = useState('');

  const [visible, setVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const [stateName, setStateName] = useState('');
  const [stateCapital, setStateCapital] = useState('');
  const [coordinates, setCoordinates] = useState('');
  const [zoneName, setZoneName] = useState('');
  const [reload, setReload] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    refreshStates();
    refreshZones();
  }, []);
  useEffect(() => {
    if (reload) {
      refreshStates();
    }
  }, [reload]);

  const refreshStates = () => {
    setLoading(true);
    getStates()
      .then((res) => {
        setstateList(res.result.data);
        setLoading(false);
        setReload(false);
      })
      .catch((error) => {
        setLoading(false);
        setReload(false);
        return openNotification({
          type: 'error',
          title: 'Something went wrong!',
          message: error && typeof error === 'string' ? error : 'Error getting states.',
        });
      });
  };

  const refreshZones = () => {
    getZones()
      .then((res) => {
        setzonesList(res.result.data);
      })
      .catch((error) => {
        return openNotification({
          type: 'error',
          title: 'Something went wrong!',
          message:
            error && error.message && typeof error.message === 'string'
              ? error.message
              : 'Error getting list of zones.',
        });
      });
  };

  const getStateZone = (key) => {
    const filteredZone = zonesList.filter((zone) => zone.zoneName === key)[0];
    if (filteredZone) {
      setselectedZoneId(filteredZone.zoneId);
    }
  };

  const reloadState = () => {
    setReload(true);
  };

  const closeAddStateModal = () => {
    setVisible(false);
  };

  const openEditModal = () => {
    setEditModalVisible(true);
  };
  const closeEditModal = () => {
    setEditModalVisible(false);
  };

  const getSelectedStateData = (stateId) => {
    const stateData = stateList.filter((state) => state.stateId === stateId)[0];

    if (stateData) {
      getStateZone(stateData.zoneName);

      setStateName(stateData.stateName);
      setStateCapital(stateData.stateCapital);
      setCoordinates(stateData.coordinates);
      setZoneName(stateData.zoneName);
    }
  };

  // GET SELECTED STATE DATA TO EDIT
  const editState = (ev) => {
    getSelectedStateData(ev.target.id);
    setSelectedStateId(ev.target.id);
    openEditModal();
  };

  // DELETE SELECTED STATE
  const confirmDeleteState = (e, id) => {
    setLoading(true);
    const filteredState = stateList.filter((state) => state.stateId === e);

    if (filteredState) {
      if (filteredState[0].facilityCount > 0) {
        return openNotification({
          type: 'error',
          title: 'Error deleting state',
          message: 'Facilities exist in this state',
        });
      } else {
        handleDelete(e);
      }
    }
  };
  const handleDelete = (stateId) => {
    deleteState(stateId)
      .then((res) => {
        setLoading(false);
        setReload(true);
        return openNotification({
          type: 'success',
          title: 'State Deleted!',
          message: 'State Deleted Successfully!',
        });
      })
      .catch((error) => {
        setLoading(false);
        return openNotification({
          type: 'error',
          title: 'Error deleting state',
          message: error && error,
        });
      });
  };
  const columns = [
    {
      title: 'State',
      dataIndex: 'stateName',
    },

    {
      title: 'Capital',
      dataIndex: 'stateCapital',
    },
    {
      title: 'Zone',
      dataIndex: 'zoneName',
    },
    {
      title: 'Coordinates',
      dataIndex: 'coordinates',
      render: (records) => (
        <span>
          {records && records.latitude && records.longitude
            ? convertDMS(records.latitude, records.longitude)
            : ''}
        </span>
      ),
    },
    {
      title: '',
      key: 'stateId',
      dataIndex: 'stateId',
      render: (stateId, records) => (
        <>
          <Button className="transparent__bg" id={stateId} onClick={editState}>
            <EditOutlined /> Edit
          </Button>

          <Popconfirm
            title={`Are you sure you want to delete ${properCase(
              records.stateName
            )} from the records?`}
            onConfirm={(e) => confirmDeleteState(stateId)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" className="text-danger">
              <CloseCircleOutlined style={{ color: '#ec4c47' }} /> Delete
            </a>
          </Popconfirm>
        </>
      ),
    },
  ];

  const searchStates = (values) => {
    handleSearchStates(values);
  };

  const handleSearchStates = (input) => {
    setLoading(true);
    const max = 100000;
    const params = `State?Search=${input}`;
    getStates(params)
      .then((res) => {
        setSearchResults(res.result.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error === undefined) {
          return openNotification({
            type: 'error',
            title: 'Error getting state',
            message: `${input} state not found`,
          });
        } else {
          message.error(error);
          return openNotification({
            type: 'error',
            title: 'Error getting states',
            message: error,
          });
        }
      });
  };

  const keyedData = stateList;
  keyedData && keyedData.length && keyedData.forEach((dt) => (dt['key'] = dt.stateId));
  return (
    <Layout headerTitle="State Management">
      <div className="breadcrumb__container ml-2rem mt-1rem">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/settings">Settings</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/settings/lookups">Lookups</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>State Lookup</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {visible && (
        <AddStateModal open={visible} close={closeAddStateModal} reloadState={reloadState} />
      )}
      {editModalVisible && (
        <EditState
          open={editModalVisible}
          close={closeEditModal}
          state={stateName}
          capital={stateCapital}
          coordinates={coordinates}
          zone={zoneName}
          stateId={selectedStateId}
          zoneId={selectedZoneId}
          zoneList={zonesList}
          reloadState={reloadState}
        />
      )}

      <PageHeader className="no-padding-x">
        <Row
          // gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
          type="flex"
          align="start"
          justify="space-between"
          className="pl-2rem header-span100"
          style={{ width: '100%' }}
        >
          <Col span={20} style={{ flexFlow: 'column' }}>
            <Row className="mb-1rem">
              <Col span={16} style={{ flexFlow: 'column' }}>
                <h3 className="heading mb-20" style={{ alignSelf: 'flex-start' }}>
                  State Lookups
                </h3>
                <p style={{ alignSelf: 'flex-start' }}>
                  This lookup contains all the states that are currently registered in the system.
                </p>
                <p style={{ alignSelf: 'flex-start' }}>
                  You can edit, delete and create new states.
                </p>
              </Col>
            </Row>
            <Row
              type="flex"
              align="start"
              justify="space-between"
              // style={{ width: '100%' }}
            >
              <Col span={4} className="text-left">
                <Button
                  appearance="default"
                  type="primary"
                  style={{ marginLeft: '0px !important' }}
                  onClick={() => setVisible(true)}
                  nomargin
                >
                  <PlusOutlined />
                  New State
                </Button>
              </Col>
              <Col lg={6}>
                <SearchInput
                  className="search__states__input"
                  placeholder="Search states"
                  searchTable={searchStates}
                  style={{ marginBottom: '1rem' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Row type="flex" align="start" justify="space-between" style={{ width: '100%' }}>
          <Col lg={18}>
            <Row justify="space-between lookup__title">
              <Col span={12}>
                <h3 className="heading mb-10">State Lookups</h3>
                <p className="text-gray small-text">State lookups define states in zone areas</p>
              </Col>
              <Col span={5} className="text-right flex-column-end">
                <Button type="primary" appearance="default" onClick={() => setVisible(true)}>
                  <PlusOutlined />
                  Add State
                </Button>
              </Col>
            </Row>
          </Col>
        </Row> */}
      </PageHeader>

      <PageContent>
        <Row>
          <Col lg={{ span: 20 }}>
            <div className={className}>
              {loading && stateList === null ? (
                <LoadingContent pageLoading={true} />
              ) : (
                <Table
                  bordered={false}
                  paddedTable={true}
                  dataSource={searchResults !== null ? searchResults : keyedData}
                  columns={columns}
                  rowClassName="state__lookup__row"
                  pagination={true}
                />
              )}
            </div>
          </Col>
        </Row>
      </PageContent>
    </Layout>
  );
};

export default styled(StateLookup)`
  padding-left: 2rem;
  font-family: var(--font-family);
  color: #2e384d;
  .loader {
    padding: 3rem 0;
    text-align: center;
  }

  .edit__state__btn {
    color: #0c5bab !important;
    margin-right: 20px;
    background: #fff;
    border: none;
    font-size: 14px;
    i {
      margin-right: 5px;
    }
  }
  .delete__state__btn {
    background: #fff;
    border: none;
    color: #ec4c47 !important;
    font-size: 14px;
  }
  .ant-table-thead th {
    font-size: 14px;
  }
  .state-row {
    padding: 1.5rem;
    border-bottom: 0.5px solid #d8d8d8;
    .title {
      font-size: 18px;
      text-transform: capitalize;
    }
    .title.editable {
      background: rgba(10, 10, 120, 0.05);
      border: none;
      padding: 0 10px;
    }
    .text-right {
      text-align: right;
    }
    i {
      margin-right: 5px;
    }
    button {
      background: transparent;
      box-shadow: none;
      border: none;
      display: inline-block;
    }
  }
  .add-more {
    text-align: right;
    /* margin-top: 2rem; */
    button {
      background: transparent;
      color: #0c5bab;
      font-size: 16px;
      font-weight: 500;
      border: none;
    }
  }
  .add-more-container {
    /* margin-top: 2rem; */
    .ant-form-inline {
      align-items: center;
      display: flex;
      .ant-form-item {
        flex: 1;
        button {
        }
        .ant-input {
          background: rgba(10, 10, 120, 0.05);
          border: none;
          padding: 0 10px;
          height: 38px;
        }
        .ant-select-selector {
          height: 38px;
          background: rgba(10, 10, 120, 0.05);
          border: none;
          padding: 0 10px;
          font-family: var(--font-family);
        }
      }
      .ant-form-item.state-btn {
        text-align: right;
      }
    }
    .ant-form-item-explain {
      margin-top: 0;
    }

    button {
      border-radius: 3px;
    }
    .cancel-btn {
      color: #828ea9;
      border: none;
      padding: 0 10px;
      height: 38px;
      background: rgba(16, 112, 202, 0.08);
      margin-right: 0;
    }
    .save-btn {
      background: #0c5bab;
      color: #fff;
      border: none;
      padding: 0 14px;
      height: 38px;
      margin-right: 10px;
    }
  }
`;
