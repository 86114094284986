import React, { useEffect, useState, useRef } from 'react';
import { Table } from 'antd';
import styled from 'styled-components';

const _Table = styled(Table)`
  // .main-data-table {
  //   padding: 0 24px;
  // }
  &.main-data-table {
    padding: 0px 24px;
  }
  &.data-table {
    .ant-table-body,
    .ant-table-header {
      overflow: unset;
      /* overflow: unset !important; */
    }
  }
  .main-table-rows,
  .padded-table-rows,
  .table-rows {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: unset !important;
    // line-height: normal;
    letter-spacing: normal;
    color: var(--dark);
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .main-table-rows,
  .padded-table-rows {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .main-table-rows:nth-child(even) > td,
  .padded-table-rows:nth-child(even) > td,
  .table-rows:nth-child(even) > td {
    background-color: #f5f7ff;
    background: #f5f7ff;
  }

  th.ant-table-cell {
    background: #ffffff;
    // background: #fafafa;
    font-weight: 600;
    color: var(--dark);
    font-family: var(--font-family-bold);
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &.main-table-rows th.ant-table-cell,
  &.padded-table-rows th.ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .ant-table-tbody tr > td {
    font-family: var(--font-family) !important;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &.main-data-table .ant-table-tbody tr > td,
  &.padded-data-table .ant-table-tbody tr > td {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .ant-table-column-sorters {
    font-family: var(--font-family-bold);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark);
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &.main-data-table .ant-table-column-sorters,
  &.padded-data-table .ant-table-column-sorters {
    line-height: 1;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &.data-table .ant-table-thead th.ant-table-cell,
  &.data-table .ant-table-column-sorters {
    font-weight: 400;
    padding-top: 8px;
    padding-bottom: 8px;
    background: #e2f3f9;
  }
`;

let mainTableStyle = {
  // marginBottom: '1rem',
  // padding: '24px 30px',
  // marginTop: '-1.5rem',
};

let mainStyle;

export default (props) => {
  const {
    columns = [],
    dataSource = [],
    className = '',
    rowClassName = '',
    style = {},
    mainTable,
    paddedTable,
  } = props;

  // const [pageSize, setPageSize] =useState(10);
  const tableRef = useRef(null);
  // useEffect(() => {
  //   effect
  //   return () => {
  //     cleanup
  //   }
  // }, [input])

  const xs = window.matchMedia('(max-width: 1269px)');
  const ys = window.matchMedia('(max-height: 1269px)');

  let pageSize = 10;
  if (!xs || !ys) {
    pageSize = 15;
  }

  if (!mainTable) {
    mainTableStyle = {};
  }
  if (style && typeof style === 'object') {
    mainStyle = { ...style, ...mainTableStyle };
  } else mainStyle = mainTableStyle;

  const _rowClassName = `${rowClassName} ${
    mainTable ? 'main-table-rows' : paddedTable ? 'padded-table-rows' : 'table-rows'
  }`;
  const _className = `${className} ${
    mainTable ? 'main-data-table' : paddedTable ? 'padded-data-table' : 'data-table'
  }`;

  if (tableRef.current) {
    if (tableRef.current.parentElement.clientHeight) {
      const height =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      const refH = tableRef.current.parentElement.clientHeight;
      const firstCalc = 10 * (height / refH);
      // pageSize = firstCalc < 15 ? 10 : firstCalc > 16 ? 20 : 15;
      pageSize = firstCalc > 25 ? 20 : firstCalc > 20 ? 15 : 10;
    }
  }

  let pagiData = {
    showSizeChanger: mainTable || paddedTable ? true : false,
  };
  if (mainTable || paddedTable) {
    pagiData = {
      showSizeChanger: mainTable || paddedTable ? true : false,
      // pageSize,
    };
  }

  return (
    // eslint-disable-next-line
    <div ref={tableRef}>
      <_Table
        {...props}
        columns={columns}
        className={_className}
        dataSource={dataSource}
        pagination={pagiData}
        // pageSize={50}
        style={mainStyle}
        rowClassName={_rowClassName}
      />
    </div>
  );
};
