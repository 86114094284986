import React from 'react'
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import VerifyPayment from '../components/screens/VerifyPayments/VerifyPayment';

export default function PaymentVerifier(props) {
    return (
        <Layout headerTitle="Checkout">
            <PageContainer id="checkout">
                <VerifyPayment props={props}/>
            </PageContainer>
        </Layout>
    )
}