import React from 'react';
import { Row, Col, Checkbox, Switch } from 'antd';
import PageHeader from '../../../components/common/PageHeader';
import PageContent from '../../../components/common/PageContent';
import NotificationForm from './NotificationForm';
import './style.css';

const NotificationConfig = () => {
  return (
    <div className="notification__container mb-2rem">
      <Row>
        <Col lg={22} offset={2}>
          <PageHeader split={true} className="no-padding-x">
            <Row
              // gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
              type="flex"
              align="start"
              justify="space-between"
              className=""
              style={{ width: '100%' }}
            >
              <Col span={15} style={{ flexFlow: 'column' }}>
                <h3 className="heading" style={{ alignSelf: 'flex-start' }}>
                  Notification Settings
                </h3>
                <p className="text-gray sub-heading" style={{ alignSelf: 'flex-start' }}>
                  Enabling Notification will alert everyone when an important activity happens
                </p>
              </Col>
            </Row>
          </PageHeader>
          <PageContent className="transparent__bg">
            <Row>
              <Col lg={16}>
                <NotificationForm />
              </Col>
            </Row>
          </PageContent>
        </Col>
      </Row>
    </div>
  );
};

export default NotificationConfig;
