import React, { useState, useEffect, useContext } from 'react';
import { Col } from 'antd';
import Select from '../../common/Select';
import LoadingContent from '../../common/LoadingContent';
import styled from 'styled-components';
import { UserContext } from '../../../store/UserContext';
import { getFacilities } from '../../../helpers/api';
import { openNotification } from '../../../helpers/notification';
import './Users.css';

const { Option } = Select;

const Facilities = ({ className }) => {
  const [FacilityList, setFacilityList] = useState([]);
  const [Loading, setLoading] = useState(false);
  const { updateFacility, SelectedFacility } = useContext(UserContext);

  useEffect(() => {
    getFacilityList();
  }, []);

  const getFacilityList = () => {
    setLoading(true);
    getFacilities()
      .then((res) => {
        setFacilityList(res.result.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return openNotification({
          type: 'error',
          title: 'Something went wrong!',
          message: error && error.message ? error.message : 'Could Not fetch Facilities',
        });
      });
  };

  // const onSearch = (val) => {
  // console.log('search:', val);
  // };
  const handleChange = (value) => {
    updateFacility(value);
  };

  const displayFacilities = () => {
    if (FacilityList.length > 0) {
      return (
        <Select
          mode="multiple"
          placeholder="Search facility to assign to role"
          optionFilterProp="children"
          onChange={handleChange}
          className="mt-20 regulator__input"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {FacilityList.map((facility) => {
            return (
              <Option value={facility.facilityId} key={facility.facilityId}>
                {facility.facilityName}
              </Option>
            );
          })}
        </Select>
      );
    } else {
      return <h3>No Facilities</h3>;
    }
  };

  return (
    <div className={className}>
      <Col span={24}>{Loading ? <LoadingContent modal={true} /> : displayFacilities()}</Col>
    </div>
  );
};

export default styled(Facilities)`
  .ant-select-selector .ant-select-selection-item {
    background: #e7f5ff;
    color: #007ace;
    border: none;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
  .ant-select-selection__choice__content {
    color: #007ace;
    font-weight: 500;
    padding: 3px 13px;
  }
  .ant-select-selection__choice {
    background: #e7f5ff;
    border: none;
    border-radius: 15px;
  }
  .ant-select.facility-selector {
    .ant-select-selection-item {
      background: #e7f5ff !important;
      border-radius: 15px;
      height: 30px !important;
      border: none;
      display: flex;
      align-items: center;
    }
  }
  .facility-selector .ant-select-selection-item {
  }
  .ant-select-selection-item-content {
    color: #0c5bab;
    // padding: 3px 13px;
  }
`;
