import React, { useState } from 'react';
import { setFormatValue } from '../../helpers/utils';
import Input from '../../components/common/AntInput';

const LocationInput = ({
  value = {},
  onChange,
  type = 'latitude',
  placeholder,
  location,
  setLocation,
  className,
}) => {
  const [loc, setLoc] = useState(location);

  const triggerChange = (changedValue) => {
    if (onChange) {
      setLocation(changedValue['loc']);
      onChange({
        loc,
        ...value,
        ...changedValue,
      });
    }
  };

  const onLocChange = (e) => {
    let newLoc = e.target.value;

    newLoc = setFormatValue(newLoc);

    setLoc(newLoc);

    triggerChange({
      loc: newLoc,
    });
  };

  return (
    <Input
      value={value[type] || loc}
      onChange={onLocChange}
      placeholder={placeholder}
      className={className}
    />
  );
};

export default LocationInput;
