import { Col, Row, Select, Spin } from 'antd';
import StatCardsForEdm from './edm/StatCardsForEdm';
import ViolatorsEdmView from './edm/ViolatorsEdmView';
import PendingPaymentsEdmView from './edm/PendingPayentsEdmView';
import ComparativeAnalyticsEdmView from './edm/ComparativeAnalyticsEdmView';
import { useContext, useEffect, useState } from 'react';
import { getStates } from '../../../helpers/api';
import { LoadingOutlined } from '@ant-design/icons';
import PageContent from '../../common/PageContent';
import { AppContext } from '../../../store/AppContext';

export default function EdmDashboard() {
  const { state } = useContext(AppContext);
  const loggedInFirstName = state?.auth?.result?.data?.firstname
  const [stateData, setStateData] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [selectedStateId, setSelectedStateId] = useState('');

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = () => {
    setLoadingState(true);
    getStates()
      .then((response) => {
        setLoadingState(false);
        const states = response.result.data;
        const m = states?.map((item) => ({
          label: item.stateName,
          value: item.stateId,
        }));

        setStateData(m);
      })
      .catch((error) => {
        setLoadingState(false);
        console.log(error);
      });
  };

  const renderStatesDropdown = () => {
    if (loadingState) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <Spin indicator={<LoadingOutlined spin />} size="small" /> Loading States
        </div>
      );
    }

    if (!loadingState && stateData?.length > 0) {
      return (
        <Select
          showSearch
          allowClear
          placeholder="All States"
          options={stateData}
          style={{
            width: 200,
          }}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          onChange={(stateId) => setSelectedStateId(stateId)}
        />
      );
    }
  };

  return (
    <PageContent>
      <div style={{ padding: '1rem 2rem' }}>
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{
              flexGrow: 1,
              fontWeight:"bolder",
              fontSize:"2em",
              color:"#5F6368"
            }}
          >
            Hi {loggedInFirstName}
          </div>
          <div>{renderStatesDropdown()}</div>
        </div>
        <br />
        <StatCardsForEdm stateId={selectedStateId} />
        <br />
        <div>
          <ComparativeAnalyticsEdmView stateId={selectedStateId}/>
        </div>
        <br />
        <div>
          <Row gutter={20}>
            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <ViolatorsEdmView />
            </Col>
            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <PendingPaymentsEdmView stateId={selectedStateId} />
            </Col>
          </Row>
        </div>
        <br />
      </div>
    </PageContent>
  );
}
