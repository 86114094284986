import React, { useState, useContext } from 'react';
import { Layout, Row, Col, PageHeader, Tabs, Button, Select, Statistic, Descriptions } from 'antd';
import { FacilityContext } from '../../context';
import FacilityMap from '../FacilityMap';
import { getRecordSnapshot, getBiggestSamplePoint, getCurrentSamplePoint } from '../../helpers';
import { properCase } from '../../../../../helpers/utils';

// import FacilityMap from '../FacilityMap';

const { Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;

export default function index() {
  const [curr, setCurr] = useState('1');
  const [currQtr, setCurrQtr] = useState('q1');
  const context = useContext(FacilityContext);

  console.log('context', context);

  const {
    reports,
    setLoading,
    results,
    facility,
    samplePoints,
    currentSamplePoint,
    currentParameters,
    currCompareResult,
    allParameters,
    compareParameters,
    avgCompareResult,
    allSamplePointRecordData,
  } = context;

  // const biggestSamplePoint = getBiggestSamplePoint(samplePoints);
  // let biggestSamplePointArray = [];
  // if (biggestSamplePoint && Object.keys(biggestSamplePoint))
  //   biggestSamplePointArray = biggestSamplePoint[Object.keys(biggestSamplePoint)[0]];

  let currSamplePoint = getCurrentSamplePoint(samplePoints, currQtr);

  console.log('currsma007acep', currSamplePoint);

  const renderContent = (key) => {
    setCurr(key);
  };

  const loadContent = () => {
    switch (curr) {
      case '1':
        return (
          <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }} type="flex" justify="start" align="middle">
            <Col span={24}>
              <FacilityMap
                facility={facility}
                samplePoints={currSamplePoint}
                qtr={currQtr}
                allSptData={allSamplePointRecordData}
              />
            </Col>
          </Row>
        );
        break;
      case '2':
        return <div>Details tab!</div>;
        break;
      default:
        return (
          <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }} type="flex" justify="start" align="middle">
            <Col span={24}>
              <FacilityMap
                facility={facility}
                samplePoints={currSamplePoint}
                qtr={currQtr}
                allSptData={allSamplePointRecordData}
              />
            </Col>
          </Row>
        );
        break;
    }
  };

  return (
    <Content style={{ margin: '0 16px 0', overflow: 'initial' }} key="1">
      <div
        className="site-layout-background facility-overview"
        style={{ padding: 24, textAlign: 'left' }}
        key="1"
      >
        <PageHeader
          className="site-page-header-responsive"
          // onBack={() => window.history.back()}
          title={
            <div
              style={{
                display: 'flex',
                width: 'max-content',
                justifyContent: 'flex-end',
              }}
            >
              <Statistic
                title="# of Samplepoints"
                value={`${currSamplePoint.length}`}
                style={{
                  marginRight: 32,
                }}
              />
              <Statistic
                title="Status"
                value={`${properCase(facility.status)}`}
                style={{
                  marginRight: 32,
                }}
              />
              <Statistic
                title="Record Snapshot"
                value={`${getRecordSnapshot(allSamplePointRecordData, currQtr)}`}
                style={{
                  marginRight: 32,
                }}
              />
              {/* <Statistic title="Price" prefix="$" value={568.08} /> */}
            </div>
          }
          // subTitle="This is a subtitle"
          extra={[
            <Select
              // style={{ width: '12.8em' }}
              defaultValue="q1"
              key="1"
              onChange={(value) => setCurrQtr(value)}
            >
              <Option value="q1">Quarter 1</Option>
              <Option value="q2">Quarter 2</Option>
              <Option value="q3">Quarter 3</Option>
              <Option value="q4">Quarter 4</Option>
            </Select>,
            // <Button key="2">Operation</Button>,
            // <Button key="1" type="primary">
            //   Primary
            // </Button>,
          ]}
          footer={
            <Tabs defaultActiveKey="1" onChange={renderContent}>
              <TabPane tab="Map" key="1" />
              <TabPane tab="Details" key="2" />
            </Tabs>
          }
        >
          {/* <Descriptions size="small" column={3}>
            <Descriptions.Item label="# Sample Points">
              {facility.samplePointCount}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              <a>{facility.status}</a>
            </Descriptions.Item>
            <Descriptions.Item label="Record Snapshot">
              {getRecordSnapshot(allSamplePointRecordData, 'all')}
            </Descriptions.Item>
          </Descriptions> */}
        </PageHeader>

        {loadContent()}
      </div>
    </Content>
  );
}
