import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import SettingsContent from '../components/screens/settings/SettingsContent';

export default (props) => {
  const manageSys = props.userPermission.includes('MANAGE_SYSTEM_CONFIGURATIONS');
  return (
    <Layout headerTitle="Settings">
      <PageContainer>
        <SettingsContent userCategory={props.userCategory} userPermission={props.userPermission} />
      </PageContainer>
    </Layout>
  );
};
