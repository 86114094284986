import React, { useState } from 'react';
import { Modal, Form, Row, Col, message } from 'antd';
import { getStates, createLga } from '../../../../../helpers/api';
import Input from '../../../../../components/common/AntInput';
import Button from '../../../../../components/common/Button';
import Select from '../../../../../components/common/Select';
import LocationInput from '../../../../../components/common/LocationInput';
import {
  properCase,
  optionalCheckLatitude as checkLatitude,
  optionalCheckLongitude as checkLongitude,
} from '../../../../../helpers/utils';

const Option = Select;

const AddLgaModal = ({ open, close, stateList, reloadLga }) => {
  const [loading, setLoading] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  // CREATE NEW LOCAL GOVERNMENT
  const handleAddLg = (values) => {
    setLoading(true);
    const payload = {
      lgaName: values.lgaName,
      stateId: values.stateName,
      coordinates: {
        latitude: values.latitude ? values.latitude.loc : 0,
        longitude: values.longitude ? values.longitude.loc : 0,
      },
    };
    createLga(payload)
      .then((res) => {
        message.success(`${values.lgaName} Local govt created successfully! `);
        setLoading(false);
        reloadLga();
        close();
      })
      .catch((error) => {
        console.log(error);
        if (error === undefined) {
          message.error('Local Govt already exists');
        } else {
          message.error(error);
        }

        setLoading(false);
      });
  };

  // LIST ARRAY OF STATES
  const displayStateOptions = () => {
    if (stateList.length > 0) {
      return stateList.map((states) => {
        return (
          <Option key={states.stateId} value={states.stateId}>
            {states.stateName}
          </Option>
        );
      });
    }
    return null;
  };

  return (
    <Modal
      className="create__lga__modal"
      visible={open}
      footer={false}
      onCancel={close}
      title="Create New Local Government"
    >
      <Form layout="vertical" onFinish={handleAddLg} className="lga__lookup__form">
        <Row style={{ marginBottom: '10px' }}>
          <Col span={24}>
            <Form.Item
              label="State Name"
              name="stateName"
              rules={[
                {
                  required: true,
                  message: 'Please select a state',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a state"
                className="add__more__select form-controlxx"
                optionFilterProp="children"
              >
                {displayStateOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ marginBottom: '10px' }}>
          <Col span={24}>
            <Form.Item
              name="lgaName"
              label="Local Govt Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter local govt name',
                },
              ]}
            >
              <Input placeholder="Enter local govt name here" className="form-controlx" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="lgaChord"
          label="LGA Co-ordinates "
          rules={[{ required: true, message: 'Please enter Local Govt lat & long' }]}
        >
          <Row style={{ marginBottom: '10px' }}>
            <Col span={12}>
              <Form.Item
                name="latitude"
                rules={[
                  {
                    validator: checkLatitude,
                    required: true,
                    message: 'Please enter Local Govt latitude',
                  },
                ]}
              >
                <LocationInput
                  type="latitude"
                  placeholder="Enter Latitude"
                  location={latitude}
                  setLocation={setLatitude}
                  className="form-controlx"
                />
              </Form.Item>
            </Col>
            <span
              style={{
                display: 'inline-block',
                width: '0px',
                lineHeight: '32px',
                textAlign: 'center',
              }}
            />
            <Col span={12} style={{ textAlign: 'right' }}>
              <Form.Item
                name="longitude"
                rules={[
                  {
                    validator: checkLongitude,
                    required: true,
                    message: 'Please enter Local Govt longitiude',
                  },
                ]}
                style={{ display: 'inline-block', width: 'calc(100% - 20px)' }}
              >
                <LocationInput
                  type="longitude"
                  placeholder="Enter Longitude"
                  location={longitude}
                  setLocation={setLongitude}
                  className="form-controlx"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Row>
          <Col span={24}>
            <Form.Item className="mt-20 lg-btn">
              <Button
                htmlType="submit"
                type="primary"
                appearance="default"
                className="mr-10"
                loading={loading}
                disable={loading}
              >
                Save
              </Button>
              <Button type="secondary" onClick={close} disable={loading}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddLgaModal;
