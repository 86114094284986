import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import Reports from '../components/screens/report';

export default (props) => {
  const headerTitl =
    props.userCategory && props.userCategory.trim().toLowerCase() === 'consultant'
      ? 'my reports'
      : 'report history';
  const canView =
    props.userPermission.includes('VIEW_REPORT') ||
    props.userPermission.includes('VIEW_COMPARATIVE_REPORT');
  return (
    <Layout headerTitle={headerTitl}>
      <PageContainer>
          <Reports props={props} id="reports" />
      </PageContainer>
    </Layout>
  );
};
