import React from 'react';
import { Route } from 'react-router-dom';
import SignInPage from './SignIn';
import SignUpPage from './Signup';
import ForgotPasswordPage from './ForgotPassword';
import ResetPassword from './ResetPassword';

export default [
  <Route exact key="signin" path="/signin" component={SignInPage} />,
  <Route exact key="forgot_password" path="/forgot_password" component={ForgotPasswordPage} />,
  <Route key="signup" path="/signup" component={SignUpPage} />,
  <Route exact key="reset" path="/reset-password" component={ResetPassword} />,
];
