import React, { useState, useEffect, useRef } from 'react';
import {
  arc,
  pie,
  select,
  selectAll,
  scaleOrdinal,
  interpolate,
  interpolateWarm,
  schemeSet3,
  quantize,
} from 'd3';
import { Tooltip, Radio } from 'antd';
import { SyncOutlined } from '@ant-design/icons/lib/icons';
import styled from 'styled-components';
import LoadingContent from '../../../../components/common/LoadingContent';
import { toFixed } from '../../../../helpers/utils';

const avgRadioOptions = [
  { label: 'NESREA', value: 'fmEnv', name: 'envs' },
  { label: 'World Bank', value: 'worldBank', name: 'envs' },
];
const initCompData = { qaqc: {}, report: {} };

const avgOptions = ['fmEnv', 'worldBank'];

const initAvgCompData = {};

avgOptions.map((avgOpt) => (initAvgCompData[avgOpt] = initCompData));

const LimitViolations = (props) => {
  const {
    loading: initialLoading,
    className,
    error,
    title,
    width,
    height,
    fetch,
    url,
    margin,
  } = props;
  const SVGCanvas = useRef(null);
  const [data, setData] = useState();
  const [localError, setLocalError] = useState(error);
  const [loading, setLoading] = useState(initialLoading);
  const [mounted, setMounted] = useState(false);
  const [avgEnv, setAvgEnv] = useState(avgOptions[0]);
  const [currUrl, setCurrUrl] = useState();

  let arcData, arcGenerator, pieGenerator;

  const names = {
    fmEnv: 'fmenvViolationPercentage',
    worldBank: 'worldBankViolationPercentage',
  };

  let { [avgEnv]: value } = names;

  const isEmpty = (data = data, env) => {
    if (data && data.length > 0) {
      if (data.some((dt) => dt[names[env]])) return false;
    }
    return true;
  };

  const render = (data = data) => {
    const svg = select(SVGCanvas.current);

    if (svg.node()) {
      console.log(svg)
      const bounds = svg.node().getBoundingClientRect();
      const w = bounds.width;
      const h = bounds.height;
      const innerW = w - margin.left - margin.right;
      const innerH = h - margin.top - margin.bottom;

      let canvasOffset = 25;

      const legendRectSize = 12;
      // const legendSpacing = 8;

      let legendSpacing = (8 * 10) / data.length;

      if (data.length < 3) legendSpacing = 10;

      const r = Math.min(innerH, innerW) / 2 - canvasOffset; // 100;
      let color = scaleOrdinal()
        .domain(data.map((d) => d.measurementName))
        .range(quantize((t) => interpolateWarm(t * 0.8 + 0.1), data.length).reverse());

      if (data && data.length < 3) color = scaleOrdinal(schemeSet3);

      selectAll('.limit-violations input').on('change', change);

      const timeout = setTimeout(function() {
        select(`input[value="${avgOptions[0]}"]`)
          .property('checked', true)
          .each(change);
      }, 2000);

      // this will create <path> elements for us using arc data
      arcGenerator = arc().innerRadius(0);
      // .outerRadius((d, i) => r - (50 - d.data[value]));

      // create the arc (start and end angle) with the value of each element in our data array
      pieGenerator = pie()
        .sort((a, b) => b[value] - a[value])
        .value((d) => d[value]);

      // create canvas for the chart
      let canvas = svg
        .append('g')
        .classed('g-box', true)
        .attr('transform', `translate(${innerW / 2 - canvasOffset * 2 - 10}, ${innerH / 2})`)
        .selectAll('path')
        .data(pieGenerator(data))
        .join('path')
        .attr('d', arcGenerator)
        .attr('fill', (d, i) => color(d.data.measurementName))
        .attr('class', 'slice');

      canvas.append('title').text((d) => `${d.data.measurementName}: ${d.data[value].toFixed(2)}%`);

      // Store the displayed angles in _current.
      // Then, interpolate from _current to the new angles.
      // During the transition, _current is updated in-place by d3.interpolate.
      function arcTween(a) {
        var i = interpolate(this._current, a);
        this._current = i(0);
        return function(t) {
          return arcGenerator(i(t));
        };
      }

      let canvasPath;

      const update = (value, canvas) => {
        canvasPath = canvas
          // .append('g') //create a group to hold each slice (we will have a <path> and a <text> element associated with each slice)
          // .data(arcData)
          // .join('path')
          // .attr('d', arcGenerator)
          .each(function(d) {
            this._current = d;
          }); // store the initial angles
      };

      update(value, canvas);

      function change() {
        var value = this.value;
        clearTimeout(timeout);
        pieGenerator.value(function(d) {
          return d[names[value]];
        })(data); // change the value function

        arcGenerator = arc()
          .innerRadius(0)
          .outerRadius((d, i) => r - (50 - d.data[names[value]]));
        canvas = canvas.data(pieGenerator(data)); // compute the new angles
        canvas
          .selectAll('title')
          .join('title')
          .text((d) => `${d.data.measurementName}: ${d.data[names[value]].toFixed(2)}%`);

        canvas
          .transition()
          .duration(750)
          .attrTween('d', arcTween); // redraw the arcs

        update(names[value], canvas);
        legend
          .selectAll('text')
          .join('text')
          .text((d) => `${d.measurementName}, ${toFixed(d[names[value]], 2)}%`);
        // updateLegend()
      }

      const legend = svg
        .append('g')
        .classed('g-legend', true)
        .attr(
          'transform',
          `translate(${innerW / 2 + r - canvasOffset}, ${innerH / 2 + canvasOffset})`
        )
        .selectAll('.legend')
        .data(data)
        .join('g')
        .attr('class', 'legend')
        // .attr('width', '5rem')
        // .attr('overflow', 'scroll')
        .attr('transform', function(d, i) {
          const h1 = legendRectSize + legendSpacing;
          const offset = (h1 * color.domain().length) / 2;
          const horz = -2 * legendRectSize;
          const vert = i * h1 - offset;
          return 'translate(' + horz + ',' + vert + ')';
        });

      legend
        .append('circle')
        .attr('cx', legendRectSize - 35)
        .attr('cy', legendRectSize - legendSpacing)
        .attr('r', 4)
        .style('fill', (d) => color(d.measurementName))
        .style('stroke', (d) => color(d.measurementName));

      legend
        .append('text')
        .attr('x', legendRectSize + legendSpacing)
        .attr('y', legendRectSize - legendSpacing)
        .text((d) => `${d.measurementName}, ${toFixed(d[value], 2)}%`)
        .attr('dy', '4')
        .attr('dx', `-35`)
        // .attr('dx', '-5')
        .style('text-anchor', 'start');
    }
  };

  const renderChart = (refresh) => {
    if (refresh) setLoading(true);
    if (!url && !refresh) setLoading(true);
    else if (!refresh && data && data.length > 0 && url === currUrl) {
      render(data);
    } else if (!localError)
      fetch(url)
        .then(({ result }) => {
          const res = result.data;
          setCurrUrl(url);
          if (res && res.length > 0) {
            setData(res);
            render(res);
          } else {
            setData([]);
          }
          if (loading) setLoading(false);
        })
        .catch((err) => {
          console.log('erro fetching', err);
          if (loading && mounted) setLoading(false);
          if (mounted) setLocalError(true);
        });
  };

  const refresh = () => {
    setLocalError(false);
    renderChart(true);
  };

  const changeAvgEnv = (e) => {
    setAvgEnv(e.target.value);
  };

  useEffect(() => {
    try {
      setMounted(true);
      if (mounted) renderChart();
      else {
        return;
      }
    } catch (err) {
      console.log('caught: ', err);
      setLocalError(true);
    }
    return () => {
      // setData('');
      const clearPlots = () => {
        const svg = select(SVGCanvas.current);
        if (svg.node()) {
          svg
            .selectAll('g')
            .data([], (d) => d)
            .exit()
            .remove()
            .transition()
            .duration(750);
        }
      };
      clearPlots();

      return setMounted(false);
    };
  }, [mounted, props.url, localError, loading]);

  return (
    <>
      <div className={`dashboard-content-box ${className}`}>
        <div className="box-item-title">
          <h3 className="subheading bold">{title}</h3>
        </div>
        <div className="box-item-canvas limit-violations">
          {loading && <LoadingContent modal={true} />}
          {!loading && !localError && data && data.length > 0 && (
            <>
              <Radio.Group
                options={avgRadioOptions}
                onChange={changeAvgEnv}
                defaultValue={avgEnv}
                style={{ float: 'right' }}
              />
              {isEmpty(data, avgEnv) ? (
                <>
                  <span>Nothing to show!</span>
                  <Tooltip title="Refresh">
                    <span className="ml-1rem pointer" onClick={refresh}>
                      <SyncOutlined />
                    </span>
                  </Tooltip>
                </>
              ) : (
                <svg
                  className="limit-violations-canvas"
                  ref={SVGCanvas}
                  style={{ width: '100%', height }}
                />
              )}
            </>
          )}
          {!loading && !localError && (!data || (data && data.length === 0)) && (
            <>
              <span>Nothing to show!</span>
              <Tooltip title="Refresh">
                <span className="ml-1rem pointer" onClick={refresh}>
                  <SyncOutlined />
                </span>
              </Tooltip>
            </>
          )}
          {!loading && localError && (
            <>
              <span>Oops, we encountered some problems!</span>
              <Tooltip title="Refresh">
                <span className="ml-1rem pointer" onClick={refresh}>
                  <SyncOutlined />
                </span>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const generateSize = ({ width = '400px', height = '350px' }) => {
  return `width: ${width};height: ${height};`;
  // return `height: ${height};`;
};

export default styled(LimitViolations)`
  // ${(props) => generateSize(props)};
  box-shadow: -1px 2px 8px -2px rgba(67, 90, 111, 0.2);
  // box-shadow: 0px 8px 13px 4px rgba(67, 90, 111, 0.2);
  // box-shadow: 3px 3px 13px -8px rgba(67, 90, 111, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;
  font-family: var(--font-family);
  border-radius: 5px;

  .box-item-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        .subheading {
      padding-bottom: 14px;
      padding-top: 14px;
      padding-left: 14px;
      padding-right: 5px;

      font-size: 18px;
      font-family: var(--font-family-bold);

      .ant-radio-group {
        .ant-radio-checked {
          .ant-radio-inner {
            border-color: #1c81ce;
            background-color: #1c81ce;
            ::after {
              background-color: white;
              width: 5.5px;
              left: 4px;
              top: 3.3px;
              border-radius: 50%;
            }
          }
        }
      }
    }

  }
  .box-item-canvas {
    padding-top: 14px;
    padding-left: 14px;
    padding-right: 5px;    
    margin-bottom: 14px;
    height: calc(100% - 30px);

    @media (max-width: 1200px) {
      {
        ${(props) =>
          props.error || props.loading
            ? `height: calc(100% - 30px);`
            : `height: ${props.height}`};      
      }
    }
    width: 100%;
    font-family: var(--font-family);

    svg {
      .g-box {
        transform: translate(9rem, 11rem);
      }
      .g-legend {
        transform: translate(22rem, 15rem);
      }
      .legend {
            font-size: 12px;
            font-family: var(--font-family);
        text {
          font-size: 12px;
        }
        rect {
          stroke-width: 2;
        }
      }
      @media (max-width: 2480px) {
        {
          .g-legend {
            transform: translate(75%, 15rem);
            text {
              font-size: 0.8em;
            }
          }
        }
      }
      @media (max-width: 1655px) {
        {
          .g-legend {
            transform: translate(65%, 15rem);
            text {
              font-size: 0.7em;
            }
          }
        }
      }
      @media (max-width: 1400px) {
        {
          .g-legend {
            transform: translate(70%, 15rem);
          }
        }
      }
      @media (max-width: 1240px) {
        {
          .g-legend {
            transform: translate(75%, 15rem);
          }
        }
      }
      @media (max-width: 1200px) {
        {
          .g-legend {
            transform: translate(70%, 15rem);
            text {
              font-size: 0.9em;
            }
          }
        }
      }      
    }
  }
  
`;
