import { useEffect, useState } from 'react';
import { generateFacilityReport, getFacilitiyV2 } from '../../../../helpers/api';
import { Divider, Dropdown, Empty, Menu, Table, Tag, Tooltip } from 'antd';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import { formatDate, properCase } from '../../../../helpers/utils';
import AppPagination from '../../../common/AppPagination';
import { MoreOutlined } from '@ant-design/icons';
import './index.css';
import { facilityReportingModals } from '../../../../helpers/appstate';
import FacilityQuarters from '../FacilityQuarters';

export default function FacilityListTable({
  userPermissions,
  userCategory,
  setRecord,
  setModal,
  setPage,
  facilityRecord,
  search,
}) {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [row, setRow] = useState('');
  const [loading, setLoading] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const pageSize = 8;

  const [facilityQuatersModal, setFacilityQuartersModal] = useState(false);

  useEffect(() => {
    fetchData();
  }, [search, pageNum]);

  const fetchData = () => {
    setLoading(true);
    getFacilitiyV2(search, '', '', '', '', '', '', pageNum, pageSize)
      .then((response) => {
        setLoading(false);
        const facilityData = response.result.data;
        setTotal(500);
        setData(facilityData);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleRenderDisplay = () => {
    if (loading) {
      return (
        <div style={{ padding: '1rem' }}>
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
        </div>
      );
    } else if (!loading && !data.length) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            //border:"1px solid gray",
            height: '60vh',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <Empty />
          </div>
        </div>
      );
    } else if (!loading && data.length) {
      return renderDisplay();
    }
  };

  const canCreateStandardReport = userPermissions.includes('record_report');
  const canCreateAdhocReport = userPermissions.includes('record_report');
  const canLockFacilityReport = userPermissions.includes('schedule_quarter');
  const canViewFacilityReport = userPermissions.includes('view_report');
  const canUpdateFacility = userPermissions.includes('update_facility');
  const canApproveFacility = userPermissions.includes('approve_facility');
  const canApprovePayment = userPermissions.includes('approve_payment');

  const getFacilityQuaterRecords = (facilityId) => {
    generateFacilityReport(facilityId)
      .then((res) => {
        const quarters = res.result.data;
        facilityRecord(quarters);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const facilityMenu = () => {
    return (
      <Menu className="facility-overflow__menu" style={{ width: '170px' }}>
        {canCreateStandardReport && (
          <Menu.Item
            onClick={() => {
              getFacilityQuaterRecords(row.facilityId);
              setRecord({ type: 'standard', data: row });
              return setModal('selectqtr');

              // setFacilityQuartersModal(true);
            }}
          >
            <span>Standard Report</span>
          </Menu.Item>
        )}

        {/* {canCreateAdhocReport && (
          <Menu.Item
            onClick={() => {
              const newRow = { ...row };
              newRow.adhocReports = [];
              getFacilityQuaterRecords(row.facilityId);
              setRecord({ type: 'adhoc', data: newRow });
              return setModal('adhoc');
            }}
          >
            <span>AdHoc Report</span>
          </Menu.Item>
        )} */}

        {(canCreateStandardReport || canCreateAdhocReport) && <Divider />}

        {canLockFacilityReport && (
          <Menu.Item
            onClick={() => {
              getFacilityQuaterRecords(row.facilityId);
              setRecord({ type: 'locking', data: row });
              setModal('locking');
            }}
          >
            <span>Reserve Quarter</span>
          </Menu.Item>
        )}

        {canViewFacilityReport && (
          <Menu.Item
            onClick={() => {
              setRecord({ type: 'viewingcert', data: row });
              setModal('viewingcert');
            }}
          >
            <span>EIA Certificate</span>
          </Menu.Item>
        )}

        {canApprovePayment && (
          <Menu.Item
            onClick={() => {
              setRecord({ type: 'viewinvoices', data: row });
              setModal('viewinvoices');
            }}
          >
            <span>Invoices & Payments</span>
          </Menu.Item>
        )}

        {canUpdateFacility && canApproveFacility && <Divider />}

        {canUpdateFacility && (
          <Menu.Item
            onClick={() => {
              setRecord({ type: 'edit', data: row });
              setPage('edit');
            }}
          >
            <span>Update Facility</span>
          </Menu.Item>
        )}

        {/* {canApproveFacility && (
          <Menu.Item
            onClick={() => {
              setRecord({ type: 'edit', data: row });
              setPage('edit');
            }}
          >
            <span>
              {row.status === 'PENDING' || row.status === 'INACTIVE'
                ? 'Activate Facility'
                : 'Deactivate Facility'}{' '}
            </span>
          </Menu.Item>
        )} */}
      </Menu>
    );
  };

  // const facilityMenu = () => {
  //   return (
  //     <Menu className="facility-overflow__menu" style={{ width: '170px' }}>
  //       {canCreateStandardReport && (
  //         <Menu.Item
  //           onClick={() => {
  //             onMenuSelect('STANDARD_REPORT', selectedData);
  //           }}
  //         >
  //           <span>Standard Report</span>
  //         </Menu.Item>
  //       )}

  //       {canCreateAdhocReport && (
  //         <Menu.Item
  //           onClick={() => {
  //             onMenuSelect('ADHOCK_REPORT', selectedData);
  //           }}
  //         >
  //           <span>AdHoc Report</span>
  //         </Menu.Item>
  //       )}

  //       {(canCreateStandardReport || canCreateAdhocReport) && <Divider />}

  //       {canLockFacilityReport && (
  //         <Menu.Item
  //           onClick={() => {
  //             onMenuSelect('RESERVE_QUARTER', selectedData);
  //           }}
  //         >
  //           <span>Reserve Quarter</span>
  //         </Menu.Item>
  //       )}

  //       {canViewFacilityReport && (
  //         <Menu.Item
  //           onClick={() => {
  //             onMenuSelect('EIA_CERT', selectedData);
  //           }}
  //         >
  //           <span>EIA Certificate</span>
  //         </Menu.Item>
  //       )}

  //       {canApprovePayment && (
  //         <Menu.Item
  //           onClick={() => {
  //             onMenuSelect('INVOICE', selectedData);
  //           }}
  //         >
  //           <span>Invoices</span>
  //         </Menu.Item>
  //       )}

  //       {canUpdateFacility && canApproveFacility && <Divider />}

  //       {canUpdateFacility && (
  //         <Menu.Item
  //           onClick={() => {
  //             onMenuSelect('UPDATE', selectedData);
  //           }}
  //         >
  //           <span>Update Facility</span>
  //         </Menu.Item>
  //       )}

  //       {/* {canApproveFacility && (
  //       <Menu.Item
  //         onClick={() => {
  //           setRecord({ type: 'edit', data: row });
  //           setPage('edit');
  //         }}
  //       >
  //         <span>
  //           {row.status === 'PENDING' || row.status === 'INACTIVE'
  //             ? 'Activate Facility'
  //             : 'Deactivate Facility'}{' '}
  //         </span>
  //       </Menu.Item>
  //     )} */}
  //     </Menu>
  //   );
  // };

  const renderDisplay = () => {
    const columns = [
      {
        title: 'Name',
        key: 'facilityName',
        dataIndex: 'facilityName',
        render: (_, record) => {
          const samplePointCount = record.samplePointCount;
          return (
            <div>
              <div>{record.facilityName}</div>
              <div style={{ fontSize: '0.7em', color: 'gray' }}>
                {`${samplePointCount} ${samplePointCount > 1 ? 'Sample Points' : 'Sample Point'}`}
              </div>
            </div>
          );
        },
      },
      {
        title: 'State',
        ellipsis: true,
        dataIndex: 'stateName',
        key: 'stateName',
        render: (stateName) => <div>{properCase(stateName)}</div>,
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        render: (address) => {
          return (
            <div
              style={{
                width: '250px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Tooltip title={address}>{address}</Tooltip>
            </div>
          );
        },
      },
      {
        title: 'Category',
        dataIndex: '',
        key: '',
        render: (_, record) => {
          let background = '';

          if (record.facilityCategoryName === 'MINI') background = '#0CB230';
          if (record.facilityCategoryName === 'SMALL') background = '#FAAB10';
          if (record.facilityCategoryName === 'MEDUIM') background = '#7CBDFF';
          if (record.facilityCategoryName === 'LARGE') background = '#FA1010';
          return (
            <Tag
              color={background}
              style={{
                width: '70px',
                textAlign: 'center',
                borderRadius: '100px',
              }}
            >
              {properCase(record.facilityCategoryName)}
            </Tag>
          );
        },
      },
      {
        title: 'Date Created',
        dataIndex: 'entryDate',
        key: 'entryDate',
        render: (entryDate) => (
          <span>{entryDate ? formatDate(new Date(entryDate), 'LLL do, yyyy') : '00:00'}</span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          let color = '';

          if (status === 'ACTIVE') color = 'success';
          if (status === 'INACTIVE') color = 'error';
          if (status === 'PENDING') color = 'warning';

          return (
            <Tag color={color} style={{ width: '70px', textAlign: 'center' }}>
              {properCase(status)}
            </Tag>
          );
        },
      },
      {
        title: '',
        key: 'dropdown-action',
        render: (_, record) => {
          return (
            <Dropdown
              overlay={facilityMenu}
              trigger={['click']}
              onClick={() => {
                setRow(record);
              }}
            >
              <MoreOutlined />
            </Dropdown>
          );
        },
      },
    ];

    return (
      <div>
        <Table columns={columns} dataSource={data} size="middle" pagination={false} />
      </div>
    );
  };

  return (
    <div
      style={{
        padding: '1rem',
      }}
    >
      <div>{handleRenderDisplay()}</div>
      <br />
      <AppPagination
        defaultCurrent={pageNum}
        total={total}
        onPageChange={(pageNumber) => {
          setPageNum(pageNumber);
        }}
      />

      {/* {facilityQuatersModal && (
        <FacilityQuarters
          open={facilityQuatersModal}
          onClose={() => setFacilityQuartersModal(false)}
          userCategory={userCategory}
          userPermissions={userPermissions}
          facilityData={row}
        />
      )} */}
    </div>
  );
}
