// import React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

export default styled(Select)`
  font-family: 'var(--font-family)';
  .ant-select {
    margin: unset;
  }
  .ant-select-item-option-content {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .ant-select-selector {
    font-size: 14px;
    border-radius: 5px;
    font-family: var(--font-family);
    color: var(--dark);
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 32px;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
`;
