import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import LookupContent from '../components/screens/settings/Lookup/index';
import LookupContextProvider from '../store/LookupContext';
// import BreadCrumb from '../components/common/BreadCrumb';

export default (props) => {
  return (
    <Layout headerTitle="Settings">
      <PageContainer>
        <LookupContextProvider>
          <LookupContent userCategory={props.userCategory} />
        </LookupContextProvider>
      </PageContainer>
    </Layout>
  );
};
