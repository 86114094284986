import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router';
import { AppContext } from './store/AppContext';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(AppContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        state.auth.signedIn ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  );
};

export default ProtectedRoute;
