import React from 'react';
import { Modal } from 'antd';
import EditLgaForm from './EditLgaForm';
import './lga.css';

const EditLga = ({ open, close, lgaName, stateId, lgId, stateName, stateList, reloadLga }) => {
  return (
    <Modal
      visible={open}
      className="edit__lga__modal"
      onCancel={close}
      title="Edit Local Government"
      footer={false}
    >
      <EditLgaForm
        name={lgaName}
        lgId={lgId}
        stateId={stateId}
        stateName={stateName}
        stateList={stateList}
        closeEditModal={close}
        reloadLGA={reloadLga}
      />
    </Modal>
  );
};

export default EditLga;
