import { Button, Card, Col, Form, Input, Modal, Row, Space, Upload, Button as AntButton } from 'antd'
import React, { useState } from 'react'
import '../index.css'
import { CreditCardOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { openNotification } from '../../../../helpers/notification';
import { initializePayment } from '../../../../helpers/api';
import LoadingContent from '../../../common/LoadingContent';
import CloseIcon from '../../../../components/common/CloseIcon';
import PaystackLogo from '../../../../assets/img/paystacklogo.png'
import SquadCoLogo from '../../../../assets/img/squacologo.png'
import OfflinePaymentImg from '../../../../assets/img/offlinepayment.jpeg'


export default function FacilityInvoicePaymentOptions({ invoiceRecord }) {
    const [selected, setSelected] = useState('')
    const [offlinePaymentModal, setOfflinePaymentModal] = useState(false)
    const [savingOfflinePayment, setSavingOfflinePayment] = useState(false)
    const [certBase64, setCertBase64] = useState('');
    console.log(certBase64)

    const [fileList, setFileList] = useState();


    const handleInitializePayment = (item) => {
        const param = {
            invoiceId: invoiceRecord.invoiceId,
            paymentMethod: item.method,
            paymentGateway: item.gateway
        }

        initializePayment(param).then(res => {
            window.location.replace(res.result.data.authourizedUrl);
            //console.log(res)
        }).catch(err => {
            //console.log(err)
            return openNotification({
                type: 'error',
                title: 'Action failed!',
                message: err.data.result.desc
            })
        })
    }


    const paymentOptions = [
        {
            id: '1',
            method: 'ONLINE',
            gateway: 'PAYSTACK',
            desc: 'Pay with Paystack',
            color: 'midnightblue',
            handleSelect: (gateway) => { setSelected(gateway) },
            bg: PaystackLogo
        },
        {
            id: '2',
            method: 'ONLINE',
            gateway: 'GTPAY',
            desc: 'Pay with GTPAY',
            color: 'chocolate',
            handleSelect: (gateway) => { setSelected(gateway) },
            bg: SquadCoLogo
        },
        {
            id: '3',
            method: 'OFFLINE',
            gateway: 'OFFLINE',
            desc: 'Pay Offline',
            color: '',
            handleSelect: (gateway) => { setSelected(gateway) },
            bg: OfflinePaymentImg
        },
    ]


    const renderOfflinePaymentModalHeader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', }} className="facility-report__header">
                <span style={{ alignSelf: 'start' }}>
                    <div className="report-title">
                        <div type="strong">Offline Payment </div>

                    </div>
                </span>
                <span style={{ alignSelf: 'end', textAlign: 'right' }}>
                    <CloseIcon
                        className="close-icon report-viewing"
                        onClick={() => (
                            setSelected(''),
                            setOfflinePaymentModal(!offlinePaymentModal),
                            setSavingOfflinePayment(!savingOfflinePayment)
                        )}
                        style={{
                            marginBottom: '1rem',
                            marginTop: '0.8rem',
                        }}
                    />
                </span>
            </div>
        )
    }


    const validateMessage = {
        required: 'Field is required'
    }


    const style = {
        background: '#0092ff',
        padding: '8px 0',
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        console.log(e.fileList)
        return e.fileList;
    };

    const uploadProps = {
        name: 'file',
        action: (file) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    setCertBase64(reader.result);
                    return resolve(reader.result);
                };
                reader.onerror = (error) => reject(error);
            }),
        // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        // headers: {
        //     authorization: 'authorization-text',
        // },
        // beforeUpload(file, fileList) {
        //     const isJpgOrPngorPdf =
        //         file.type === 'image/jpeg' ||
        //         file.type === 'image/png' ||
        //         file.type === 'application/pdf' ||
        //         file.type === 'image/x-eps';
        //     if (!isJpgOrPngorPdf) {
        //         message.error('You can only upload JPG/PNG/PDF file!');
        //     }
        //     const isLt2M = file.size / 1024 / 1024 < 2;
        //     if (!isLt2M) {
        //         message.error('File must smaller than 2MB!');
        //     }
        //     isJpgOrPngorPdf && isLt2M && fileList && setFileList(fileList.slice(-1));
        //     return isJpgOrPngorPdf && isLt2M;
        // },
        // onRemove: () =>
        //     new Promise((resolve) => {
        //         formRef.current.setFieldsValue({ eiaCert: null });
        //         setCertBase64(null);
        //         setFileList([]);

        //         return resolve(true);
        //     }),
        // onChange(info) {
        //     if (info.file.status !== 'uploading') {
        //         // console.log(info.file, info.fileList);
        //     }
        //     if (info.file.status === 'done') {
        //         // let fileList = [...info.fileList];
        //         // fileList = fileList.slice(-1);
        //         // setFileList(fileList);

        //         message.success(`${info.file.name ? info.file.name : ''} file uploaded successfully`);
        //         // setFileList(info.newFileList);
        //     } else if (info.file.status === 'error') {
        //         message.error(`${info.file.name ? info.file.name : ''} file upload failed.`);
        //     }
        // },
        // transformFile(file) {
        //     return new Promise((resolve, reject) => {
        //         const reader = new FileReader();
        //         reader.readAsDataURL(file);
        //         reader.onload = () => {
        //             setCertBase64(reader.result);
        //             return resolve(reader.result);
        //         };
        //         reader.onerror = (error) => reject(error);
        //     });
        // },
        // fileList,
        // // disabled: fileList && fileList.length > 0,
        // progress: {
        //     strokeColor: {
        //         '0%': '#108ee9',
        //         '100%': '#87d068',
        //     },
        //     strokeWidth: 3,
        //     format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
        // },
    }


    const renderOfflinePaymentModalBody = () => {
        return (
            <div>
                <Form
                    onFinish={handleOfflinePaymentSave}
                    validateMessages={validateMessage}
                >
                    <Row>
                        <Col span={12}>
                            <Row>
                                <Col span={24}>
                                    <div>
                                        <div>Enter paid amount</div>
                                        <Form.Item
                                            name="paidAmount"
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="Amount"
                                                type='number'
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div >
                                        <div>Bank Name</div>
                                        <Form.Item
                                            name="bankName"
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="Enter Bank Name"
                                                style={{ width: '100%', }}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div >
                                        <div>Account Name</div>
                                        <Form.Item
                                            name="accountName"
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="Enter Account Name"
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} style={{ padding: '1rem' }}>
                            <div>
                                <div>
                                    {certBase64 !== '' && <img src={certBase64} style={{ width: '100px' }} />}
                                </div>
                                <div>
                                    <Form.Item
                                        name="receipt"
                                        style={{ marginBottom: 0, marginTop: '1rem', display: 'block' }}
                                    >
                                        <Upload {...uploadProps}>
                                            <AntButton>
                                                <UploadOutlined /> Upload reciept
                                            </AntButton>
                                        </Upload>
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                        <Col span={24} className="gutter-row">
                            <div>
                                <Form.Item>
                                    <Button
                                        type='primary'
                                        appearance="default"
                                        htmlType="submit"
                                        disabled={savingOfflinePayment}
                                    >
                                        {!savingOfflinePayment ? "Save" : "Saving . . ."}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>

                </Form>
            </div>
        )
    }


    const handleOfflinePaymentSave = (values) => {
        setSavingOfflinePayment(true)
        console.log(values)
        
        const param = {
            invoiceId: invoiceRecord.invoiceId,
            paymentMethod: 'OFFLINE',
            paymentGateway: 'OFFLINE',
            paidAmount:values.paidAmount,
            bankName:values.bankName,
            accountName:values.accountName,
            receipt: certBase64
        }
    }

    return (
        <>
            <div className='thereport__body'>
                <Row
                    gutter={24}
                    style={{ marginTop: '1.5rem' }}
                >
                    {/* <img src={PaystackLogo}/> */}
                    {paymentOptions.map((item) => (
                        <Col
                            span={8}
                            key={item.id}
                            style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}
                        >
                            <div>
                                <Card
                                    hoverable={true}
                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', backgroundImage: `url(${item.bg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                                    onClick={() => {
                                        item.handleSelect(item.gateway)
                                        if (item.gateway === "OFFLINE") setOfflinePaymentModal(!offlinePaymentModal)
                                        else handleInitializePayment(item);
                                    }}
                                >
                                    {/* {selected === item.gateway ? (
                                        <LoadingContent pageLoading={true} />
                                    ) : (
                                        <Space>
                                            <CreditCardOutlined style={{ fontSize: '2.5rem', color: item.color }} />
                                        </Space>
                                    )} */}
                                    {/* <div style={{ color: item.color }}>{item.desc}</div> */}
                                </Card>
                                <div style={{ padding: '0.5rem' }}>
                                    {selected === item.gateway ? (
                                        <LoadingContent pageLoading={true} />
                                    ) : (
                                        <div style={{ color: item.color, fontWeight: 'bold' }}>{item.desc}</div>
                                    )}
                                </div>

                            </div>
                        </Col>
                    ))}
                </Row>
            </div>

            <Modal
                visible={offlinePaymentModal}
                title={renderOfflinePaymentModalHeader()}
                style={{ height: '400px', width: '500px', overflow: "hidden" }}
            >
                {renderOfflinePaymentModalBody()}
            </Modal>
        </>
    )
}