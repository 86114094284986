import React from 'react';
import CloseIcon from '../../../../components/common/CloseIcon';

const ModalHeader = ({ closeModal }) => {
  return (
    <div>
      <span>
        <CloseIcon onClick={closeModal} />
      </span>
      <h3 className="heading mb-10">User Profile</h3>
    </div>
  );
};

export default ModalHeader;
