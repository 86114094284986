// import React from 'react';
import { Dropdown } from 'antd';
import styled from 'styled-components';

export default styled(Dropdown)`
  font-family: var(--font-family) !important;
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title,
  span {
    font-family: var(--font-family) !important;
  }
  .ant-dropdown-menu-item {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
`;
