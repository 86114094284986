import React, { useState, useEffect } from 'react';
import { Row, Col, Popconfirm, Collapse, message } from 'antd';
import Table from '../../../../components/common/Table';
import LoadingContent from '../../../../components/common/LoadingContent';
import PageHeader from '../../../../components/common/PageHeader';
import PageContent from '../../../../components/common/PageContent';
import Button from '../../../../components/common/Button';
import { getMeasurements, removeMeasurementParameters } from '../../../../helpers/api';
import {
  CloseCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  CaretRightOutlined,
} from '@ant-design/icons';
import { openNotification } from '../../../../helpers/notification';
import { parseString3, parseString4 } from '../../../../helpers/utils';
import AddMeasurementModal from './AddMeasurement';
import AddParameterModal from './AddParameter';
import EditMeasurementModal from './EditMeasurement';

import '../style.css';
import EditParameter from './EditParameter';

const { Panel } = Collapse;

const MeasurmentConfig = () => {
  const [measurementList, setMeasurementList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [tabKey, setTabKey] = useState('');
  const [parameters, setParameters] = useState(null);
  const [openParameterModal, setOpenParameterModal] = useState(false);
  const [selectedMeasurementId, setSelectedMeasurementId] = useState('');
  const [openEditMeasurement, setOpenEditMeasurement] = useState(false);
  const [selectedMeasurementName, setSelectedMeasurementName] = useState('');
  const [editParameterModal, setEditParameterModal] = useState(false);
  const [selectedParameterDetail, setSelectedParameterDetail] = useState(null);

  useEffect(() => {
    refreshMeasurement();
  }, []);

  useEffect(() => {
    if (measurementList) {
      getMeasurementContent();
      setInitialTab();
    }
  }, [measurementList]);

  useEffect(() => {
    if (reload) {
      refreshMeasurement();
    }
  }, [reload]);

  const refreshMeasurement = () => {
    setLoading(true);
    getMeasurements()
      .then((res) => {
        setMeasurementList(res.result.data);
        setLoading(false);
        setReload(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        return openNotification({
          type: 'error',
          title: 'Something went wrong',
          message: error,
        });
      });
  };

  const closeAddModal = () => {
    setAddModal(false);
  };
  const setInitialTab = () => {
    if (measurementList !== null) {
      setTabKey(measurementList[0].measurementId);
    }
  };
  const closeParameterModal = () => {
    setOpenParameterModal(false);
  };
  const closeEditMeasurementModal = () => {
    setOpenEditMeasurement(false);
  };
  const closeEditParameterModal = () => {
    setEditParameterModal(false);
  };

  const handleRemoveParameter = (id) => {
    setLoading(true);
    getParameterData(id);
  };

  const getParameterData = (id) => {
    const filteredData = parameters.filter((data) => data.parameterId === id);
    if (filteredData && filteredData.length) {
      setSelectedMeasurementId(filteredData[0].measurementId);
      removeParameter(filteredData[0].measurementId, id);
    }
  };

  const removeParameter = (measurementId, parameterId) => {
    console.log('trying to delete');
    if (parameterId !== '' && measurementId !== '') {
      removeMeasurementParameters(measurementId, parameterId)
        .then((res) => {
          message.success('Parameter removed successfully');
          reloadData();
        })
        .catch((error) => {
          console.log(error);
          if (error === undefined) {
            message.error(
              'YOU CANNOT REMOVE THIS ITEM BECAUSE THERE ARE OTHER ITEMS THAT DEPEND ON IT'
            );
          } else {
            message.error(error);
          }

          setLoading(false);
        });
    }
  };

  // EDIT PARAMETER
  const editParameter = (id) => {
    getParameterDetail(id);
    setEditParameterModal(true);
  };
  const getParameterDetail = (parameterId) => {
    const filteredData = parameters.filter((params) => params.parameterId === parameterId);
    if (filteredData) {
      setSelectedParameterDetail(filteredData);
    }
  };

  const columns = [
    {
      title: 'Parameter',
      //   width: 100,
      dataIndex: 'unit',
      key: 'unit',
      render: (unit) => (
        <>
          <span>{parseString4(parseString3(unit))}</span>
        </>
      ),
    },

    {
      title: 'SI Unit',
      dataIndex: 'siUnit',
      key: 'siUnit',
      render: (siUnit) => (
        <>
          <span>{parseString4(parseString3(siUnit))}</span>
        </>
      ),
    },
    // {
    //   title: 'NESREA Limit',
    //   dataIndex: 'fmEnv',
    //   key: 'fmenv',
    // },
    {
      title: 'NESREA Limit',
      dataIndex: 'nesrea',
      key: 'nesrea',
      render: (_, data) => {
        let symbol;

        if (data.nesreaDataType === 'LESS_THAN') symbol = '<';
        else if (data.nesreaDataType === 'GREATER_THAN') symbol = '>';
        else symbol = '';

        return <div>{!data.nesrea ? '-' : `${symbol} ${data.nesrea}`}</div>;
      },
    },
    {
      title: 'World Bank Limit',
      key: 'world',
      dataIndex: 'world',
      render: (_, data) => {
        return <div>{!data.world ? '-' : data.world}</div>;
      },
    },
    {
      title: '',
      dataIndex: 'parameterId',
      key: 'parameterId',
      render: (parameterId) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            id={parameterId}
            className="transparent__bg ml-10"
            onClick={(e, id) => editParameter(parameterId)}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Are you sure remove this parameter?"
            onConfirm={(e) => handleRemoveParameter(parameterId)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">
              <DeleteOutlined className="text-danger" />
            </a>
          </Popconfirm>
        </div>
      ),
    },
  ];

  // GET KEY OF ACTIVE ACCORDION TAB
  const handleTabView = (key) => {
    setTabKey(key);
    getMeasurementContent(key);
  };

  // FILTER PARAMETERS FOR ACTIVE TAB
  const getMeasurementContent = (activeTabKey = tabKey) => {
    if (activeTabKey !== '') {
      const filteredParams = measurementList.filter(
        (params) => params.measurementId === activeTabKey
      );
      // console.log(filteredParams[0].parameters);
      if (filteredParams && filteredParams.length) {
        setParameters(filteredParams[0].parameters);
      }
    } else {
      if (measurementList !== null) {
        setParameters(measurementList[0].parameters);
      }
    }
  };

  // DISPLAY MEASUREMENT PARAMETERS
  const measurementContent = () => {
    const keyedData = parameters;
    keyedData && keyedData.length && keyedData.forEach((dt) => (dt['key'] = dt.parameterId));
    return (
      <Table
        columns={columns}
        dataSource={keyedData}
        className="measurement__table"
        paddedTable
        rowClassName="measurements__row"
      />
    );
  };

  const getMeasurementData = (id) => {
    const filteredData = measurementList.filter((params) => params.measurementId === id);
    if (filteredData) {
      setSelectedMeasurementName(filteredData[0].measurementName);
    }
  };

  const editMeasurements = (id) => {
    setOpenEditMeasurement(true);
    setSelectedMeasurementId(id);
    getMeasurementData(id);
  };

  // MEASUREMENT SETTINGS ACTIONS
  const genExtra = (id) => (
    <>
      <EditOutlined
        style={{ marginRight: '1rem', color: '#0c5BAB' }}
        onClick={(event) => {
          event.stopPropagation();
          editMeasurements(id);
        }}
      />
    </>
  );

  // ADD PARAMETERS TO MEASUREMENT TYPE
  const addParameter = (ev) => {
    console.log(ev.target.id, 'measure me');
    setSelectedMeasurementId(ev.target.id);
    setOpenParameterModal(true);
  };

  // DISPLAY MEASUREMENTS
  const displayMeasurements = () => {
    if (measurementList === null) {
      return <LoadingContent pageLoading={true} />;
    } else {
      return (
        <Collapse
          activeKey={tabKey}
          onChange={handleTabView}
          bordered={false}
          className="custom__collapse"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          accordion
        >
          {measurementList.map((measurements) => {
            const { measurementId, measurementName } = measurements;
            return (
              <Panel
                header={measurementName}
                key={measurementId}
                className="custom__collapse__panel"
                extra={genExtra(measurementId)}
              >
                {measurementContent()}
                <div className="">
                  <Button
                    type="primary"
                    appearance="default"
                    className="add__params__btn"
                    id={measurementId}
                    onClick={addParameter}
                  >
                    Add Parameter
                  </Button>
                </div>
              </Panel>
            );
          })}
        </Collapse>
      );
    }
  };
  const reloadData = () => {
    setReload(true);
  };

  return (
    <div className="measurement__container">
      {addModal && (
        <AddMeasurementModal
          open={addModal}
          close={closeAddModal}
          reloadMeasurements={reloadData}
        />
      )}
      {openParameterModal && (
        <AddParameterModal
          open={openParameterModal}
          close={closeParameterModal}
          reloadMeasurements={reloadData}
          id={selectedMeasurementId}
        />
      )}
      {openEditMeasurement && (
        <EditMeasurementModal
          open={openEditMeasurement}
          close={closeEditMeasurementModal}
          reloadMeasurements={reloadData}
          id={selectedMeasurementId}
          name={selectedMeasurementName}
        />
      )}
      {editParameterModal && (
        <EditParameter
          open={editParameterModal}
          close={closeEditParameterModal}
          detail={selectedParameterDetail}
          reloadMeasurements={reloadData}
        />
      )}
      <Row>
        <Col lg={22} offset={2}>
          <PageHeader split={true} className="no-padding-x">
            <Row
              // gutter={{ xs: 4, sm: 8, md: 16, lg: 22 }}
              type="flex"
              align="start"
              justify="space-between"
              className=""
              style={{ width: '100%' }}
            >
              <Col span={8} style={{ flexFlow: 'column' }}>
                <h3 className="heading" style={{ alignSelf: 'flex-start' }}>
                  Measurement Types
                </h3>
              </Col>
              <Col span={9} className="text-right">
                <Button
                  appearance="default"
                  type="primary"
                  className="add__button"
                  onClick={() => setAddModal(true)}
                >
                  <PlusOutlined />
                  New Measurement Type
                </Button>
              </Col>
            </Row>
          </PageHeader>
          <PageContent className="transparent__bg">
            <Row className="mt-20">
              <Col lg={20}>
                <Row>
                  {/* <Col lg={8}>
                    <h3 className="sub-heading bold">Measurement Name</h3>
                  </Col>
                  <Col lg={8}>
                    <h3 className="sub-heading bold">Values</h3>
                  </Col> */}
                </Row>
              </Col>
              <Col lg={20}>
                {loading ? <LoadingContent pageLoading={true} /> : displayMeasurements()}
              </Col>
            </Row>
          </PageContent>
        </Col>
      </Row>
    </div>
  );
};

export default MeasurmentConfig;
