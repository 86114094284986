import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import TemplateScreen from '../components/screens/settings/Templates';

export default (props) => {
  return (
    <Layout headerTitle={'Setting'}>
      <PageContainer>
        <TemplateScreen props={props} id="templates" />
      </PageContainer>
    </Layout>
  );
};
