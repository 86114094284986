import {
  CalendarOutlined,
  CalendarTwoTone,
  CloseOutlined,
  LoadingOutlined,
  LockFilled,
  LockOutlined,
  UnlockFilled,
  UnlockOutlined,
  UnlockTwoTone,
} from '@ant-design/icons';
import { Card, Col, Empty, Modal, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import './index.css';
import { generateFacilityReport } from '../../../../helpers/api';
import { openNotification } from '../../../../helpers/notification';
import { reportTypes } from '../../../../helpers/appstate';

import '../../../../shared.css';

export default function FacilityQuarters({
  open,
  onClose,
  userCategory,
  userPermissions,
  facilityData,
}) {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const isNotLocked = (report, qtr) => {
    if (report.length > 0) {
      const rpt = report.filter(
        (rp) =>
          rp &&
          rp.reportQuarter &&
          parseInt(rp.reportQuarter) === parseInt(qtr) &&
          rp.reportType !== 'QAQC'
      );
      if (rpt && rpt.length > 0) return true;
      return false;
    }
    return false;
  };

  //   const isNotPassedFirstQuarter = !isNotLocked(facilityData, 1);
  //   const isNotPassedSecondQuarter = !isNotLocked(facilityData, 2);
  //   const isNotPassedThirdQuarter = !isNotLocked(facilityData, 3);
  //   const isNotPassedFourthQuarter = !isNotLocked(facilityData, 4);
  //   const getStatusAsap = (q) => facilityData.filter((fR) => parseInt(fR.reportQuarter) === q)[0];
  //   const q1Status = getStatusAsap(1)['reportStatus'].toLowerCase();
  //   const q2Status = getStatusAsap(2)['reportStatus'].toLowerCase();
  //   const q3Status = getStatusAsap(3)['reportStatus'].toLowerCase();
  //   const q4Status = getStatusAsap(4)['reportStatus'].toLowerCase();

  console.log(facilityData);
  console.log(userPermissions);
  console.log(userCategory);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadingData(false);
    generateFacilityReport(facilityData.facilityId)
      .then((response) => {
        setLoadingData(false);
        const responseData = response?.result?.data;
        setData(responseData);
        console.log(responseData);
      })
      .catch((error) => {
        setLoadingData(false);
        return openNotification({
          type: 'error',
          title: 'Failed to quarters',
          message: `${err}`,
        });
      });
  };

  const handleDisplay = () => {
    if (loadingData) {
      return (
        <div>
          <Spin indicator={<LoadingOutlined spin />} size="small" /> Fetching data ...
        </div>
      );
    }

    if (!loadingData && data?.length < 1) {
      return (
        <div style={{ textAlign: 'center' }}>
          <br />
          <br />
          <Empty />
        </div>
      );
    }

    if (!loadingData && data?.length > 0) {
      return renderContent();
    }
  };

  const renderContent = () => {
    return (
      <div className="facility-report__body" style={{ width: '100%' }}>
        <Row gutter={10}>
          {data?.map((item) => {
            const isReserved = item.reportType === reportTypes.QAQC;

            return (
              <Col
                key={item?.reportid}
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 12 }}
                xs={{ span: 12 }}
              >
                <Card className={'facility-card__body'}>
                  <div style={{ display: 'flex', justifyContent: 'right' }}>
                    {isReserved ? <UnlockTwoTone /> : <LockFilled />}
                  </div>
                  <div> {isReserved ? <CalendarTwoTone /> : <CalendarOutlined />}</div>
                  <Title>{item?.reportTitle}</Title>
                  <div>{item?.remarks}</div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onCancel={onClose}
        title={
          <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }}>Title page</div>
            <div>
              <CloseOutlined onClick={onClose} />
            </div>
          </div>
        }
        // width="auto"
        // style={{border:"10px solid red"}}
        // bodyStyle={{border:"10px solid green", width:"100%"}}
        // height={100}
        // style={{ width: '80%' }} // Set custom width here
        //   footer={<p>Footer page</p>}
        // width={1000}
      >
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
        {/* {handleDisplay()} */}
      </Modal>
    </div>
  );
}

// const Card = styled.div`
//   border: 1px solid #c4c4c4;
//   padding: 1rem;
//   border-radius: 10px;
//   height: 100%;
// `;

const Title = styled.div`
    color: #5f6368,
    font-size: 1.5em,
    font-weight: bold,
  `;
