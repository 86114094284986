import React from 'react';
import './style.css';

const ErrorNotFound = ({ }) => {
    return (
      <div className="signup__notfound">
        <div className="content">
          <h1 className="heading">Whoops! Invalid Invite Link.</h1>
          <h3>Contact an IEMS administrator for an invitation</h3>
        </div>
      </div>
    );
};

export default ErrorNotFound;
