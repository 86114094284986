import { Pagination } from 'antd';

export default function AppPagination({ defaultCurrent, total, onPageChange }) {
  const handleChange = (pageNumber) => {
    onPageChange(pageNumber);
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'end',
        padding:"1rem 0"
      }}
    >
      <Pagination defaultCurrent={defaultCurrent} total={total} onChange={handleChange} />
      <br />
    </div>
  );
}
