import React from 'react';
import { Modal } from 'antd';
import Form from './AddParameterForm';

const AddParameter = ({ open, close, reloadMeasurements, id }) => {
  return (
    <Modal
      visible={open}
      onCancel={close}
      footer={false}
      title="Add Parameter to Measurement"
      className="add__parameter__modal"
    >
      <Form closeModal={close} reload={reloadMeasurements} measurementId={id} />
    </Modal>
  );
};

export default AddParameter;
