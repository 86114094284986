import React, { useState, useEffect, useRef } from 'react';
import { InputNumber, Checkbox, Form, Spin } from 'antd';
import styled from 'styled-components';
import Button from '../../common/Button';
import Input from '../../common/AntInput';
import Select from '../../common/Select';
import { Link } from 'react-router-dom';
import { signup, getUserEmail } from '../../../helpers/api';
import { openNotification } from '../../../helpers/notification';
import { withRouter } from 'react-router';
import history from '../../common/History';
import { Redirect } from 'react-router-dom';
import ConfirmSignupModal from './ConfirmSignupModal';
import './style.css';

const { Option } = Select;

const SignupForm = ({ history, className }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [inviteId, setInviteId] = useState('');
  const formRef = useRef();

  useEffect(() => {
    const location = window.location.hash.split('=')[1];
    setInviteId(location);
    getUserEmail(location)
      .then((res) => {
        const { code } = res.result.status;
        console.log(res);
        setUserEmail(res.result.data.email);
      })
      .catch((error) => {
        return openNotification({
          type: 'error',
          title: `Error getting invited user's email`,
          message: `${error}`,
        });
      });
  }, []);
  useEffect(() => {
    defaultValues();
  }, [userEmail]);

  const initialEmail = {
    email: userEmail,
  };

  const defaultValues = () => {
    formRef.current.setFieldsValue({ email: userEmail });
  };

  const prefixSelector = (
    <Form.Item name="prefix" className="phone-prefix" noStyle>
      <Select
        style={{
          width: 80,
        }}
        defaultValue="+ 234"
      >
        <Option value="234">+ 234</Option>
      </Select>
    </Form.Item>
  );

  const handleSubmit = (values) => {
    setLoading(true);
    console.log(values);
    const payload = {
      firstname: values.firstname,
      lastname: values.lastname,
      mobileno: `0${values.mobileno}`,
      emailAddress: userEmail,
      password: values.password,
      gender: values.gender,
      username: values.username,
    };
    signup(payload, inviteId)
      .then((res) => {
        setLoading(false);
        setVisible(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return openNotification({
          type: 'error',
          title: 'Error signing up',
          message: `${err}`,
        });
      });
  };

  const closeModal = () => {
    setVisible(false);
    history.push('/signin');
  };
  return (
    <div className={className}>
      <ConfirmSignupModal open={visible} close={closeModal} />
      <Form layout="vertical" ref={formRef} onFinish={handleSubmit}>
        <div className="form__header">
          <h1>Get Started On IEMS!</h1>
        </div>

        <Form.Item
          label="First Name"
          name="firstname"
          rules={[{ required: true, message: 'please enter first name' }]}
        >
          <Input type="text" placeholder="Enter first name here" className="signup__input" />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastname"
          rules={[{ required: true, message: 'please enter last name ' }]}
        >
          <Input type="text" placeholder="Enter last name here" className="signup__input" />
        </Form.Item>

        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'please enter username ' }]}
        >
          <Input type="text" placeholder="Enter username here" className="signup__input" />
        </Form.Item>

        <Form.Item
          label="Gender"
          name="gender"
          rules={[{ required: true, message: 'please select a gender  ' }]}
        >
          <Select className="signup__input">
            <Option value="m">Male</Option>
            <Option value="f">Female</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Phone Number"
          className="phonenumber__input"
          name="mobileno"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                if (!value || (value && regex.test(value))) {
                  return Promise.resolve();
                }
                return Promise.reject('The input is not a valid phone number!');
              },
            }),
          ]}
        >
          <Input
            addonBefore={prefixSelector}
            placeholder="803 999 7654"
            style={{
              width: '100%',
            }}
          />
        </Form.Item>

        <Form.Item name="email" label="Email Address">
          <Input type="email" className="signup__input" readOnly />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"

          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
                if (!value || ((value && regex.test(value)) || value.length < 6)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'Password should not be shorter than 6 characters and should include at least one number and one capital and small letter'
                );
              },
            }),
          ]}
        >
          <Input.Password type="password" className="signup__input" placeholder="Enter unique password" required />
        </Form.Item>

        <Form.Item
          label=""
          name="terms"
          valuePropName="checked"
          rules={[{ required: true, message: 'please agree to terms & conditions to proceed' }]}
        >
          <Checkbox required>Agree to terms & conditions</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button appearance="blue" appearance="default" size="large" disabled={loading}>
            {loading && <Spin />} &nbsp; Sign Up
          </Button>
        </Form.Item>
        <div className="form-group signup__footer">
          <p>
            Already have an account? &nbsp;
            <Link to="/signin">Sign in now</Link>
          </p>
        </div>
      </Form>
    </div>
  );
};

export default styled(withRouter(SignupForm))``;
