import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BreadCrumb = ({ className, previousPath, previousView, currentView, clickHandler }) => {
  let customClickhandler = () => {};
  if (clickHandler) customClickhandler = clickHandler;
  return (
    <ul className={className}>
      <li>
        <Link to={previousPath} onClick={customClickhandler}>
          {' '}
          {previousView}{' '}
        </Link>
        <span className="divider">/</span>
      </li>

      <li className="current__view">{currentView}</li>
      <span className="divider">/</span>
    </ul>
  );
};

export default styled(BreadCrumb)`
  list-style: none;
  display: flex;
  margin-top: 1.5rem;
  padding-inline-start: 0;
  font-family: var(--font-family);
  .divider {
    color: #161616;
  }
  li {
    color: #0f62fe;
    padding-right: 5px;
    font-size: 14px;
    letter-spacing: 0.16px;
  }
  .current__view {
    color: var(--gray);
  }
  a {
    color: #0f62fe;
    text-decoration: none;
  }
`;
