import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

import { Empty } from 'antd';
import LoaderSkeleton from './LoaderSkeleton';
import PageContent from './PageContent';

const heatdata = [
  { latitude: 6.5244, longitude: 3.3792 }, // Lagos
  { latitude: 6.57, longitude: 3.3421 }, // Ikeja
  { latitude: 6.5, longitude: 3.35 }, // Mainland
  { latitude: 6.55, longitude: 3.42 }, // Victoria Island
  { latitude: 6.48, longitude: 3.38 }, // Lekki
];

const mapContainerStyle = {
  height: '100vh',
  width: '100%',
};

const center = {
  lat: 6.5244, // Lagos
  lng: 3.3792,
};

export default function MapForLocation({ locationCordinate }) {
  const mapRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setData(heatdata);
    }, 1000);
  };

  const handleLoad = (map) => {
    mapRef.current = map;
    initializeMap();
  };

  const initializeMap = () => {
    if (mapRef.current && window.google && window.google.maps) {
      const heatmapData = data.map((item) => ({
        location: new window.google.maps.LatLng(item.latitude, item.longitude),
        weight: 20, // Increase the weight for more intensity
      }));

      const heatmap = new window.google.maps.visualization.HeatmapLayer({
        data: heatmapData,
        radius: 50, // Increase the radius to make heatmap points larger
        opacity: 0.7, // Adjust opacity for better visualization
      });

      heatmap.setMap(mapRef.current);
    } else {
      setError('Map reference or Google API is not available.');
    }
  };

  const handleContentDisplay = () => {
    if (loading) {
      return (
        <div style={{ padding: '1rem 0' }}>
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
        </div>
      );
    }

    if (!loading && data.length < 1) {
      return <Empty />;
    }

    return (
      <LoadScript
        googleMapsApiKey="AIzaSyB85KWUMOtGfz0F0qazy_13P0oRT3JRbVY"
        libraries={['visualization']} // Include visualization library for heatmap
        onLoad={() => console.log('Google Maps loaded')}
      >
        <GoogleMap
          mapContainerStyle={{
            height: '400px',
            width: '100%',
          }}
          center={locationCordinate || center} // Center map on given coordinates or default
          zoom={12}
          onLoad={handleLoad} // Load the map and initialize the heatmap
        />
      </LoadScript>
    );
  };

  return (
    <PageContent>
      <div style={{ padding: '1rem 2rem' }}>{handleContentDisplay()}</div>
    </PageContent>
  );
}
