import React, { useState, useEffect, useRef } from 'react';
import {
  select,
  bisector,
  mouse,
  format,
  line,
  curveCardinal,
  scaleOrdinal,
  schemeCategory10,
  selectAll,
  scaleLinear,
  scaleBand,
  axisLeft,
  axisBottom,
  dispatch,
  min,
  max,
  nest,
  sum,
  curveBasis,
} from 'd3';
import { Tooltip, Radio, Row, Col, Checkbox } from 'antd';
import { SyncOutlined } from '@ant-design/icons/lib/icons';
import styled from 'styled-components';
import LoadingContent from '../../../../components/common/LoadingContent';
import Filter from '../../../../components/common/Filter';
import { parseString3, parseString4 } from '../../../../helpers/utils';

const CheckboxGroup = Checkbox.Group;

const avgOptions = ['fmEnv', 'worldBank'];

const avgRadioOptions = [
  { label: 'NESREA', value: avgOptions[0], name: 'envs2', className: 'envinput' },
  { label: 'World Bank', value: avgOptions[1], name: 'envs2', className: 'envinput' },
];

const names = {
  fmEnv: 'fmEnvViolation',
  worldBank: 'worldBankViolation',
};

const messenger = dispatch('changeEnv', 'changeMeasurements', 'changeParams');

function getArrayIndexes(arrayOptions, array) {
  const aChecked = [];
  array && array.map((dfc) => aChecked.push(arrayOptions.indexOf(dfc)));
  return aChecked;
}

const LimitViolators = (props) => {
  const {
    loading: initialLoading,
    className,
    error,
    title,
    width,
    height,
    fetch,
    url,
    margin,
  } = props;
  const SVGCanvas = useRef(null);
  const [data, setData] = useState();
  const [localError, setLocalError] = useState(error);
  const [loading, setLoading] = useState(initialLoading);
  const [mounted, setMounted] = useState(false);
  const [measurementOptions, setMeasurementOptions] = useState([]);
  const [currUrl, setCurrUrl] = useState();

  const defaultMCheckedList = measurementOptions.slice(0, 3);
  let msrementsOptions = [];
  if (data) msrementsOptions = Object.keys(data);
  else msrementsOptions = [];

  const getAllMOptions = (data = {}) => {
    const msrementsOptions = Object.keys(data);

    const allMsrementsOptions = [];
    msrementsOptions.map((msre) =>
      allMsrementsOptions.push({
        mType: msre,
        options: Object.keys(data[msre]),
      })
    );
    return allMsrementsOptions;
  };

  const getCurrMoptions = (data, mType, allMData = false) => {
    let mCheckedOptionsFilter;
    if (data) {
      if (allMData) {
        mCheckedOptionsFilter = data.filter((m) => m.mType === mType);
      } else {
        mCheckedOptionsFilter = getAllMOptions(data).filter((m) => m.mType === mType);
      }
      if (mCheckedOptionsFilter && mCheckedOptionsFilter[0]) {
        return mCheckedOptionsFilter[0]['options'];
      }
    }
    return [];
  };

  const defaultMCheckedOption = msrementsOptions[0];

  let mCheckedOptions = getCurrMoptions(data, defaultMCheckedOption);

  const initialMeasurementsState = {
    checkedList: defaultMCheckedList,
    checkedListIndexes: getArrayIndexes(mCheckedOptions, defaultMCheckedList),
    currM: defaultMCheckedOption,
    avgEnv: names[avgOptions[0]],
    indeterminate:
      !!defaultMCheckedList.length && defaultMCheckedList.length < mCheckedOptions.length,
    checkAll: defaultMCheckedList.length === mCheckedOptions.length,
  };

  const [measurementsState, setMeasurementsState] = useState(initialMeasurementsState);
  const [avgEnv, setAvgEnv] = useState(avgOptions[0]);

  // let { [avgEnv]: value } = names;

  const plotLineChart = (xdata = data, svg, mesrementsState) => {
    const allMsrementsOptions = getAllMOptions(xdata);

    let selectedSectors = mesrementsState.checkedListIndexes;
    const mapXData = (mesrementsState = measurementsState, whichX = selectedSectors) => {
      const type = mesrementsState.avgEnv || names[avgOptions[0]];
      const data = xdata;
      const currM = mesrementsState.currM;
      const currDatum = data[currM];
      const currPicks = mesrementsState.checkedList;
      const returningObj = {};

      currPicks &&
        currPicks.map((mOpts) => {
          const theCurrOpt = currDatum[mOpts];
          Object.keys(theCurrOpt).map((thePick) => {
            if (!returningObj[thePick]) {
              const thePickValue = { [mOpts]: theCurrOpt[thePick][type] };
              returningObj[thePick] = thePickValue;
            } else {
              const theNewPickValue = {
                [mOpts]: theCurrOpt[thePick][type],
              };
              const theFormerPickValue = returningObj[thePick];
              returningObj[thePick] = { ...theFormerPickValue, ...theNewPickValue };
            }
          });
        });

      const allMeasurements = allMsrementsOptions.map((dt) => dt['mType']);
      const newStructy = [];
      Object.keys(returningObj).map((fRun) => {
        const otherVals = returningObj[fRun];
        // const themKeys = Object.keys(otherVals);
        let objExtract = { period: fRun };
        currPicks.forEach((keyy) => {
          // currpicks was formelly themKeys;
          objExtract[keyy] = otherVals[keyy] ? otherVals[keyy] : 0;
        });
        newStructy.push(objExtract);
      });
      return {
        data: returningObj,
        selectedData: newStructy, // use this for plotting
        selectedKeys: mesrementsState.checkedList,
        type,
        allMeasurements,
      };
    };

    let workingData = mapXData(mesrementsState);

    const bounds = svg.node().getBoundingClientRect();
    const w = bounds.width;
    const h = bounds.height;

    const innerWidth = w - margin.left - margin.right;
    const innerHeight = h - margin.top - margin.bottom;

    // const xScale = scaleOrdinal([0, innerWidth]);
    // const xScale = scaleBand().range([0, innerWidth]);
    const xScale = scaleBand()
      .rangeRound([0, innerWidth])
      .padding(1);

    const yScale = scaleLinear()
      // .range([innerHeight, 0])
      .rangeRound([innerHeight, margin.top]);

    const z = scaleOrdinal(schemeCategory10);
    // let c;

    let legend;

    createLineChart(workingData);

    function createLineChart(xworkingData) {
      const newData = xworkingData.selectedData;
      const selected = xworkingData.selectedKeys;
      // let keys = [...new Set(newData.map((nd) => nd['period']))];
      let keys = [...newData.map((nd) => nd['period'])];
      xScale.domain(keys);

      // custom invert function
      // xScale.invert = (function() {
      //   var domain = xScale.domain();
      //   var range = xScale.range();
      //   var scale = scaleQuantize()
      //     .domain(range)
      //     .range(domain);

      //   return function(x) {
      //     return scale(x);
      //   };
      // })();
      xScale.invert = (function() {
        var domain = xScale.domain();
        var paddingOuter = xScale(domain[0]);
        var eachBand = xScale.step();

        return function(xvalue) {
          const value = xvalue + paddingOuter;
          var index = Math.floor((value - paddingOuter) / eachBand);
          const theDomXtent = domain[Math.max(0, Math.min(index, domain.length - 1))];
          return theDomXtent;
        };
      })();

      // function scaleBandInvert(scale) {
      //   var domain = scale.domain();
      //   var paddingOuter = scale(domain[0]);
      //   var eachBand = scale.step();
      //   return function(value) {
      //     var index = Math.floor((value - paddingOuter) / eachBand);
      //     return domain[Math.max(0, Math.min(index, domain.length - 1))];
      //   };
      // }

      // xScale.invert = function(x){ return d3.scaleQuantize().domain(this.range()).range(this.domain())(x);}

      let c = scaleOrdinal()
        .domain(selected)
        .range(schemeCategory10);

      const lineD = line()
        // .curve(curveCardinal)
        .x((d) => xScale(d.period))
        .y((d) => yScale(d.count));

      if (!newData || (newData && newData.length < 1)) {
        svg
          .selectAll('.lineplots')
          .data([])
          .exit()
          .remove();
        svg
          .selectAll('.tick')
          .data([])
          .exit()
          .remove();
      } else {
        svg
          .append('g')
          .attr('class', 'x-axis')
          .attr('transform', `translate(${margin.right + 10}, ${innerHeight})`)
          .call(axisBottom(xScale));

        svg
          .append('g')
          .attr('class', 'y-axis')
          .attr('transform', 'translate(' + margin.left + ',0)');

        const focus = svg
          .append('g')
          .attr('class', 'focus')
          .style('display', 'none');

        focus
          .append('line')
          .attr('class', 'lineHover')
          .style('stroke', '#999')
          .attr('stroke-width', 1)
          .style('shape-rendering', 'crispEdges')
          .style('opacity', 0.5)
          .attr('y1', -innerHeight)
          .attr('y2', 0);

        focus
          .append('text')
          .attr('class', 'lineHoverDate')
          .attr('text-anchor', 'middle')
          .attr('font-size', 12);

        const overlay = svg
          .append('rect')
          .attr('class', 'overlay')
          .attr('x', margin.left)
          .attr('width', innerWidth - margin.right - margin.left)
          .attr('height', innerHeight);

        update(xworkingData, 0);

        function update(mdata, speed) {
          const xkeys = mdata.selectedKeys;
          let newWorkingData = mdata;

          let actualWorkingData = newWorkingData.selectedData;

          const parameters = xkeys.map(function(key) {
            return {
              id: key,
              values: actualWorkingData.map((d) => {
                return {
                  period: d.period,
                  count: +d[key],
                };
              }),
            };
          });

          // let keys = [...newData.map((nd) => nd['period'])];
          xScale.domain([...mdata.selectedData.map((nd) => nd['period'])]);

          yScale
            .domain([
              0,
              max(parameters, (d) => max(d.values, (c) => c.count)) + 1,
              // min(parameters, (d) => min(d.values, (c) => c.count)),
              // max(parameters, (d) => max(d.values, (c) => c.count)),
            ])
            .nice()
            .tickFormat(format('d'));

          const yAxisTicks = yScale.ticks().filter((tick) => Number.isInteger(tick));
          const yAxis = axisLeft(yScale)
            .tickValues(yAxisTicks)
            .tickFormat(format('d'));

          svg
            .selectAll('.y-axis')
            .transition()
            .duration(speed)
            .call(yAxis.tickSize(-innerWidth + margin.right + margin.left));

          const parameter = svg.selectAll('.parameters').data(parameters);

          parameter.exit().remove();

          parameter
            .enter()
            .insert('g', '.focus')
            .append('path')
            .attr('class', 'line parameters')
            .style('stroke', (d) => z(d.id))
            .merge(parameter)
            .transition()
            .duration(speed)
            .attr('d', (d) => lineD(d.values));

          tooltip(actualWorkingData, xkeys);
        }

        function tooltip(data, xxkeys) {
          const labels = focus.selectAll('.lineHoverText').data(xxkeys);

          labels
            .enter()
            .append('text')
            .attr('class', 'lineHoverText')
            .style('fill', (d) => z(d))
            .attr('text-anchor', 'start')
            .attr('font-size', 12)
            .attr('dy', (_, i) => 1 + i * 2 + 'em')
            .merge(labels);

          const circles = focus.selectAll('.hoverCircle').data(xxkeys);

          circles
            .enter()
            .append('circle')
            .attr('class', 'hoverCircle')
            .style('fill', (d) => z(d))
            .attr('r', 2.5)
            .merge(circles);

          svg
            .selectAll('.overlay')
            .on('mouseover', function() {
              focus.style('display', null);
            })
            .on('mouseout', function() {
              focus.style('display', 'none');
            })
            .on('mousemove', mousemove);

          function mousemove() {
            const bisectPeriod = bisector((d) => (d ? d.period : xScale.domain().slice(-1))).left;

            const x0 = xScale.invert(mouse(this)[0]),
              i = bisectPeriod(data, x0, 0),
              d1 = data[i],
              d = d1;

            focus
              .select('.lineHover')
              .attr(
                'transform',
                `translate(${
                  d ? xScale(d.period) : xScale(xScale.domain().slice(-1))
                }, ${innerHeight})`
              );

            focus
              .select('.lineHoverDate')
              .attr(
                'transform',
                `translate(${
                  d ? xScale(d.period) : xScale(xScale.domain().slice(-1))
                }, ${innerHeight + margin.bottom})`
              );
            // .text(d.period);

            focus
              .selectAll('.hoverCircle')
              .attr('cy', (e) => yScale(`${d ? d[e] : yScale.domain()[0]}`))
              .attr('cx', xScale(`${d ? d.period : xScale.domain().slice(-1)}`));

            focus
              .selectAll('.lineHoverText')
              .attr(
                'transform',
                `translate(${
                  d ? xScale(d.period) : xScale(xScale.domain().slice(-1))
                }, ${innerHeight / 2.5})`
              )
              .text((e) =>
                d && (d[e] || d[e] == 0) ? parseString4(parseString3(e)) + ' ' + d[e] : ''
              );

            xScale(`${d ? d.period : xScale.domain().slice(-1)}`) > innerWidth - innerWidth / 4
              ? focus
                  .selectAll('text.lineHoverText')
                  .attr('text-anchor', 'end')
                  .attr('dx', -10)
              : focus
                  .selectAll('text.lineHoverText')
                  .attr('text-anchor', 'start')
                  .attr('dx', 10);
          }
        }

        const selectbox = select('#selectbox').on('change', function() {
          let xxworkingData = mapXData(generateNewMState(this.value, xdata));
          update(xxworkingData, 750);
        });

        messenger.on('changeParams', changeParams);
        messenger.on('changeEnv', changeEnv);

        function changeParams(newMState) {
          let xxworkingData = mapXData(newMState);
          update(xxworkingData, 750);
        }

        function changeEnv(newMState) {
          let xxworkingData = mapXData(newMState, null, newMState.envType);
          update(xxworkingData, 750);
        }
      }

      // plotLegend(newData, c);

      function plotLegend(legendData, c) {
        // svg.append('g').selectAll('g').data([]).exit().remove().enter();
        if (!legendData || (legendData && legendData.length === 0)) {
          svg
            .selectAll('.legend')
            .data([])
            .exit()
            .remove();
        } else {
          const legendSpacing = 80;
          const legendRectSize = 5;

          // const svgGroup = svg.append('g').selectAll('area').data(series);

          legend = svg
            .append('g')
            // .classed('g-legend', true)
            .attr('transform', `translate(${innerWidth / 6}, ${h})`)
            .selectAll('.legend')
            .data(c.domain())
            .join('g')
            .attr('class', 'legend')
            .attr('transform', function(d, i) {
              const h1 = legendSpacing;
              const offset = (h1 * c.domain().length) / 2;
              const vert = -2;
              const horz = i * h1 + d.length + offset;
              return 'translate(' + horz + ',' + 0 + ')';
            });

          legend
            .append('rect')
            .attr('x', legendRectSize - 20)
            .attr('y', legendRectSize - 20)
            .attr('width', 10)
            .attr('height', 10)
            .style('fill', (d) => c(d))
            .style('stroke', (d) => c(d));

          legend
            .append('text')
            .attr('x', legendRectSize)
            .attr('y', legendRectSize - 10)
            .text((d) => d)
            .style('fill', (d) => c(d))
            // .attr('dy', '4')
            // .attr('dx', '-5')
            .style('text-anchor', 'start');
        }
      }
    }
  };

  const render = (data, checkListState = measurementsState) => {
    const svg = select(SVGCanvas.current);
    if (svg.node()) {
      plotLineChart(data, svg, checkListState);
    }
  };

  const renderChart = (refresh) => {
    if (refresh) setLoading(true);
    if (!url && !refresh) setLoading(true);
    else if (!refresh && data && url === currUrl)
      render(data, measurementsState, measurementOptions);
    else if (!localError)
      fetch(url)
        .then(({ result }) => {
          const res = result.data;
          setCurrUrl(url);
          if (res) {
            const reformat = {};
            res.map((rData) => {
              if (rData && rData.values && rData.values.length > 0) {
                rData.values.map((rDataL2) => {
                  let msreName = rDataL2.longName;
                  let theMeasHead = rDataL2.measurements;

                  theMeasHead &&
                    theMeasHead.length > 0 &&
                    theMeasHead.map((theMeas) => {
                      if (!reformat[msreName]) {
                        const themValues = {};
                        themValues[rData.quarterTrend] = {
                          fmEnvViolation: theMeas.fmEnvViolation ? theMeas.fmEnvViolation : 0,
                          worldBankViolation: theMeas.worldBankViolation
                            ? theMeas.worldBankViolation
                            : 0,
                        };
                        reformat[msreName] = {};
                        if (theMeas.unit) {
                          reformat[msreName][theMeas.unit] = themValues;
                        }
                      } else {
                        if (!reformat[msreName][theMeas.unit]) {
                          const themValues = {};
                          themValues[rData.quarterTrend] = {
                            fmEnvViolation: theMeas.fmEnvViolation,
                            worldBankViolation: theMeas.worldBankViolation,
                          };
                          reformat[msreName][theMeas.unit] = themValues;
                        } else if (!reformat[msreName][theMeas.unit][rData.quarterTrend]) {
                          const themValues = {
                            fmEnvViolation: theMeas.fmEnvViolation,
                            worldBankViolation: theMeas.worldBankViolation,
                          };
                          reformat[msreName][theMeas.unit][rData.quarterTrend] = themValues;
                        } else if (reformat[msreName][theMeas.unit][rData.quarterTrend]) {
                          const shortName = reformat[msreName][theMeas.unit][rData.quarterTrend];
                          const themValues = {
                            fmEnvViolation: theMeas.fmEnvViolation + shortName.fmEnvViolation,
                            worldBankViolation:
                              theMeas.worldBankViolation + shortName.worldBankViolation,
                          };
                          reformat[msreName][theMeas.unit][rData.quarterTrend] = themValues;
                        }
                      }
                    });
                });
              }
            });

            const msrementsOptions = Object.keys(reformat);
            const allMsrementsOptions = [];
            msrementsOptions.map((msre) =>
              allMsrementsOptions.push({
                mType: msre,
                options: Object.keys(reformat[msre]),
              })
            );

            const defaultMCheckedOption = msrementsOptions[0];
            const mCheckedOptions = allMsrementsOptions.filter(
              (m) => m.mType === defaultMCheckedOption
            )[0]['options'];

            const defaultMCheckedList = mCheckedOptions.slice(0, 3);

            const initialMeasurementsState = {
              checkedList: defaultMCheckedList,
              checkedListIndexes: getArrayIndexes(mCheckedOptions, defaultMCheckedList),
              currM: defaultMCheckedOption,
              avgEnv: names[avgOptions[0]],
              indeterminate:
                !!defaultMCheckedList.length && defaultMCheckedList.length < mCheckedOptions.length,
              checkAll: defaultMCheckedList.length === mCheckedOptions.length,
            };

            setMeasurementsState(initialMeasurementsState);
            setMeasurementOptions(msrementsOptions);
            setData(reformat);
            render(reformat, initialMeasurementsState);
          } else setData(null);
          setLoading(false);
        })
        .catch((err) => {
          console.log('error fetching', err);
          if (loading && mounted) setLoading(false);
          if (mounted) setLocalError(true);
        });
  };

  const refresh = () => {
    setLocalError(false);
    renderChart(true);
  };

  const changeAvgEnv = (e) => {
    setAvgEnv(e.target.value);

    const formerMState = measurementsState;
    formerMState['avgEnv'] = names[e.target.value];
    messenger.call('changeEnv', this, formerMState);
  };

  useEffect(() => {
    try {
      // if (!mounted && !data) setLoading(true);
      if (!mounted) setMounted(true);
      if (mounted) renderChart();
      else {
        return;
      }
    } catch (err) {
      console.log('caught: ', err);
      setLocalError(true);
    }
    return () => {
      // setData('');
      const clearPlots = () => {
        const svg = select(SVGCanvas.current);
        if (svg.node()) {
          svg
            .selectAll('g')
            .data([], (d) => d)
            .exit()
            .remove()
            .transition()
            .duration(750);
        }
      };
      clearPlots();
      return setMounted(false);
    };
  }, [mounted, url, localError, loading]);

  function generateNewMState(selectedVal, xdata = data) {
    if (measurementOptions.includes(selectedVal)) {
      let mCheckedOptions = getAllParamOptions(xdata, selectedVal);
      const checkedList = mCheckedOptions.slice(0, 3);

      return {
        checkedList: checkedList,
        checkedListIndexes: getArrayIndexes(mCheckedOptions, checkedList),
        currM: selectedVal,
        avgEnv: names[avgEnv] || names[avgOptions[0]],
        indeterminate: !!checkedList.length && checkedList.length < mCheckedOptions.length,
        checkAll: checkedList.length === mCheckedOptions.length,
      };
    }
    return measurementsState;
  }

  const selectMType = (e) => {
    const selectedVal = e.target.value;
    if (measurementOptions.includes(selectedVal)) {
      setMeasurementsState(generateNewMState(selectedVal));
      // messenger.call('changeMeasurements', this, selectedVal);
    }
  };

  const onCheckAllChange = (e) => {
    const formerMState = measurementsState;
    const formerCurrM = formerMState.currM;
    let mCheckedOptions = getAllParamOptions(data, formerCurrM);
    // let mCheckedOptions = getCurrMoptions(data, formerCurrM);

    const currentCheck = e.target.checked ? mCheckedOptions : [mCheckedOptions[0]];

    const newMState = {
      checkedList: currentCheck,
      checkedListIndexes: getArrayIndexes(mCheckedOptions, currentCheck),
      currM: formerCurrM,
      avgEnv: names[avgEnv] || names[avgOptions[0]],
      indeterminate: false,
      checkAll: e.target.checked,
    };
    setMeasurementsState(newMState);
    // messenger.call('changeParams', this, getArrayIndexes(mCheckedOptions, currentCheck));
    messenger.call('changeParams', this, newMState);
  };

  const onCheckChange = (checkedList) => {
    if (checkedList && checkedList.length > 0) {
      const formerMState = measurementsState;
      const formerCurrM = formerMState.currM;
      // let mCheckedOptions = getCurrMoptions(data, formerCurrM);
      let mCheckedOptions = getAllParamOptions(data, formerCurrM);

      const newMState = {
        checkedList,
        indeterminate: !!checkedList.length && checkedList.length < mCheckedOptions.length,
        currM: formerCurrM,
        avgEnv: names[avgEnv] || names[avgOptions[0]],
        checkedListIndexes: getArrayIndexes(mCheckedOptions, checkedList),
        checkAll: checkedList.length === mCheckedOptions.length,
      };
      setMeasurementsState(newMState);
      messenger.call('changeParams', this, newMState);
    }
  };

  const filterContent = (
    <>
      <div className="divide-under">
        <Checkbox
          indeterminate={measurementsState.indeterminate}
          onChange={onCheckAllChange}
          checked={measurementsState.checkAll}
        >
          Check all
        </Checkbox>
      </div>
      <CheckboxGroup
        // options={getAllParamOptions(data, measurementsState.currM, true)}
        value={measurementsState.checkedList}
        onChange={onCheckChange}
      >
        <Row style={{ display: 'flex', flexWrap: 'no-wrap', overflowX: 'hidden' }}>
          {getAllParamOptions(data, measurementsState.currM, true).map((chb, i) => (
            <Col span={24} style={{ overflowX: 'hidden' }} key={i}>
              <Checkbox value={chb.value}>{chb.name}</Checkbox>
            </Col>
          ))}
        </Row>
      </CheckboxGroup>
    </>
  );

  function getAllParamOptions(xdata = data, mType = measurementsState.currM, withLabels = false) {
    try {
      if (xdata) {
        if (withLabels) {
          let arrR = [];
          Object.keys(xdata[mType]).forEach((xd) =>
            arrR.push({ name: parseString4(parseString3(xd)), value: xd })
          );
          return arrR;
        }
        return Object.keys(xdata[mType]);
      }
      return [];
    } catch (error) {
      setLocalError(true);
    }
  }

  return (
    <>
      <div className={`dashboard-content-box ${className}`}>
        <div className="box-item-title">
          <h3 className="subheading bold">
            {title}

            {data && (
              <div
                className="violation-trend-controls"
                style={{ float: 'right', marginRight: '20px' }}
              >
                {/* <p style={{ fontSize: '12px' }}>Change Measurement Type:</p> */}
                <select id="selectbox" onChange={selectMType}>
                  {measurementOptions.map((m) => (
                    <option key={m} value={m}>
                      {m}
                    </option>
                  ))}
                </select>

                <Filter
                  enableClear={false}
                  content={filterContent}
                  isFiltering={false}
                  enableHeader={false}
                  customClassName="custom filter-pollution-violations"
                  custom={true}
                />
              </div>
            )}
          </h3>
        </div>
        <div className="box-item-canvas violations-trend">
          {loading && <LoadingContent modal={true} />}
          {!loading && !localError && data && (
            <>
              <Radio.Group
                options={avgRadioOptions}
                onChange={changeAvgEnv}
                defaultValue={avgEnv}
                style={{ float: 'right' }}
              />
              <svg
                className="violations-trend-canvas"
                ref={SVGCanvas}
                style={{ width: '100%', height }}
              />
            </>
          )}
          {!loading && !localError && !data && (
            <>
              <span>Nothing to show!</span>
              <Tooltip title="Refresh">
                <span className="ml-1rem pointer" onClick={refresh}>
                  <SyncOutlined />
                </span>
              </Tooltip>
            </>
          )}
          {!loading && localError && (
            <>
              <span>Oops, we encountered some problems!</span>
              <Tooltip title="Refresh">
                <span className="ml-1rem pointer" onClick={refresh}>
                  <SyncOutlined />
                </span>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const generateSize = ({ width = '400px', height = '250px' }) => {
  return `width: ${width};height: ${height};`;
  // return `height: ${height};`;
};

export default styled(LimitViolators)`
  // ${(props) => generateSize(props)};
  box-shadow: -1px 2px 8px -2px rgba(67, 90, 111, 0.2);
  // box-shadow: 0px 8px 13px 4px rgba(67, 90, 111, 0.2);
  // box-shadow: 3px 3px 13px -8px rgba(67, 90, 111, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;
  font-family: var(--font-family);
  border-radius: 5px;

  .box-item-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    .filter-popover {
      .ant-dropdown-link {
        font-family: var(--font-family);
        font-weight: normal;
        font-size: 14px
      }
    }

    .subheading {
      padding-bottom: 14px;
      padding-top: 14px;
      padding-left: 14px;
      padding-right: 5px;

      font-size: 18px;
      font-family: var(--font-family-bold);

      .ant-radio-group {
        .ant-radio-checked {
          .ant-radio-inner {
            border-color: #1c81ce;
            background-color: #1c81ce;
            ::after {
              background-color: white;
              width: 5.5px;
              left: 4px;
              top: 3.3px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .box-item-canvas {
    padding-top: 14px;
    padding-left: 14px;
    padding-right: 5px;    
    margin-bottom: 14px;
    height: calc(100% - 30px);

    @media (max-width: 1200px) {
      {
        ${(props) =>
          props.error || props.loading
            ? `height: calc(100% - 30px);`
            : `height: ${props.height}`};      
      }
    }
    width: 100%;
    font-family: var(--font-family);

    svg {
      .g-box {
        transform: translate(9rem, 11rem);
      }
      .g-legend {
        transform: translate(22rem, 15rem);
      }
      .legend {
            font-size: 12px;
            font-family: var(--font-family);
        text {
          font-size: 12px;
        }
        rect {
          stroke-width: 2;
        }
      }
      @media (max-width: 2480px) {
        {
          .g-legend {
            transform: translate(75%, 15rem);
          }
        }
      }
      @media (max-width: 1655px) {
        {
          .g-legend {
            transform: translate(65%, 15rem);
          }
        }
      }
      @media (max-width: 1400px) {
        {
          .g-legend {
            transform: translate(70%, 15rem);
          }
        }
      }
      @media (max-width: 1240px) {
        {
          .g-legend {
            transform: translate(75%, 15rem);
          }
        }
      }
      @media (max-width: 1200px) {
        {
          .g-legend {
            transform: translate(70%, 15rem);
          }
        }
      }      
    }
  }
  
`;
