import React from 'react';
import styled from 'styled-components';

const PageContent = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(PageContent)`
  background: #fff;
  .icon {
    width: 240px;
    height: 240px;
    opacity: 0.2;
    @media screen and (max-width: 769px) {
      width: 200px;
      height: 200px;
    }
  }
`;
