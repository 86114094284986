import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu, Dropdown, Popover, Checkbox } from 'antd';
import { FilterOutlined, FilterFilled, DownOutlined } from '@ant-design/icons';
import { gray } from '../../design-system/colors';

const Filter = styled(Dropdown)`
  font-family: 'var(--font-family)';
  color: ${gray.misc3} !important;

  .anticon {
    margin-left: 8.4px;
    color: #42526e;
  }
`;

export default ({
  list,
  content,
  isFiltering,
  clearFilter,
  custom = false,
  customClassName = '',
  customIcon,
  enableHeader = true,
  enableClear = true,
}) => {
  const [visible, setVisible] = useState(isFiltering);
  const customClear = clearFilter ? clearFilter : console.log;
  if (!customIcon)
    customIcon = (
      <DownOutlined
        style={{
          fontSize: '10px',
          paddingLeft: '5px',
          color: '#66788a',
          verticalAlign: 'middle',
        }}
      />
    );
  let menu = (
    <>
      {enableClear && (
        <div className="clear-filter" onClick={customClear}>
          clear filter
        </div>
      )}
      {content}
    </>
  );
  if (!content)
    menu = (
      <Menu>
        {list &&
          list.map(({ text, value }, index) => {
            return (
              <Menu.Item key={`${text}${index}`} onClick={() => setVisible(true)}>
                <Checkbox value={value}>{text}</Checkbox>
              </Menu.Item>
            );
          })}
      </Menu>
    );
  if (content)
    return (
      <div className="filter-popover">
        <Popover
          placement="bottomRight"
          title={enableHeader ? <span>Filter</span> : false}
          content={menu}
          trigger="click"
          overlayClassName={`filter-popover ${custom ? customClassName : ''}`}
        >
          <a
            className={`ant-dropdown-link ${custom ? '' : 'subheading'}`}
            href="/#"
            onClick={(e) => e.preventDefault()}
          >
            {isFiltering ? (
              <>
                Filtering ...
                {custom ? customIcon : <FilterFilled className="filter-icon filter-active" />}
              </>
            ) : (
              <>
                Filter
                {custom ? customIcon : <FilterOutlined className="filter-icon" />}
              </>
            )}
          </a>
        </Popover>
      </div>
    );

  return (
    <Filter
      overlay={menu}
      placement="bottomRight"
      trigger={['click']}
      visible={visible}
      className="filter-dropdown"
      onVisibleChange={(flag) => setVisible(flag)}
    >
      {/* eslint-disable-next-line */}
      <a className="ant-dropdown-link subheading" href="#">
        {isFiltering ? (
          <>
            Filtering ...
            <FilterFilled className="filter-icon filter-active" />
          </>
        ) : (
          <>
            Filter
            <FilterOutlined className="filter-icon" />
          </>
        )}
      </a>
    </Filter>
  );
};
