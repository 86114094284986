import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import Schedules from '../components/screens/schedules';

export default (props) => {
  return (
    <Layout headerTitle="scheduler">
      <PageContainer id="schedules">
        <Schedules props={props} />
      </PageContainer>
    </Layout>
  );
};
