import { useEffect, useState } from 'react';
import { getFacilityAnalyticsSamplePoints } from '../../../helpers/api';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  IgrGrid,
  IgrGridToolbar,
  IgrGridToolbarActions,
  IgrGridToolbarAdvancedFiltering,
  IgrGridToolbarHiding,
  IgrGridToolbarPinning,
  IgrGridToolbarExporter,
  IgrColumn,
  IgrDataGridModule,
  IgrColumnGroup,
} from 'igniteui-react-grids';
import { IgrExcelModule } from 'igniteui-react-excel';

import {
  ComponentRenderer,
  IgrDownloadingMultiScaleImageEventArgs,
  WebGridDescriptionModule,
} from 'igniteui-react-core';
import {
  IgrButtonModule,
  IgrDialogModule,
  IgrDropdownItemModule,
  IgrDropdownModule,
  IgrInputModule,
  IgrRippleModule,
  IgrSelectModule,
  IgrTextareaModule,
} from 'igniteui-react';

//import 'igniteui-react-grids/grids/combined.js';
import 'igniteui-react-grids/grids/themes/light/indigo.css';

IgrDataGridModule.register();
IgrExcelModule.register();
IgrButtonModule.register();
IgrButtonModule.register();
IgrDialogModule.register();
IgrDropdownItemModule.register();
IgrDropdownModule.register();
IgrInputModule.register();
IgrRippleModule.register();
IgrSelectModule.register();
IgrTextareaModule.register();

export default function FacilityAnalytics() {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  console.log(data);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadingData(true);
    getFacilityAnalyticsSamplePoints('5018190e-6d9e-4fcf-8373-056d9b3de2ea')
      .then((response) => {
        setLoadingData(false);
        const responseData = response?.result?.data;
        const m = responseData?.map((item) => {
          return {
            samplePointName: item.samplePointName,
            measurementName: item.measurementName,
            latitude: item.coordinates.latitude,
            longitude: item.coordinates.longitude,
          };
        });
        setData(m);
      })
      .catch((error) => {
        //console.log(error.response);
      });
  };

  const handleDisplay = () => {
    if (loadingData) {
      return (
        <div style={{ display: 'flex', gap: 4 }}>
          <Spin indicator={<LoadingOutlined spin />} size="small" /> Fetching data
        </div>
      );
    }

    if (!loadingData && data?.length > 0) return renderContent();
  };

  const renderContent = () => {
    return (
      <div className="container sample ig-typography">
        <div className="container fill">
          <IgrGrid autoGenerate="false" data={data} style={{ background: 'white' }}>
            <IgrGridToolbar>
              <IgrGridToolbarActions>
                <IgrGridToolbarAdvancedFiltering />
                <IgrGridToolbarHiding />
                <IgrGridToolbarPinning />
                <IgrGridToolbarExporter />
              </IgrGridToolbarActions>
            </IgrGridToolbar>
            <IgrColumn field="samplePointName" header="Name" width="200px" />
            <IgrColumn field="measurementName" header="Type" width="200px" />
            <IgrColumnGroup header="Coordinates" collapsible="true">
              <IgrColumn field="latitude" header="Easting" />
              <IgrColumn field="longitude" header="Northing" />
            </IgrColumnGroup>
          </IgrGrid>
        </div>
      </div>
    );
  };
  return <div>{handleDisplay()}</div>;
}
