import React from 'react';
import { Modal } from 'antd';
import Checkmark from '../../../../assets/img/checkmark.svg';
import './ConfirmModal.css';

const ConfirmationModal = ({ open, close, message }) => {
  return (
    <Modal
      title={false}
      footer={false}
      visible={open}
      onCancel={close}
      className="confirmation__modal"
    >
      <div className="modal__content">
        <img src={Checkmark} alt="" />
        <p>{message}</p>
        <button onClick={close}>Ok</button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
