import React, { useState, useEffect } from 'react';
import { Table, Popover } from 'antd';
// import { mdiArrowUpBold as CaretUpOutlined, mdiArrowDownBold as CaretDownOutlined } from '@mdi/js';
// import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import {
  TiArrowDownThick as CaretDownOutlined,
  TiArrowUpThick as CaretUpOutlined,
} from 'react-icons/ti';
import { parseString } from '../../helpers/utils';
import './index.css';
import '../../scrollfix.css';
import { properCase } from '../../helpers/utils';

// let extraPadRatio = 1;

// const calcWidth = (which, allCount = 10) => {
//   const share = 6;
//   let ratio = 0.4;
//   const extraPad = (0.07 * (extraPadRatio - 1)) / (parseInt(which.length) / 1.5);
//   if (which === 'delete') ratio = 0.2;
//   else if (which === 'ind') ratio = 0.27;
//   else if (which === 'avg') ratio = 0.55;
//   else if (which === 'time') ratio = 0.87;
//   else {
//     switch (which.length) {
//       case 1:
//         ratio = 0.32 + extraPad;
//         break;
//       case 2:
//         ratio = 0.42 + extraPad;
//         break;
//       case 3:
//         ratio = 0.45 + extraPad;
//         break;
//       case 4:
//         ratio = 0.5 + extraPad;
//         break;
//       case 5:
//         ratio = 0.55 + extraPad;
//         break;
//       case 7:
//         ratio = 0.6 + extraPad;
//         break;
//       case 8:
//         ratio = 0.65 + extraPad;
//         break;
//       default:
//         ratio = 0.68 + extraPad;
//         break;
//     }
//   }
//   const valu = `${parseFloat((share * ratio) / allCount.toFixed(2))}%`;
//   return valu;
// };

const TrendDown = () => (
  <CaretDownOutlined style={{ color: '#ac000d', fontSize: '9px', display: 'inline' }} />
);
const TrendUp = () => (
  <CaretUpOutlined style={{ color: '#ac000d', fontSize: '9px', display: 'inline' }} />
);

const contentComparePopOver = (avgVal, actualVal, siUnit) => (
  <>
    {actualVal > avgVal && (
      <>
        <TrendUp /> {avgVal} <p className="inline">{siUnit}</p>
      </>
    )}
    {actualVal < avgVal && (
      <>
        <TrendUp /> {avgVal} <p className="inline">{siUnit}</p>
      </>
    )}
    {actualVal === avgVal && (
      <>
        {avgVal} <p className="inline">{siUnit}</p>
      </>
    )}
  </>
);

const ReportCompareTable = ({ data, parameters, type, loading, avg, avgEnv }) => {
  const [isLoading, setIsLoading] = useState(true);

  const pLen = parameters.length;

  useEffect(() => {
    const setLoadingTimer = setTimeout(() => setIsLoading(false), 1500);
    return () => {
      clearTimeout(setLoadingTimer);
    };
  }, [isLoading]);

  function whichAvg(unit) {
    return avg.qaqcAvg[unit] || avg.conAvg[unit];
  }

  const columns = [
    {
      title: type === 'facilityView' ? 'Qtr' : 'Qtr',
      // width: `${calcWidth('ind', pLen)}`,
      width: type === 'facilityView' ? '4.8rem' : '4.5rem',
      key: 'ind',
      dataIndex: type === 'facilityView' ? 'reportQuarter' : 'ind',
      render: (ind, dt) => (
        <span
          style={{
            display: 'inline-block',
            textAlign: 'center',
          }}
        >
          {type === 'facilityView'
            ? dt['reportType'] === 'QAQC'
              ? 'QAQC'
              : `${ind.toUpperCase()}`
            : `#${ind}`}
        </span>
      ),
    },
  ];

  let paramtrs = [];

  if (parameters)
    parameters.map((pmta) => {
      columns.push({
        title: parseString(pmta.unit),
        dataIndex: pmta.unit,
        key: pmta.unit,
        // width: `${calcWidth(parseString3(pmta.unit), pLen)}`,
        render: (rType, record) =>
          type !== 'facilityView' ||
          (record.reportStatus &&
            String(record.reportStatus)
              .trim()
              .toLowerCase() === 'published') ? (
            <span
              className={`trended-compare ${
                type === 'facilityView' ? (record.reportType === 'QAQC' ? 'qaqc-report' : '') : ''
              }`}
            >
              {rType && rType > whichAvg(pmta.unit) && (
                <>
                  <Popover
                    content={contentComparePopOver(whichAvg(pmta.unit), rType, pmta.siUnit)}
                    trigger="click"
                    title={properCase(avgEnv)}
                  >
                    <TrendUp /> {rType}
                  </Popover>
                </>
              )}
              {rType && rType < whichAvg(pmta.unit) && <>{rType}</>}
              {rType && rType === whichAvg(pmta.unit) && <p>{rType}</p>}
            </span>
          ) : (
            <p>{' N|A '}</p>
          ),
      });
      if (!paramtrs.includes(pmta.unit)) paramtrs.push(pmta.unit);
    });

  const keyedData = data || [];
  if (parameters && pLen > 0) {
    if (type === 'facilityView') {
      keyedData.forEach((dt, index) => {
        if (dt['reportType'] === 'QAQC') {
          dt['key'] = `${0}-${0}`;
          dt['ind'] = 1;
        } else {
          dt['key'] = `${index + 1}-${index + 1}`;
          dt['ind'] = index + 2;
        }
      });
    } else {
      keyedData.forEach((dt, index) => {
        dt['key'] = `${index}-${index}`;
        dt['ind'] = index + 1;
      });
    }
  }

  if (type === 'facilityView') {
    keyedData.sort((a, b) => (a.reportQuarter > b.reportQuarter ? 1 : -1));
  }

  const xOffset = pLen > 8 ? 100 * pLen : 700;
  const yOffset = 450;
  console.log(pLen);
  // extraPadRatio = pLen === 0 ? 1 : parseInt(Math.ceil(pLen / 4));

  return (
    <Table
      columns={columns}
      dataSource={keyedData}
      // scroll={{
      //   x: xOffset,
      //   y: yOffset,
      // }}
      style={{ width: '100%' }}
      loading={isLoading || loading}
      // bordered={false}
      // rowClassName={'facility-upload__rows'}
    />
  );
};

export default ReportCompareTable;
