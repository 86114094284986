import React, { useState } from 'react';
import { Modal, Form, message } from 'antd';
import Input from '../../../../../components/common/AntInput';
import Button from '../../../../../components/common/Button';
import { createZone } from '../../../../../helpers/api';
import './Zone.css';

const AddZoneModal = ({ open, close, reloadZone }) => {
  const [loading, setLoading] = useState(false);

  const handleAddZone = (values) => {
    setLoading(true);
    console.log(values);
    createZone(values)
      .then((res) => {
        setLoading(false);
        message.success(`New Zone ${values.zoneName} created successfully!`);
        console.log(res.result);
        close();
        reloadZone();
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };
  return (
    <Modal visible={open} title="Create New Zone" onCancel={close} className="add__zone__modal">
      <Form layout="vertical" onFinish={handleAddZone}>
        <Form.Item
          name="zoneName"
          label="Zone Name"
          rules={[{ required: true, message: 'please enter zone name' }]}
        >
          <Input placeholder="Enter zone name here" />
        </Form.Item>

        <Button
          type="primary"
          appearance="default"
          className="mr-10"
          htmlType="submit"
          loading={loading}
        >
          Save
        </Button>
        <Button type="secondary" loading={loading} onClick={close}>
          Cancel
        </Button>
      </Form>
    </Modal>
  );
};

export default AddZoneModal;
