import React from 'react';
import { Breadcrumb, Row, Col, Form, Carousel, Popconfirm } from 'antd';
import LoadingContent from '../../../components/common/LoadingContent';
import Button from '../../../components/common/Button';

function noSamplePointCompleted(spt) {
  let disable = true;
  if (spt && spt.length > 0) {
    // let filterSpt = spt.filter((sp) => sp.status && sp.status.toLowerCase === 'draft');
    if (spt && spt.some((ft) => ft.status && ft.status.toLowerCase() !== 'draft')) disable = false;
    if (spt && spt.some((ft) => ft.resultCount >= ft.checkCount)) disable = false;
  }
  return disable;
}

export default function FacilityReporting(props) {
  const {
    type,
    records,
    handleCancel,
    canPublishReport = true,
    adhocPublished: allPublished,
    finalSubmitReport,
    back,
    loading,
    currentReport,
    rowContent,
  } = props;

  const samplePoints = records.data || [];
  const len = samplePoints && samplePoints.length ? samplePoints.length : 0;

  if (loading)
    return (
      <>
        <div className="facility-report__body onreporting-modal">
          <Breadcrumb separator="" className="onreporting-modal__breadcrumb">
            <Breadcrumb.Item
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                back('root');
              }}
            >{`Adhoc Reports`}</Breadcrumb.Item>
            <Breadcrumb.Separator>:</Breadcrumb.Separator>
            <Breadcrumb.Item>{currentReport.reportTitle}</Breadcrumb.Item>
          </Breadcrumb>

          <Row gutter={16} style={{ marginTop: '1.5rem' }}>
            <LoadingContent modal={true} />
          </Row>
        </div>
      </>
    );
  return (
    <>
      <div className="facility-report__body onreporting-modal">
        <Breadcrumb separator="" className="onreporting-modal__breadcrumb">
          <Breadcrumb.Item
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              back('root');
            }}
            className="clickable-breadcrumb"
          >{`Adhoc Reports`}</Breadcrumb.Item>
          <Breadcrumb.Separator>:</Breadcrumb.Separator>
          <Breadcrumb.Item>{currentReport.reportTitle}</Breadcrumb.Item>
          {/* <Breadcrumb.Separator /> */}
          {/* <Breadcrumb.Item>{`${datelize(records.data.reportQuarter)} Quarter`}</Breadcrumb.Item> */}
        </Breadcrumb>

        <Carousel>
          {samplePoints &&
            samplePoints.length > 0 &&
            samplePoints.map((spt, index) => {
              if (index % 8 === 0) {
                return (
                  <div key={`${index}${spt.samplePointId}`}>
                    <Row gutter={16} style={{ marginTop: '1.5rem', overflowX: 'unset' }}>
                      {rowContent(samplePoints, index, currentReport.reportId)}
                    </Row>
                  </div>
                );
              }
            })}
        </Carousel>
        <div className={`submitBtnReport ${len < 5 ? 'submitBtnReporting' : ''}`}>
          <Form
            // {...formItemLayout}
            // form={form}
            layout="horizontal"
            onFinish={() => {}}
            className="facility-reporting__form submit-report"
          >
            {!allPublished && (
              <Row type="flex" justify="start" className="text-left">
                <Col span={3}>
                  <Button
                    type="secondary"
                    className="back-button"
                    onClick={() => handleCancel(type)}
                    style={{ width: 'max-content' }}
                  >
                    Close
                  </Button>
                </Col>
                {true && (
                  <Col span={2}>
                    <Popconfirm
                      title={
                        <>
                          <h3>Submitting is final </h3>
                          <p>Are you sure you want to publish this report?</p>
                        </>
                      }
                      onConfirm={finalSubmitReport}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="primary"
                        appearance="default"
                        htmlType="submit"
                        disabled={noSamplePointCompleted(samplePoints) && canPublishReport}
                        //   disabled={notEverySamplePointCompleted(samplePoints) || !canPublishReport}
                      >
                        Submit
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
              </Row>
            )}
            {allPublished && (
              <Row type="flex" justify="start" className="text-left">
                <Col span={2}>
                  <Button
                    type="secondary"
                    className="back-button"
                    onClick={() => handleCancel(type)}
                  >
                    Exit
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </div>
      </div>
    </>
  );
}
