import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import ProfileContent from '../components/screens/settings/ProfileContent';
import UserContextProvider from '../store/UserContext';

export default () => {
  return (
    <UserContextProvider>
      <Layout headerTitle="Settings">
        <PageContainer>
          <ProfileContent />
        </PageContainer>
      </Layout>
    </UserContextProvider>
  );
};
