import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import Facilities from '../components/screens/facilities';

export default (props) => {
  return (
    <Layout headerTitle="facilities">
      <PageContainer id="facilities">
        <Facilities props={props} />
      </PageContainer>
    </Layout>
  );
};
