import React from 'react';

const PopoverTitle = (props) => {
  return (
    <div>
      <div className="role-heading">
        <b className="dark">Role Type</b>
        <span className="role-badge">{props.roleName}</span>
        <p className="role-description">{props.description}</p>
      </div>
    </div>
  );
};

export default PopoverTitle;
