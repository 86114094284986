import React, { useState } from 'react';
import { Form, Spin } from 'antd';
import Input from '../../common/AntInput';
import Button from '../../common/Button';
import { Link } from 'react-router-dom';
import { forgetPassword } from '../../../helpers/api';
import { openNotification } from '../../../helpers/notification';
import ConfirmResetModal from './ConfirmResetModal';
import './ForgotPassword.css';

const ForgotPasswordForm = ({ className }) => {
  const [Loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [visible, setVisible] = useState(false);

  const closeModal = () => {
    setVisible(false);
  };
  const openModal = () => {
    setVisible(true);
  };

  const reset = (values) => {
    setErrorMessage('');
    setLoading(true);
    forgetPassword(values)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setConfirmMessage(res.result.status.desc);
        setVisible(true);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error && error === undefined) {
          return openNotification({
            type: 'error',
            title: 'Reset failed',
            message: error,
          });
        } 
        return openNotification({
          type: 'error',
          title: 'Reset failed',
          message: error,
        });
      });
  };

  return (
    <Form onSubmit={reset} layout="vertical" className="forgot__password-form" onFinish={reset}>
      <div className="form-header">
        <h1 className="heading">Reset Password</h1>
        <p className="subheading text-gray">Enter your email addres to get reset link.</p>
        {/* <p className="error-message">{errorMessage}</p> */}
      </div>

      <ConfirmResetModal open={visible} message={confirmMessage} close={closeModal} />
      <Form.Item
        name="email"
        label="Email Address"
        rules={[{ required: true, message: 'Please enter your email address' }]}
      >
        <Input type="email" placeholder="jane@mail.com" className="forgot__password-input" />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" appearance="blue" size="large" loading={Loading}>
          Get Reset Link
        </Button>
      </Form.Item>
      <div className="form-group">
        <Link to="/signin">
          <i className="fa fa-long-arrow-left" /> Back to Login
        </Link>
      </div>
    </Form>
  );
};

export default ForgotPasswordForm;
