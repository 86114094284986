import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import EditParamterForm from './EditParameterForm';

import '../style.css';

const EditParameter = ({ open, close, detail, reloadMeasurements }) => {
  const [paramId, setParamId] = useState('');
  const [paramName, setParamName] = useState('');
  //const [paramFMENV, setParamFMENV] = useState(0);
  //const [paramWorldBank, setParamWorldBank] = useState(0);
  const [paramUnit, setParamUnit] = useState('');

  const [nesrea, setNesrea] = useState('')
  const [world, setWorld] = useState('')
  const [nesreaLowerLimit, setNesreaLowerLimit] = useState()
  const [nesreaUpperLimit, setNesreaUpperLimit] = useState()
  const [worldLowerLimit, setWorldLowerLimit] = useState()
  const [worldUpperLimit, setWorldUpperLimit] = useState()

  useEffect(() => {
    getDetails();
  });
  const getDetails = () => {
    if (detail) {
      setParamId(detail[0].parameterId);
      setParamName(detail[0].unit);
      setParamUnit(detail[0].siUnit);
      //setParamWorldBank(detail[0].worldBank);
      //setParamFMENV(detail[0].fmEnv);

      setNesrea(detail[0].nesrea)
      setWorld(detail[0].world)
      setNesreaLowerLimit(detail[0].nesreaLowerLimit)
      setNesreaUpperLimit(detail[0].nesreaUpperLimit)
      setWorldLowerLimit(detail[0].worldLowerLimit)
      setWorldUpperLimit(detail[0].worldUpperLimit)
    }
  };

  return (
    <Modal
      className="edit__parameter__modal"
      visible={open}
      onCancel={close}
      title="Edit Measurement Parameter"
      footer={false}
    >
      <EditParamterForm
        unit={paramName}
        siUnit={paramUnit}
        //worldBank={paramWorldBank}
        id={paramId}
        //fmLimit={paramFMENV}

        nesrea={nesrea}
        world={world}
        nesreaLowerLimit={nesreaLowerLimit}
        nesreaUpperLimit={nesreaUpperLimit}
        worldLowerLimit={worldLowerLimit}
        worldUpperLimit={worldUpperLimit}

        closeModal={close}
        reload={reloadMeasurements}
      />
    </Modal>
  );
};

export default EditParameter;
