import React from 'react';
import { Modal } from 'antd';
import Form from './EditMeasurementForm';

const EditMeasurementModal = ({ open, close, reloadMeasurements, name, id }) => {
  return (
    <Modal
      visible={open}
      onCancel={close}
      footer={false}
      title="Edit Measurement"
      className="edit__measurement__modal"
    >
      <Form reload={reloadMeasurements} closeModal={close} name={name} id={id} />
    </Modal>
  );
};

export default EditMeasurementModal;
