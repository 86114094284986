import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../store/AppContext';
import SideNav from './SideNav';
import Header from './DashboardHeader';

const LayoutItem = ({ className, children, isOpen, setOpen, headerTitle }) => {
  return (
    <div className={className}>
      <SideNav isOpen={isOpen} setOpen={setOpen} />
      <div className="main-panel">
        <Header screenName={headerTitle} />
        <div className="Main-Content">{children}</div>
      </div>
    </div>
  );
};

const Layout = ({ className, children, headerTitle }) => {
  const { state, dispatch } = useContext(AppContext);
  const isOpen = state.sidebarOpen;

  const setOpen = () => dispatch({ type: 'TOGGLE-SIDEBAR' });

  return (
    <_LayoutItem
      className={className}
      isOpen={isOpen}
      setOpen={setOpen}
      headerTitle={headerTitle}
      children={children}
    />
  );
};

const generateWidth = (isOpen) => {
  // if (isOpen) return `width: calc(100% - 204px) !important;`;
  // else return `width: calc(100% - 64px) !important; `;
  if (isOpen) return `width: calc(100% - var(--sidebar-main-w)) !important;`;
  else return `width: calc(100% - var(--sidebar-min-w)) !important; `;
};

const _LayoutItem = styled(LayoutItem)`
  display: flex;
  .bread-crumb-container {
    padding: 0 2rem;
  }
  .main-panel {
    ${(props) => generateWidth(props.isOpen)};
    position: absolute;
    right: 0;
    height: auto;
    @media screen and (max-width: 769px) {
      position: absolute;
      right: 0;
      width: calc(100% - 64px);
      z-index: 10;
    }
  }
  .Main-Content {
    overflow: hidden;
  }
`;

export default Layout;
