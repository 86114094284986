import React, { Component } from 'react';
import { Layout } from 'antd';
import LoadingContent from '../../../components/common/LoadingContent';
import {
  // getFacilities,
  getFacility,
  getComparativeReport,
  getFacilityReport,
  getSamplePointRecord,
  // sendSamplePointRecord,
  // publishFacilityReport,
} from '../../../helpers/api';

const { Content } = Layout;

const FacilityContext = React.createContext();
const currentYear = new Date().getFullYear();
let localState;

export default class FacilityProvider extends Component {
  state = {
    facility: this.props.record,
    comparativeReport: '',
    results: '',
    facilityRecord: '',
    reports: {},
    samplePoints: '',
    allSamplePointRecordData: {},
    allSamplePointRecord: {},
    allParameters: {},
    currentSamplePoint: {},
    currentSamplePointRecord: {},
    currentSamplePointRecordData: {},
    currentParameters: {},
    parameters: '',
    compareParameters: '',
    avgCompareResult: '',
    currCompareResult: [],
    loading: true,
    type: 'all',
  };

  getReport = (slug) => {
    let tempReports = [...this.state.reports];
    const report = tempReports.find((report) => report[slug]);
    return report;
  };

  updateState = (newState, which) => {
    const currState = { ...this.state };
    if (which.includes('/')) {
      which = which.split('/');
      if (which.length === 2) {
        currState[which[0]][which[1]] = newState;
        this.setState({ [which[0]]: currState[which[0]] });
      }
      if (which.length === 3) {
        if (!currState[which[0]][which[1]])
          currState[which[0]][which[1]] = {
            [which[2]]: newState,
          };
        currState[which[0]][which[1]][which[2]] = newState;
        this.setState({ [which[0]]: currState[which[0]] });
      }
    } else {
      this.setState({ [which]: newState }, () => (localState = currState));
    }
  };

  setLoading = (loadingState = false) => {
    this.updateState(loadingState, 'loading');
  };

  getAndSetFacilityReport = (id, qtr, yyyy = currentYear) => {
    getFacilityReport(id, qtr, yyyy).then(({ result }) => {
      const fReport = { qtr, data: null };
      if (result.data) fReport.data = result.data;
      const currState = { ...this.state.reports };
      currState[yyyy] = fReport;
      this.updateState(currState, 'reports');
    });
  };

  // call this to get all quarter reports in a year for a sample point
  // it will set curr parameter and sample point record data
  getAndSetSamplePointRecord = (samplePtId, reportId, quarter) => {
    getSamplePointRecord(samplePtId, reportId).then(({ result }) => {
      // console.log('this same ple point record', result);
      const sptRecord = { data: null };
      const resultReturning = [];
      if (result.data && result.data.results) {
        const formatResult = { ...result.data };

        formatResult.results.map((fmRst, index) => {
          const objR = {};
          fmRst.data.map((fmRst2) => {
            const thePamata = result.data.parameters.filter(
              (pamata) => pamata.parameterId === fmRst2.parameterId
            );
            if (thePamata[0] && thePamata[0]['unit']) {
              objR[thePamata[0]['unit']] = fmRst2.resultValue.toFixed(2);
              objR['entryDate'] = fmRst2.entryDate;
              objR['reportStatus'] = fmRst2.resultStatus;
            }
          });
          if (Object.keys(objR > 0)) {
            resultReturning.push(objR);
          }
        });
      }
      if (result.data) sptRecord.data = result.data;
      {
        // this.updateState(sptRecord, `currentSamplePointRecord/q${quarter}`);
        // this.updateState(sptRecord, `allSamplePointRecord/${samplePtId}/q${quarter}`);
        if (!this.state.currentSamplePoint.samplePointId)
          this.updateState(sptRecord.data, 'currentSamplePoint');
      }
      if (result.data && result.data.parameters) {
        if (!this.state.currentParameters[`q${quarter}`])
          this.updateState(result.data.parameters, `currentParameters/q${quarter}`);
        this.updateState(result.data.parameters, `allParameters/${samplePtId}/q${quarter}`);
      }
      this.updateState(resultReturning, `allSamplePointRecordData/${samplePtId}/q${quarter}`);
      // if (!this.state.currentSamplePointRecordData[`q${quarter}`])
      //   this.updateState(resultReturning, `currentSamplePointRecordData/q${quarter}`);
    });
  };

  getBiggerReport = (fmts) => {
    if (fmts && fmts.length > 0) {
      let returningFmt = fmts[0];
      if (!returningFmt['qaqc']) returningFmt['qaqc'] = [];
      if (!returningFmt['report']) returningFmt['report'] = [];

      fmts.map((fmt) => {
        if (
          (fmt.qaqc && fmt.report && fmt.qaqc.length > fmt.report.length) ||
          (fmt.qaqc && !fmt.report)
        ) {
          if (
            fmt.qaqc.length > returningFmt.qaqc.length &&
            fmt.qaqc.length > returningFmt.report.length
          ) {
            returningFmt = fmt;
          }
        } else if (
          (fmt.qaqc && fmt.report && fmt.report.length > fmt.qaqc.length) ||
          (fmt.report && !fmt.qaqc)
        ) {
          if (
            fmt.report.length > returningFmt.report.length &&
            fmt.report.length > returningFmt.qaqc.length
          ) {
            returningFmt = fmt;
          }
        }
      });
      return returningFmt;
    }
  };

  extractCompareData = ({
    samplePtId = this.state.currentSamplePoint.samplePointId,
    field = 'all',
    results = this.state.results,
  }) => {
    const fmtRst = [];
    results.map((rst1) => {
      const rstQtr = Object.keys(rst1)[0];
      const rstR = rst1[rstQtr].filter((rstF) => rstF.samplePointId === samplePtId);
      if (rstR && rstR.length > 0) {
        const rstRr = { ...rstR[0] };
        // const rstRr = Object.assign({}, rstR);
        // rstRr['reportQuarter'] = rstQtr;
        fmtRst.push(rstRr);
      }
    });

    if (fmtRst && fmtRst.length > 0) {
      const pmtRst = [];
      const resultReturning = [];
      const avgReturning = [];

      if (field === 'parameters' || field === 'all') {
        const usableFmtRst = this.getBiggerReport(fmtRst);
        if (
          (usableFmtRst.qaqc &&
            usableFmtRst.result &&
            usableFmtRst.qaqc.length >= usableFmtRst.report.length) ||
          (usableFmtRst.qaqc && usableFmtRst.qaqc.length > 0)
        ) {
          usableFmtRst.qaqc.map((fmQ) =>
            pmtRst.push({
              measurementId: fmQ.measurementId,
              parameterId: fmQ.parameterId,
              unit: fmQ.unit,
              siUnit: fmQ.siUnit,
            })
          );
        } else {
          if (usableFmtRst.report && usableFmtRst.report.length > 0) {
            usableFmtRst.report.map((fmQ) =>
              pmtRst.push({
                measurementId: fmQ.measurementId,
                parameterId: fmQ.parameterId,
                unit: fmQ.unit,
                siUnit: fmQ.siUnit,
              })
            );
          }
        }
        if (field === 'parameters') return { parameters: pmtRst };
      }
      if (field === 'tabledata' || field === 'all') {
        const avgQCompData = { fmEnv: {}, worldBank: {} };
        //  const avgQCompData = { q1: avgQCompDataQ1, q2: avgQCompDataQ2, q3: avgQCompDataQ3, q4: avgQCompDataQ4 };
        //  const avgQCompDataQ1 = { fmEnv: {}, worldBank: {} };
        //  const avgQCompDataQ2 = { fmEnv: {}, worldBank: {} };
        //  const avgQCompDataQ3 = { fmEnv: {}, worldBank: {} };
        //  const avgQCompDataQ4 = { fmEnv: {}, worldBank: {} };

        //  const whichReportType = fmtRst;

        fmtRst.map((fmtRstOne) => {
          const objQaqc = {};
          let key = fmtRstOne['reportType'];
          let { [key]: theReport } = { QAQC: fmtRstOne.qaqc, NORMAL: fmtRstOne.report };
          theReport &&
            theReport.length > 0 &&
            theReport.map((fmtR) => {
              objQaqc[fmtR.unit] = fmtR.average.toFixed(2);
              avgQCompData['fmEnv'][fmtR.unit] = fmtR['fmEnv'].toFixed(2);
              avgQCompData['worldBank'][fmtR.unit] = fmtR['worldBank'].toFixed(2);
            });
          objQaqc['reportType'] = key;
          avgQCompData['fmEnv']['reportType'] = key;
          avgQCompData['worldBank']['reportType'] = key;
          objQaqc['reportStatus'] = fmtRstOne.reportStatus;
          avgQCompData['fmEnv']['reportStatus'] = fmtRstOne.reportStatus;
          avgQCompData['fmEnv']['reportStatus'] = fmtRstOne.reportStatus;
          objQaqc['reportQuarter'] = fmtRstOne.reportQuarter;
          avgQCompData['fmEnv']['reportQuarter'] = fmtRstOne.reportQuarter;
          avgQCompData['worldBank']['reportQuarter'] = fmtRstOne.reportQuarter;

          resultReturning.push(objQaqc);
          avgReturning.push(avgQCompData);
        });

        if (field === 'tabledata') return { tabledata: resultReturning };
      }
      return {
        parameters: pmtRst,
        tabledata: resultReturning,
        avgData: avgReturning,
      };
    } else return null;
  };

  getNextCompareData = async (samplePtId, field = 'all') => {
    try {
      let result = this.extractCompareData({ samplePtId, field });
      if (result && result.parameters) {
        this.updateState(result.avgData, 'avgCompareResult');
        this.updateState(result.tabledata, 'currCompareResult');
        this.updateState(result.parameters, 'compareParameters');
      } else {
        this.updateState('', 'compareParameters');
        this.updateState([], 'currCompareResult');
      }
    } catch (err) {
      console.log('error in generating compare data', err);
      return;
    }
  };

  getFacilityRecord = (id, yyyy = currentYear) => {
    const fList = [];
    getFacility(id, yyyy).then(({ result }) => {
      result.data && result.data.length > 0 && result.data.forEach((res) => fList.push(res));
    });
    this.updateState(fList, 'facilityRecord');
  };

  digComparativeReports = (id, yyyy = currentYear, cb) => {
    const compList = [];
    const results = [];
    const sptPoints = [];
    const actualParams = {};
    const parametas = {};
    if (id) {
      getComparativeReport(id, yyyy)
        .then(({ result }) => {
          result.data &&
            result.data.length > 0 &&
            result.data.forEach((res) => {
              compList.push(res);
              const resultObj = {};
              const sptObj = {};
              const resultToKeep = [...res.results];
              if (resultToKeep && resultToKeep.length > 0) {
                resultToKeep.map((resKp) => {
                  resKp['reportType'] = res.reportType;
                  resKp['reportStatus'] = res.reportStatus;
                  resKp['reportQuarter'] = `q${res.reportQuarter}`;
                });
              }
              resultObj[`q${res.reportQuarter}`] = resultToKeep;
              sptObj[`q${res.reportQuarter}`] = res.samplePoints;
              results.push(resultObj);
              sptPoints.push(sptObj);

              res.samplePoints.map((res1) =>
                this.getAndSetSamplePointRecord(res1.samplePointId, res.reportId, res.reportQuarter)
              );

              // res.samplePoints.map(res1 => this.keepSamplePointRecord(res1.samplePointId, ))

              res.results.map((res2) => {
                const thePmtObj = [];
                const whichRpt =
                  res2.qaqc && res2.report
                    ? res2.qaqc.length > res2.report.length
                      ? res2.qaqc
                      : res2.report
                    : null;
                if (whichRpt && whichRpt.length > 0) {
                  whichRpt.map((thWch) => {
                    const pmtObj = {};
                    pmtObj['parameterId'] = thWch['parameterId'];
                    pmtObj['unit'] = thWch['unit'];
                    pmtObj['siUnit'] = thWch['siUnit'];
                    pmtObj['measurementId'] = thWch['measurementId'];
                    pmtObj['worldBank'] = thWch['worldBank'];
                    pmtObj['fmEnv'] = thWch['fmEnv'];
                    thePmtObj.push(pmtObj);
                  });
                  parametas[`${res2['samplePointName']}`] = thePmtObj;
                }
              });
              actualParams[`q${res.reportQuarter}`] = parametas;
            });
          this.updateState(actualParams, 'parameters');
          this.updateState(results, 'results');
          this.updateState(sptPoints, 'samplePoints');
          this.updateState(compList, 'comparativeReport');
        })
        .then(() => cb && typeof cb === 'function' && cb());
    }
  };

  getData = (cb) => {
    this.getFacilityRecord(this.state.facility.facilityId);
    this.digComparativeReports(this.state.facility.facilityId, currentYear, cb);
  };

  componentDidMount() {
    try {
      ((cb = () => this.setLoading(false)) => this.getData(cb))();
    } catch (error) {
      console.log('some error', error);
    }
  }

  render() {
    // console.log('strrr', this.state);

    return (
      <FacilityContext.Provider
        value={{
          ...this.state,
          getData: this.getData,
          getReport: this.getReport,
          getFacilityRecord: this.getFacilityRecord,
          digComparativeReports: this.digComparativeReports,
          getNextCompareData: this.getNextCompareData,
          getAndSetSamplePointRecord: this.getAndSetSamplePointRecord,
          setLoading: this.setLoading,
        }}
      >
        {this.state.loading ? (
          <Content style={{ margin: '0 16px 0', overflow: 'scroll' }}>
            <div className="site-layout-background" style={{ padding: 24, textAlign: 'center' }}>
              <LoadingContent modal={true} />
            </div>
          </Content>
        ) : (
          this.props.children
        )}
      </FacilityContext.Provider>
    );
  }
}

const FacilityConsumer = FacilityContext.Consumer;

export function withFacilityConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <FacilityConsumer>{(value) => <Component {...props} context={value} />}</FacilityConsumer>
    );
  };
}

export { FacilityProvider, FacilityConsumer, FacilityContext };
