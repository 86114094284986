export const white = '#ffffff';

export const black = '#000000';

export const gray = {
  light: '#9b9b9b',
  primary: '#2e384d',
  page: '#f9f9f9',
  misc1: 'rgba(224, 231, 255, 0.2)',
  misc2: '#b0bac9',
  misc3: '#454f5b',
  misc4: '#4a4a4a',
  misc5: '#5a6872',
};

export const blue = {
  faint: '#8798ad',
  primary: '#0c5bab;',
  button: '#0c5bab;',
  misc1: '#e0e7ff',
  disabled: 'rgba(0, 122, 206, .7)',
};

export const red = {
  dark: '#C23636',
  primary: '#DB3236',
  light: '#E57777',
  faint: '#F7CDC5',
  ui01: '#ed6347',
};

export const green = {
  button: '#00AB44',
};
