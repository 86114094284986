import React from 'react';
import styled from 'styled-components';
import Button from '../../../common/Button';
import { Modal, Row, Col } from 'antd';
import CheckIcon from '../../../../assets/img/checkmark.svg';


const ConfirmModal = ({className, open, close}) => {
    return (
      <Modal className={className} visible={open} footer={null}>
        <div>
          <Row>
            <Col span={24}>
              <img src={CheckIcon} alt="" />
            </Col>
            <Col span={24}>
              <h3>New Role Created Successfully!</h3>
            </Col>

            <div className="footer">
              <Button appearance="blue" size="small" onClick={close}>
                OK
              </Button>
            </div>
          </Row>
        </div>
      </Modal>
    );
}
 
export default styled(ConfirmModal)`
  background: #fff;
  width: 600px !important;
  height: 400px;
  font-family: var(--font-family);
  .ant-modal-content {
    height: 400px;
  }
  .ant-modal-footer {
    border-top: none;
  }
  div {
    text-align: center;
    h3 {
      font-size: 18px;
      margin: 2rem 0 !important;
    }
  }
  img {
    width: 200px;
  }
  .footer {
    text-align: right;
  }
`;