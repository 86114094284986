export const getQuarterWithData = (recordData) => {
  let quarterWithData = null;
  if (recordData && recordData.length > 0) {
  } else if (recordData && typeof recordData === 'object') {
    const keys = Object.keys(recordData);
    if (keys && keys.length > 0) {
      for (let i = 0; i < keys.length; i++) {
        if (recordData[keys[i]] && recordData[keys[i]].length > 0) {
          if (!quarterWithData) quarterWithData = [];
          if (!quarterWithData.includes(keys[i])) quarterWithData.push(keys[i]);
        }
      }
      if (quarterWithData) quarterWithData.sort((a, b) => (a > b ? 1 : -1));
    }
  }
  return quarterWithData;
};

const mergeSpt = (bigSpt = [], newSpt) => {
  let biggerSpt = bigSpt;
  if (bigSpt && newSpt && newSpt.length > 0) {
    for (let i = 0; i < newSpt.length; i++) {
      if (!bigSpt.some((bgSpt) => bgSpt['samplePointId'] === newSpt[i]['samplePointId'])) {
        biggerSpt.push(newSpt[i]);
      }
    }
  }

  return biggerSpt;
};

export const getBiggestSamplePoint = (recordData) => {
  let biggestSpt;
  const keys = Object.keys(recordData);
  if (keys && keys.length > 0) {
    for (let i = 0; i < keys.length; i++) {
      if (!biggestSpt) biggestSpt = recordData[keys[i]];
      else {
        biggestSpt = mergeSpt(biggestSpt, recordData[keys[i]]);
      }
    }
  }
  return biggestSpt;
};

export const getBiggerReport = (fmts) => {
  if (fmts && fmts.length > 0) {
    let returningFmt = fmts[0];
    if (!returningFmt['qaqc']) returningFmt['qaqc'] = [];
    if (!returningFmt['report']) returningFmt['report'] = [];

    fmts.map((fmt) => {
      if (
        (fmt.qaqc && fmt.report && fmt.qaqc.length > fmt.report.length) ||
        (fmt.qaqc && !fmt.report)
      ) {
        if (
          fmt.qaqc.length > returningFmt.qaqc.length &&
          fmt.qaqc.length > returningFmt.report.length
        ) {
          returningFmt = fmt;
        }
      } else if (
        (fmt.qaqc && fmt.report && fmt.report.length > fmt.qaqc.length) ||
        (fmt.report && !fmt.qaqc)
      ) {
        if (
          fmt.report.length > returningFmt.report.length &&
          fmt.report.length > returningFmt.qaqc.length
        ) {
          returningFmt = fmt;
        }
      }
    });
    return returningFmt;
  }
};

export const getRecordSnapshot = (allSptData, whichQtr = 'all') => {
  console.log('which', whichQtr);
  const rtObj = { q1: 0, q2: 0, q3: 0, q4: 0 };
  if (allSptData && typeof allSptData === 'object') {
    let keys = Object.keys(allSptData);
    let allSptLen = keys.length;
    keys.map((ky) => {
      if (whichQtr === 'all') {
        if (allSptData[ky]['q2'] && allSptData[ky]['q2'].length > 0) rtObj['q2']++;
        if (allSptData[ky]['q3'] && allSptData[ky]['q3'].length > 0) rtObj['q3']++;
        if (allSptData[ky]['q4'] && allSptData[ky]['q4'].length > 0) rtObj['q4']++;
        if (allSptData[ky]['q1'] && allSptData[ky]['q1'].length > 0) rtObj['q1']++;
      } else {
        if (allSptData[ky][whichQtr] && allSptData[ky][whichQtr].length > 0) rtObj[whichQtr]++;
      }
    });
    // if (whichQtr === 'all') {
    //   return `q1: ${rtObj['q1']}|${allSptLen}  q2: ${rtObj['q2']}|${allSptLen} q3: ${
    //     rtObj['q3']
    //   }|${allSptLen} q4: ${rtObj['q4']}|${allSptLen}`;
    // }
    // if (rtObj[whichQtr]) {
    //   return `${whichQtr}: ${rtObj[whichQtr]}|${allSptLen}`;
    // }

    if (whichQtr === 'all') {
      return `[Q1: ${rtObj['q1']}/${allSptLen}] | [Q2: ${rtObj['q2']}/${allSptLen}] | [Q3: ${
        rtObj['q3']
      }/${allSptLen}] | [Q4: ${rtObj['q4']}/${allSptLen}] | `;
    }
    if (rtObj[whichQtr] || rtObj[whichQtr] === 0) {
      return `${rtObj[whichQtr]}|${allSptLen}`;
    }
  }
  return 'N|A';
};

export const extractCompareData = ({ samplePtId, field = 'all', results }) => {
  const fmtRst = [];
  results &&
    results.length > 0 &&
    results.map((rst1) => {
      const rstQtr = Object.keys(rst1)[0];
      const rstR = rst1[rstQtr].filter((rstF) => rstF.samplePointId === samplePtId);
      if (rstR && rstR.length > 0) {
        const rstRr = { ...rstR[0] };
        // const rstRr = Object.assign({}, rstR);
        // rstRr['reportQuarter'] = rstQtr;
        fmtRst.push(rstRr);
      }
    });

  if (fmtRst && fmtRst.length > 0) {
    const pmtRst = [];
    const resultReturning = [];
    const avgReturning = [];

    if (field === 'parameters' || field === 'allcancelled') {
      const usableFmtRst = getBiggerReport(fmtRst);
      if (
        (usableFmtRst.qaqc &&
          usableFmtRst.result &&
          usableFmtRst.qaqc.length >= usableFmtRst.report.length) ||
        (usableFmtRst.qaqc && usableFmtRst.qaqc.length > 0)
      ) {
        usableFmtRst.qaqc.map((fmQ) =>
          pmtRst.push({
            measurementId: fmQ.measurementId,
            parameterId: fmQ.parameterId,
            unit: fmQ.unit,
            siUnit: fmQ.siUnit,
          })
        );
      } else {
        if (usableFmtRst.report && usableFmtRst.report.length > 0) {
          usableFmtRst.report.map((fmQ) =>
            pmtRst.push({
              measurementId: fmQ.measurementId,
              parameterId: fmQ.parameterId,
              unit: fmQ.unit,
              siUnit: fmQ.siUnit,
            })
          );
        }
      }
      if (field === 'parameters') return { parameters: pmtRst };
    }
    if (field === 'tabledata' || field === 'all') {
      const avgQCompData = { fmEnv: {}, worldBank: {} };
      //  const avgQCompData = { q1: avgQCompDataQ1, q2: avgQCompDataQ2, q3: avgQCompDataQ3, q4: avgQCompDataQ4 };
      //  const avgQCompDataQ1 = { fmEnv: {}, worldBank: {} };
      //  const avgQCompDataQ2 = { fmEnv: {}, worldBank: {} };
      //  const avgQCompDataQ3 = { fmEnv: {}, worldBank: {} };
      //  const avgQCompDataQ4 = { fmEnv: {}, worldBank: {} };

      //  const whichReportType = fmtRst;

      fmtRst.map((fmtRstOne) => {
        const objQaqc = {};
        let key = fmtRstOne['reportType'];
        let { [key]: theReport } = { QAQC: fmtRstOne.qaqc, NORMAL: fmtRstOne.report };
        theReport &&
          theReport.length > 0 &&
          theReport.map((fmtR) => {
            objQaqc[fmtR.unit] = fmtR.average.toFixed(2);
            avgQCompData['fmEnv'][fmtR.unit] = fmtR['fmEnv'].toFixed(2);
            avgQCompData['worldBank'][fmtR.unit] = fmtR['worldBank'].toFixed(2);
          });
        objQaqc['reportType'] = key;
        avgQCompData['fmEnv']['reportType'] = key;
        avgQCompData['worldBank']['reportType'] = key;
        objQaqc['reportStatus'] = fmtRstOne.reportStatus;
        avgQCompData['fmEnv']['reportStatus'] = fmtRstOne.reportStatus;
        avgQCompData['fmEnv']['reportStatus'] = fmtRstOne.reportStatus;
        objQaqc['reportQuarter'] = fmtRstOne.reportQuarter;
        avgQCompData['fmEnv']['reportQuarter'] = fmtRstOne.reportQuarter;
        avgQCompData['worldBank']['reportQuarter'] = fmtRstOne.reportQuarter;

        if (
          !resultReturning.filter((rstRt) => rstRt.reportQuarter === objQaqc['reportQuarter'])[0]
        ) {
          resultReturning.push(objQaqc);
        }
        avgReturning.push(avgQCompData);
      });

      if (field === 'tabledata') return { tabledata: resultReturning };
    }
    return {
      parameters: pmtRst,
      tabledata: resultReturning,
      avgData: avgReturning,
    };
  } else return null;
};

export const getCurrentSamplePoint = (sptData, qtr = 'q1') => {
  let retun = [];
  if (sptData && sptData.length > 0) {
    let sptFilter = sptData.filter((sp1) => sp1[qtr] && sp1[qtr].length > 0);
    if (sptFilter && sptFilter.length > 0) return sptFilter[0][qtr];
  }
  return retun;
};
