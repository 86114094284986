import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Select from '../../common/Select';
import { message } from 'antd';
import { openNotification } from '../../../helpers/notification';
import { UserContext } from '../../../store/UserContext';

const EmailInput = ({ className, invite }) => {
  const { addUsers, users } = useContext(UserContext);
  const { Option } = Select;

  useEffect(() => {
    const input = document.querySelector('.ant-select-selection-search-input');
    input.addEventListener('click', validateInput);
  });

  const emailArr = [];
  for (let i = 0; i < emailArr.length; i++) {
    emailArr.push(<Option key={i}>{i}</Option>);
  }

  const handleChange = async (value) => {
    const validateRes = await validateInput(value);
    console.log(validateRes, 'response');
    let valueArr = [];

    if (validateRes) {
      for (let i = 0; i < value.length; i++) {
        valueArr.push(value[i].toLowerCase());
      }
      addUsers(valueArr);
    } else {
      message.error('please enter an email address');
      value.pop();
    }

    // console.log(validateInput(value), 'validate input res');
    // if (validateInput(value)) {
    //   addUsers(valueArr);
    // } else {
    //   return;
    // }
  };

  //   check that users email input is of type email
  const validateEmail = (value) => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  const validateInput = async (input) => {
    // ev.preventDefault();
    const lastEmail = input[input.length - 1];
    // console.log(lastEmail, 'check last entry');

    const validEmail = await validateEmail(lastEmail);
    if (validEmail) {
      return true;
    } else {
      return false;
    }

    // if (ev.key === 'Enter') {
    //   const validEmail = await validateEmail(users);
    //   console.log(validEmail, 'check email');
    //   if (validEmail === undefined) {
    //     return;
    //   } else {
    //     if (validEmail) {
    //     } else {
    //       return openNotification({
    //         title: 'Incorrect email format',
    //         type: 'warn',
    //         duration: 10,
    //       });
    //     }
    //   }
    // }
  };

  return (
    <div className={className}>
      <div className="" id="tag_container">
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder="tayo@yahoo.com"
          onChange={handleChange}
        >
          {emailArr}
        </Select>
      </div>
    </div>
  );
};

export default styled(EmailInput)`
  .tag-container {
    display: flex;
    width: 100%;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 39px;
    padding: 0 20px;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  .ant-select-selection--multiple {
    margin-top: 13px;
    border-radius: 5px;
  }
  .ant-select .ant-select-focused {
    outline: #0052cc;
  }
  .ant-select-selection-item {
    background: #e7f5ff !important;
    border-radius: 15px !important;
    height: 30px !important;
    border: none !important;
    display: flex;
    align-items: center;
  }
  .ant-select-selection-item-content {
    color: #0c5bab;
    font-family: var(--font-family);
    padding: 3px 5px;
  }
  .ant-select-selection-item-remove {
    padding-right: 4px;
    transform: translateY(2px);
  }
  .ant-select-multiple .ant-select-selector .ant-select-selection-search-input {
    font-family: var(--font-family);
  }
`;
