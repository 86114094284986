import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserContext } from '../../../store/UserContext';
import Select from '../../../components/common/Select';

const { Option } = Select;

const UserCategory = ({ className }) => {
  const { updateCategory } = useContext(UserContext);

  const handleChange = (value) => {
    updateCategory(value);
  };

  return (
    <Select placeholder="Select user category" className={className} onChange={handleChange}>
      <Option value={2}>EDM</Option>
      <Option value={1}>REGULATOR</Option>
      <Option value={3}>CONSULTANT</Option>
    </Select>
  );
};

export default styled(UserCategory)``;
