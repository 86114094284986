import { useEffect, useState } from 'react';
import { Divider, Table, Tag } from 'antd';
import { properCase } from '../../../../helpers/utils';
import { getEdmPendingPayments } from '../../../../helpers/api';
import styled from 'styled-components';
import LoaderSkeleton from '../../../common/LoaderSkeleton';

export default function PendingPaymentsEdmView({ stateId }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  useEffect(() => {
    fetch();
  }, [stateId]);

  const fetch = () => {
    setLoading(true);
    getEdmPendingPayments(stateId)
      .then((response) => {
        console.log(response)
        setLoading(false);
        const responseData = response.result.data;
        setData(responseData);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleDisplay = () => {
    if (loading) {
      return <LoaderSkeleton />;
    }

    if (!loading && data.length > 0) {
      return content();
    }
  };

  const content = () => {
    const columns = [
      {
        title: 'Facility',
        key: 'facilityName',
        dataIndex: 'facilityName',
        render: (facilityName) => <div>{facilityName}</div>,
      },
      {
        title: 'Category',
        key: 'categoryName',
        dataIndex: 'categoryName',
        render: (_, record) => {
          let background = '';
          let color = '';

          if (record.categoryName === 'MINI') background = '#0CB230';
          if (record.categoryName === 'SMALL') background = '#FAAB10';
          if (record.categoryName === 'MEDUIM') background = '#7CBDFF';
          if (record.categoryName === 'LARGE') background = '#FA1010';

          return (
            <Tag
              color={background}
              style={{
                width: '70px',
                textAlign: 'center',
                borderRadius: '100px',
              }}
            >
              {properCase(record.categoryName)}
            </Tag>
          );
        },
      },
      // {
      //   title: 'Amount',
      //   key: 'amount',
      //   dataIndex: 'amount',
      //   render: (_, record) => {
      //     return (
      //       <div>
      //         {Intl.NumberFormat('en-NG', {
      //           style: 'currency',
      //           currency: 'NGN',
      //         }).format(record.amount)}
      //       </div>
      //     );
      //   },
      // },
      // {
      //   title: 'Paid',
      //   key: 'totalAmountPaid',
      //   dataIndex: 'totalAmountPaid',
      //   render: (_, record) => {
      //     return (
      //       <div>
      //         {Intl.NumberFormat('en-NG', {
      //           style: 'currency',
      //           currency: 'NGN',
      //         }).format(record.totalAmountPaid)}
      //       </div>
      //     );
      //   },
      // },
      {
        title: 'Outstanding',
        key: 'totalOutstandingAmount',
        dataIndex: 'totalOutstandingAmount',
        render: (_, record) => {
          return (
            <div>
              {Intl.NumberFormat('en-NG', {
                style: 'currency',
                currency: 'NGN',
              }).format(record.totalOutstandingAmount)}
            </div>
          );
        },
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={data}
        size="middle"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          onChange: handleTableChange,
          total: data?.length,
        }}
        onChange={handleTableChange}
      />
    );
  };

  return (
    <Box>
      <Header>
        <Title>
          <TitlePrimary>Outstanding Payments</TitlePrimary>
          <TitleSecondary>Outstanding payments by facilities</TitleSecondary>
        </Title>
      </Header>
      <Divider />
      <div style={{ padding: '0 1rem 0rem 1rem' }}>{handleDisplay()}</div>
    </Box>
  );
}

const Box = styled.div`
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  font-family: sans-serif;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
`;

const Title = styled.div`
  color: #898282;
  flex-grow: 1;
`;

const TitlePrimary = styled.div`
  color: #5f6368;
  font-size: 1.5em;
  font-weight: bold;
`;

const TitleSecondary = styled.div`
  margin-top: -5px;
`;
