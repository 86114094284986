import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/img/logo.png';

const NavBrandDark = ({ className, onClick }) => {
  return (
    <div className={`NavBrand ${className}`}>
      <span className="nav-logo">
        <img src={logo} alt="iems logo" />
        <span className="iems-logo-text text-dark ml-5">iems</span>
      </span>
    </div>
  );
};
export default styled(NavBrandDark)`
  background: transparent;
  display: flex;
  align-items: center;
  padding: var(--sidebar-min-padding);
  justify-content: space-between;
  @media screen and (max-width: 769px) {
    display: flex;
    padding-left: 1rem;
    flex-wrap: wrap;
  }


  .nav-logo {
    padding: var(--sidebar-min-padding);
  }
  .iems-logo-text {
    color: var(--dark) !important;
  }

  img {
    height: 24px;
    width: 22px;
  }

  span {
    font-weight: 500;
    font-size: 19px;
    color: #fff;
  }
  button {
    display: none;
    @media screen and (max-width: 769px) {
      display: block;
      margin-bottom: 0;
    }
  }
`;
